import { Box, Skeleton, Stack, Typography, useTheme } from '@mui/material';
import { format } from 'date-fns';
import useResponsive from 'src/hooks/useResponsive';
import { useLiveVisitContext } from '../../visit/hooks/useLiveVisitContext';

export default function LiveVisitHeader() {
  const { isLoading, data, isError } = useLiveVisitContext();
  const theme = useTheme();
  const isDownMd = useResponsive('down', 'md');

  return (
    <Box>
      <Stack
        sx={{
          p: isDownMd ? 1 : 3,
          backgroundColor: theme.palette.background.default,
          '@media (min-width:600px) and (max-height:420px)': {
            p: 1,
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          {!isLoading && !isError && data?.professional ? (
            <Typography
              variant={isDownMd ? 'h6' : 'h4'}
              sx={{
                '@media (min-width:600px) and (max-height:420px)': {
                  fontSize: 'h6.fontSize',
                },
              }}
            >
              {`E-spotkanie z ${data?.professional?.firstName} ${data?.professional?.lastName}`}{' '}
              {data && (
                <Typography
                  variant={isDownMd ? 'h6' : 'h4'}
                  sx={{
                    '@media (min-width:600px) and (max-height:420px)': {
                      fontSize: 'h6.fontSize',
                    },
                  }}
                  component="span"
                  color="text.secondary"
                >
                  {format(new Date(data.startTime), 'dd/MM/yyyy H:mm')}
                </Typography>
              )}
            </Typography>
          ) : (
            <Skeleton variant="rounded" width={450} height={36} />
          )}
        </Box>
      </Stack>
    </Box>
  );
}
