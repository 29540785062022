import * as ERROR_MESSAGES from './customErrorMessages';
export const getFirebaseErrorMessage = (errorCode: string) => {
  let message: string | null = null;
  switch (errorCode) {
    case 'auth/email-already-in-use':
      message = ERROR_MESSAGES.EMAIL_ALREADY_IN_USE;
      break;
    case 'auth/invalid-email':
      message = ERROR_MESSAGES.INVALID_EMAIL;
      break;
    case 'auth/invalid-login-credentials':
      message = ERROR_MESSAGES.INVALID_LOGIN_CREDENTIALS;
      break;
    case 'auth/weak-password':
      message = ERROR_MESSAGES.WEAK_PASSWORD;
      break;
    default:
      message = ERROR_MESSAGES.DEFAULT_ERROR_MESSAGE;
      break;
  }
  return message;
};
