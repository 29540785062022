import { useState } from 'react';
// @mui
import {
  Step,
  StepConnector,
  stepConnectorClasses,
  StepLabel,
  Stepper,
  styled,
} from '@mui/material';
import { StepIconProps } from '@mui/material/StepIcon';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { userApi } from 'src/api/user';
import { useUserContext } from 'src/auth/useUserContext';
import { GetUserDto, UpdateUserDto } from 'woofwoof-api';
import Iconify from '../../../../components/iconify';
import AccountBusiness, {
  AccountBusinessFormValuesProps,
} from './AccountBusiness';
import { AccountGeneralFormValuesProps } from './AccountGeneral';
import { AccountGeneral } from './index';

const steps = ['Szczegóły konta', 'Szczegóły firmy'];

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 23,
    left: 'calc(-50% + 24px)',
    right: 'calc(50% + 24px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.palette.primary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.palette.primary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 4,
    border: 0,
    borderRadius: 1,
    backgroundColor: theme.palette.divider,
  },
}));

const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  zIndex: 1,
  width: 48,
  height: 48,
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.action.disabledBackground,
  ...(ownerState.active && {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.primary.main,
  }),
  ...(ownerState.completed && {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.primary.main,
  }),
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className, icon } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <Iconify icon="ic:person" width={24} />,
    2: <Iconify icon="ic:round-business-center" width={24} />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(icon)]}
    </ColorlibStepIconRoot>
  );
}

export default function UpdateAccountStepper() {
  const { enqueueSnackbar } = useSnackbar();
  const { data, refetch } = useUserContext();
  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (updateUserDto: UpdateUserDto) =>
      userApi.userControllerUpdateUser({ updateUserDto }),
  });
  const [stepsFormValues, setStepsFormValues] = useState<
    [
      AccountGeneralFormValuesProps | GetUserDto | undefined,
      AccountBusinessFormValuesProps | GetUserDto | undefined,
    ]
  >([data, data]);
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = (stepFormValues: AccountGeneralFormValuesProps) => {
    setStepsFormValues((_steps) => [stepFormValues, _steps[1]]);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = (stepFormValues: AccountBusinessFormValuesProps) => {
    setStepsFormValues((_steps) => [_steps[0], stepFormValues]);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = async (
    stepFormValues: AccountBusinessFormValuesProps,
  ) => {
    try {
      if (stepsFormValues[0]) {
        await mutateAsync({ ...stepsFormValues[0], ...stepFormValues });
        await refetch();
      }
    } catch (error) {
      enqueueSnackbar(error.message || error, {
        variant: 'error',
      });
    }
  };

  return (
    <>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
        sx={{ marginBottom: 5 }}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {activeStep === 0 && (
        <AccountGeneral
          submitButtonLabel="Dalej"
          stepValues={stepsFormValues[0]}
          onSubmit={handleNext}
        />
      )}
      {activeStep === 1 && (
        <AccountBusiness
          onSubmit={handleSubmit}
          onBack={handleBack}
          stepValues={stepsFormValues[1]}
          isLoading={isLoading}
        />
      )}
    </>
  );
}
