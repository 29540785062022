import { Paper, Stack, useTheme } from '@mui/material';
import { ProfileFilesContent } from 'src/sections/@dashboard/profile/components/ProfileFilesContent';

export default function ProfileTabsFilesPage() {
  const theme = useTheme();

  return (
    <Paper
      sx={{
        borderRadius: 2,
        boxShadow: theme.customShadows.z8,
        height: 1,
        maxHeight: '1000px',
      }}
    >
      <Stack sx={{ height: 1, minHeight: '560px' }}>
        <ProfileFilesContent />
      </Stack>
    </Paper>
  );
}
