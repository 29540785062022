import { Box } from '@mui/system';
import MainParticipant from './MainParticipant';
import ParticipantList from './ParticipantList';

export default function Room() {
  return (
    <Box
      sx={{
        position: 'absolute',
        height: '100%',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <ParticipantList />
      <MainParticipant />
    </Box>
  );
}
