import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

export type ColorSchema =
  | 'primary'
  | 'secondary'
  | 'info'
  | 'success'
  | 'warning'
  | 'error';

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    neutral: string;
  }
  interface SimplePaletteColorOptions {
    lighter: string;
    darker: string;
  }
  interface PaletteColor {
    lighter: string;
    darker: string;
  }
}

// SETUP COLORS

export const GREY = {
  0: '#FFFFFF',
  100: '#F8F7F6',
  200: '#EEEEEE',
  300: '#E4E5E6',
  400: '#CBD0D2',
  500: '#9AA5A9',
  600: '#818F95',
  700: '#4F636B',
  800: '#364D57',
  900: '#364D57',
};

export const LIGHT_TEXT = {
  primary: '#052330',
  secondary: '#637381',
  disabled: '#919EAB',
};

export const DARK_TEXT = {
  primary: '#F4ECE5',
  secondary: '#919EAB',
  disabled: '#919EAB',
};

export const LIGHT_BACKGROUND = {
  default: '#FDFBFA',
  paper: '#F8F4EF',
  neutral: '#F4ECE5',
};

export const DARK_BACKGROUND = {
  default: '#052330',
  paper: '#052330',
  neutral: GREY[500],
};

const PRIMARY = {
  lighter: '#E3F6FF',
  light: '#B9ECFF',
  main: '#50CEFF',
  dark: '#169FD3',
  darker: '#008BC0',
  contrastText: '#000000',
};

const SECONDARY = {
  lighter: '#E1FCED',
  light: '#D3FBE3',
  main: '#B5F8D1',
  dark: '#7FF5B0',
  darker: '#5FE898',
  contrastText: '#000000',
};

const INFO = {
  lighter: '#B9ECFF',
  light: '#96E2FF',
  main: '#25BFFE',
  dark: '#006C9C',
  darker: '#003768',
  contrastText: '#000000',
};

const SUCCESS = {
  lighter: '#D8FBDE',
  light: '#86E8AB',
  main: '#08C07D',
  dark: '#1B806A',
  darker: '#0A5554',
  contrastText: '#000000',
};

const WARNING = {
  lightest: '#FFDC7D1F',
  lighter: '#FFF5CC',
  light: '#FFE59D',
  main: '#FFDC7D',
  dark: '#B76E00',
  darker: '#7A4100',
  contrastText: '#000000',
};

const ERROR = {
  lighter: '#FFE9D5',
  light: '#FFAC82',
  main: '#FF8B71',
  dark: '#B71D18',
  darker: '#7A0916',
  contrastText: '#000000',
};

const LIGHT_ACTION = {
  active: '#052330',
  hover: alpha('#052330', 0.08),
  selected: alpha('#052330', 0.16),
  disabled: alpha('#052330', 0.8),
  disabledBackground: alpha('#052330', 0.24),
  focus: alpha('#052330', 0.24),
  hoverOpacity: 0.08,
  disabledOpacity: 0.48,
};

const DARK_ACTION = {
  active: '#454F5B',
  hover: alpha(GREY[500], 0.08),
  selected: alpha(GREY[500], 0.16),
  disabled: alpha(GREY[500], 0.8),
  disabledBackground: alpha(GREY[500], 0.24),
  focus: alpha(GREY[500], 0.24),
  hoverOpacity: 0.08,
  disabledOpacity: 0.48,
};

export const COMMON = {
  common: { black: '#052330', white: '#F4ECE5' },
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  divider: alpha('#9AA5A9', 0.24),
  action: LIGHT_ACTION,
};

export default function palette(themeMode: 'light' | 'dark') {
  const light = {
    ...COMMON,
    mode: 'light',
    text: LIGHT_TEXT,
    background: LIGHT_BACKGROUND,
  } as const;

  const dark = {
    ...COMMON,
    mode: 'dark',
    text: DARK_TEXT,
    background: DARK_BACKGROUND,
    action: DARK_ACTION,
  } as const;

  return themeMode === 'light' ? light : dark;
}
