import { Box, Tabs, Tab, Typography } from '@mui/material';
import { useState } from 'react';
import { useUserContext } from 'src/auth/useUserContext';
import Markdown from 'src/components/markdown/Markdown';
import Image from 'src/components/image/Image';
type VisitModalNotesDetailsProps = {
  visit?: any;
};
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
export default function VisitModalTabs({ visit }: VisitModalNotesDetailsProps) {
  const [value, setValue] = useState(0);
  const { isProfessionalRole } = useUserContext();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return isProfessionalRole ? (
    <>
      <Box>
        <Tabs
          variant="fullWidth"
          value={value}
          onChange={handleChange}
          aria-label="notes tabs"
          indicatorColor="primary"
        >
          <Tab label="Pacjent" {...a11yProps(0)} />
          <Tab label="Specjalista" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        {visit.notes ? (
          <>
            <Typography>Notatki z e-spotkania</Typography>
            <Typography color="text.secondary">
              <Markdown>{visit.notes}</Markdown>
            </Typography>
          </>
        ) : (
          <Typography sx={{ p: 6 }} variant="h6" align="center">
            <Box display="flex" justifyContent="center" alignItems="center">
              <Image
                disabledEffect
                visibleByDefault
                alt="auth"
                src={'/assets/illustrations/laptop-paw-blue.svg'}
                sx={{ maxWidth: 180, paddingBottom: 2 }}
              />
            </Box>
            Brak Notatek
          </Typography>
        )}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        {visit.internalNotes ? (
          <>
            <Typography>Notatki z e-spotkania</Typography>
            <Typography color="text.secondary">
              <Markdown>{visit.internalNotes}</Markdown>
            </Typography>
          </>
        ) : (
          <Typography sx={{ p: 6 }} variant="h6" align="center">
            <Box display="flex" justifyContent="center" alignItems="center">
              <Image
                disabledEffect
                visibleByDefault
                alt="auth"
                src={'/assets/illustrations/laptop-paw-blue.svg'}
                sx={{ maxWidth: 180, paddingBottom: 2 }}
              />
            </Box>
            Brak Notatek
          </Typography>
        )}
      </CustomTabPanel>
    </>
  ) : (
    <>
      <Typography>Notatki z e-spotkania</Typography>
      <Typography color="text.secondary">
        <Markdown>{visit.notes}</Markdown>
      </Typography>
    </>
  );
}
