import { ProfileApi } from 'woofwoof-api';
import client, { axiosBaseUrl } from './client';

export const profileApi = new ProfileApi(
  {
    basePath: axiosBaseUrl,
    isJsonMime: () => false,
  },
  undefined,
  //@ts-ignore
  client,
);
