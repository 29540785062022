import { useTheme } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Link, Typography, Stack, Box, useMediaQuery } from '@mui/material';
// routes
import { PATH_AUTH } from '../../routes/paths';
// components
import Iconify from '../../components/iconify';
// sections
import AuthNewPasswordForm from '../../sections/auth/AuthNewPasswordForm';
// assets
import { SentIcon } from '../../assets/icons';
import Image from 'src/components/image/Image';

export default function AuthNewPassword() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Stack
      flexDirection="row"
      sx={{
        gap: 8,
        alignItems: 'center',
        justifyContent: 'center',
        width: 1,
        mt: { xs: 10, sm: 10, md: 10, lg: 0 },
        ...(isDesktop
          ? {
              '@media (max-height: 720px)': {
                marginTop: theme.spacing(3),
                paddingBottom: theme.spacing(3),
              },
            }
          : {}),
      }}
    >
      {isDesktop && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Typography
            variant="h2"
            sx={{ maxWidth: 600, textAlign: 'center', marginTop: 4 }}
          >
            Umów{' '}
            <span style={{ color: theme.palette.primary.main }}>
              swojego zwierzaka
            </span>{' '}
            na e=spotkanie online!
          </Typography>
          <Image
            disabledEffect
            visibleByDefault
            alt="auth"
            src={'/assets/illustrations/comfort.png'}
            sx={{ maxWidth: 540 }}
          />
        </Box>
      )}
      <Stack
        sx={{
          backgroundColor: theme.palette.background.default,
          boxShadow: theme.customShadows.z24,
          borderRadius: theme.spacing(2),
          padding: theme.spacing(2),
          width: '100%',
          maxWidth: '450px',
          textAlign: 'center',
        }}
      >
        <SentIcon sx={{ marginY: 3, height: 96 }} />

        <Typography variant="h3" paragraph>
          Weryfikacja powiodła się!
        </Typography>

        <Typography sx={{ color: 'text.secondary', mb: 4 }}>
          Stwórz nowe hasło.
        </Typography>

        <AuthNewPasswordForm />

        <Link
          component={RouterLink}
          to={PATH_AUTH.login}
          color="primary"
          variant="subtitle2"
          sx={{
            mx: 'auto',
            alignItems: 'center',
            display: 'inline-flex',
            mt: theme.spacing(3),
          }}
        >
          <Iconify icon="eva:chevron-left-fill" width={16} />
          Powrót do strony logowania
        </Link>
      </Stack>
    </Stack>
  );
}
