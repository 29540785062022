import { useState } from 'react';
import * as Yup from 'yup';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import {
  IconButton,
  InputAdornment,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { LIGHT_TEXT } from 'src/theme/palette';
// routes
import { Link as RouterLink } from 'react-router-dom';
import { PATH_AUTH } from '../../routes/paths';
// auth
import { useAuthContext } from '../../auth/useAuthContext';
// components
import { useSnackbar } from 'notistack';
import { getFirebaseErrorMessage } from 'src/utils/getFirebaseErrorMessage';
import FormProvider, { RHFTextField } from '../../components/hook-form';
import Iconify from '../../components/iconify';

// ----------------------------------------------------------------------

type FormValuesProps = {
  email: string;
  password: string;
  afterSubmit?: string;
};

export default function AuthLoginForm() {
  const { login, logout } = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .required('Adres e-mail jest wymagany')
      .email('Adres e-mail musi mieć poprawny format'),
    password: Yup.string().required('Hasło jest wymagane'),
  });

  const defaultValues = {
    email: '',
    password: '',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const { handleSubmit } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      setIsLoading(true);
      await login(data.email, data.password);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      enqueueSnackbar(getFirebaseErrorMessage(error.code) || error, {
        variant: 'error',
      });
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFTextField name="email" label="Adres e-mail" disabled={isLoading} />

        <RHFTextField
          name="password"
          label="Hasło"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
          disabled={isLoading}
        />
      </Stack>

      <Stack
        spacing={2}
        sx={{ mt: 5, position: 'relative', textAlign: 'right' }}
      >
        <Link component={RouterLink} to={PATH_AUTH.resetPassword}>
          <Typography variant="body2" sx={{ color: LIGHT_TEXT.primary }}>
            Nie pamiętasz hasła?{' '}
          </Typography>
        </Link>
      </Stack>
      <LoadingButton
        sx={{ mt: 2 }}
        fullWidth
        color="primary"
        size="large"
        type="submit"
        variant="contained"
        loading={isLoading}
      >
        <span>Zaloguj się</span>
      </LoadingButton>
    </FormProvider>
  );
}
