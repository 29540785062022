import useParticipants from './useParticipants';
import useVideoContext from './useVideoContext';

export default function useMainParticipant() {
  const participants = useParticipants();
  const { room } = useVideoContext();
  const localParticipant = room?.localParticipant;

  return participants[0] || localParticipant;
}
