import { IconButton, useTheme } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import Iconify from 'src/components/iconify';
import { createLocalVideoTrack } from 'twilio-video';
import useDevices from '../hooks/useDevices';
import useVideoContext from '../hooks/useVideoContext';

export default function ToggleVideoButton(props: { disabled?: boolean }) {
  const theme = useTheme();
  const { room } = useVideoContext();
  const localParticipant = room?.localParticipant;
  const videoTrack = localParticipant
    ? Array.from(localParticipant?.videoTracks.values())?.find(
        (track) => track.kind === 'video',
      )
    : undefined;
  const { hasVideoInputDevices } = useDevices();
  const [videoState, setVideoState] = useState({
    video: false,
    enabled: true,
  });

  useEffect(() => {
    setVideoState((state) => ({ ...state, video: !!videoTrack }));
  }, [videoTrack]);

  const turnOnCamera = useCallback(async () => {
    if (!localParticipant || videoState.video) {
      return;
    }

    const track = await createLocalVideoTrack();
    await localParticipant.publishTrack(track);

    setVideoState(() => ({ enabled: false, video: true }));
    setTimeout(
      () => setVideoState((state) => ({ ...state, enabled: true })),
      500,
    );
  }, [localParticipant, videoState.video]);

  const turnOffCamera = useCallback(() => {
    if (!localParticipant || !videoState.video) {
      return;
    }

    localParticipant.videoTracks.forEach((publication) => {
      publication.track.disable();
      publication.track.stop();
      publication.unpublish();
      localParticipant?.emit('trackUnpublished');
    });

    setVideoState(() => ({ enabled: false, video: false }));
    setTimeout(
      () => setVideoState((state) => ({ ...state, enabled: true })),
      500,
    );
  }, [localParticipant, videoState.video]);

  return (
    <IconButton
      size="small"
      onClick={videoState.video ? turnOffCamera : turnOnCamera}
      disabled={!hasVideoInputDevices || props.disabled || !videoState.enabled}
      sx={{
        backgroundColor: videoState.video
          ? theme.palette.text.primary
          : theme.palette.error.main,
        ':hover': {
          backgroundColor: videoState.video
            ? theme.palette.text.secondary
            : theme.palette.error.light,
        },
        '&:disabled': {
          backgroundColor: theme.palette.action.disabledBackground,
        },
      }}
    >
      {videoState.video ||
      !hasVideoInputDevices ||
      props.disabled ||
      !videoState.enabled ? (
        <Iconify icon="eva:video-fill" color="#F4ECE5" />
      ) : (
        <Iconify icon="eva:video-off-fill" color={theme.palette.text.primary} />
      )}
    </IconButton>
  );
}
