import { Theme } from '@mui/material';

// ----------------------------------------------------------------------

export default function Skeleton(theme: Theme) {
  return {
    MuiSkeleton: {
      defaultProps: {
        animation: 'wave',
      },
      styleOverrides: {
        root: {
          borderRadius: '32px',
          backgroundColor: theme.palette.grey[200],
        },
      },
    },
  };
}
