import { Stack, Typography, useTheme } from '@mui/material';
import Markdown from 'src/components/markdown/Markdown';
import { useCurrentVisitContext } from '../hooks/useCurrentVisitContext';

// ---------------------------------------------------------------------

export default function VisitAIAssistant() {
  const { data } = useCurrentVisitContext();
  const theme = useTheme();

  return (
    <Stack
      sx={{
        gap: 1,
        backgroundColor: theme.palette.background.default,
        borderRadius: 2,
        boxShadow: theme.customShadows.z8,
      }}
    >
      <Stack
        sx={{
          borderTopRightRadius: theme.spacing(2),
          borderTopLeftRadius: theme.spacing(2),
          gap: 0.5,
          paddingX: 3,
          paddingY: 2,
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <Typography variant="h6">Asystent AI</Typography>
        <Typography variant="body2" color="text.secondary">
          Automatyczna pomoc dla specjalisty.
        </Typography>
      </Stack>
      <Stack
        sx={{
          paddingX: 3,
          paddingY: 2,
          paddingTop: 1,
          gap: 2,
        }}
      >
        <Typography color="text.secondary">
          <Markdown>
            {data?.aiNote ? data.aiNote : 'Brak informacji od asystenta AI'}
          </Markdown>
        </Typography>
      </Stack>
    </Stack>
  );
}
