import { useContext } from 'react';
import { CurrentVisitContext } from '../context/CurrentVisitContext';
//
// ----------------------------------------------------------------------

export const useCurrentVisitContext = () => {
  const context = useContext(CurrentVisitContext);

  if (!context)
    throw new Error(
      'useCurrentVisitContext context must be used inside CurrentVisitProvider',
    );

  return context;
};
