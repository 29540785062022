import {
  Dispatch,
  SetStateAction,
  createContext,
  useMemo,
  useState,
} from 'react';

import { useQuery } from '@tanstack/react-query';
import { visitApi } from 'src/api/visit';
import { useUserContext } from 'src/auth/useUserContext';
import { GetVisitById } from 'woofwoof-api';
import { FormValuesProps } from '../components/VisitSteps';

// ----------------------------------------------------------------------

type Flow = {
  steps: FormValuesProps;
  notes: string;
  internalNotes?: string;
};

export type CurrentVisitContextType = {
  data?: GetVisitById;
  refetch: () => Promise<any>;
  isLoading: boolean;
  isError: boolean;
  visitFlow: Flow;
  setVisitFlow: Dispatch<SetStateAction<Flow>>;
};

export const defaultFlow: Flow = {
  steps: {
    step1: false,
    step2: false,
    step3: false,
    step4: false,
    step5: false,
  },
  notes: '',
  internalNotes: '',
};

export const CurrentVisitContext =
  createContext<CurrentVisitContextType | null>(null);

type CurrentVisitProviderProps = {
  children: React.ReactNode;
};

export function CurrentVisitProvider({ children }: CurrentVisitProviderProps) {
  const { isProfessionalRole } = useUserContext();
  const [visitFlow, setVisitFlow] =
    useState<CurrentVisitContextType['visitFlow']>(defaultFlow);

  const { refetch, data, isLoading, isError } = useQuery(
    ['currentVisit'],
    () => visitApi.visitControllerGetCurrentPendingVisit(),
    {
      retry: 1,
      enabled: Boolean(isProfessionalRole),
      refetchInterval: 10000,
      cacheTime: 0,
    },
  );

  const memoizedValue = useMemo(() => {
    return {
      data: data?.data,
      isLoading,
      isError,
      refetch,
      visitFlow,
      setVisitFlow,
    };
  }, [data, isLoading, isError, refetch, visitFlow, setVisitFlow]);

  return (
    <CurrentVisitContext.Provider value={memoizedValue}>
      {children}
    </CurrentVisitContext.Provider>
  );
}
