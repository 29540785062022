import { useContext } from 'react';
import { VisitsContext } from '../context/VisitsContext';
//
// ----------------------------------------------------------------------

export const useVisitsContext = () => {
  const context = useContext(VisitsContext);

  if (!context)
    throw new Error(
      'useVisitsContext context must be used inside VisitsProvider',
    );

  return context;
};
