import {
  Avatar,
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { format } from 'date-fns';
import { ReactNode, useState } from 'react';
import Scrollbar from 'src/components/scrollbar';
import { TablePaginationCustom, useTable } from 'src/components/table';
import { GetUserDtoCategoriesEnum, GetVisitById } from 'woofwoof-api';
import Image from '../../../../components/image';
import { VISIT_TYPE_MATRIX } from '../../visit/components/BookingForm';
import VisitModal from '../../visit/components/VisitModal';

interface Row {
  id: string;
  professional: string;
  professionalAvatar?: string;
  date: string;
  category: GetUserDtoCategoriesEnum;
}

interface Column {
  id: keyof Row;
  label: string;
  minWidth?: number;
  align?: 'left';
  render?: (row: Row) => ReactNode;
}

export type ProfileVisitsHistoryTableProps = { data: GetVisitById[] };

export default function ProfileVisitsHistoryTable({
  data,
}: ProfileVisitsHistoryTableProps) {
  const theme = useTheme();
  const [selectedVisitId, setSelectedVisitId] = useState<string>('');

  const openModal = (visitId: string) => setSelectedVisitId(visitId);

  const closeModal = () => setSelectedVisitId('');

  const handleOnRowClick = (visitId: string) => {
    openModal(visitId);
  };

  const tableData: Row[] = data.map((visit) => ({
    id: visit.id,
    professional: `${visit?.professional?.firstName} ${visit?.professional?.lastName}`,
    professionalAvatar: visit?.professional?.avatar,
    category: visit?.category,
    date: visit?.startTime,
  }));

  const COLUMNS: Column[] = [
    {
      id: 'professional',
      label: 'Specjalista',
      render: (value: Row) => (
        <Box
          sx={{
            gap: theme.spacing(2),
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Avatar
            src={value?.professionalAvatar}
            sx={{ width: theme.spacing(5), height: theme.spacing(5) }}
          />
          <Typography variant="body2">{value.professional}</Typography>
        </Box>
      ),
    },
    {
      id: 'date',
      label: 'Data e-spotkania',
      render: (value: Row) => format(new Date(value.date), 'dd/MM/yyyy H:mm'),
    },
    {
      id: 'category',
      label: 'Rodzaj',
      render: (value: Row) => VISIT_TYPE_MATRIX[value.category] || '',
    },
  ];
  const { page, rowsPerPage, onChangePage, onChangeRowsPerPage } = useTable({
    defaultRowsPerPage: 25,
  });

  const TableHeader = (
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          {COLUMNS.map((column) => (
            <TableCell
              key={column.id}
              align={column.align}
              style={{ minWidth: column.minWidth }}
            >
              {column.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </Table>
  );

  const FullTable = (
    <Box sx={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}>
      <Scrollbar>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {COLUMNS.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, rowIndex) => (
                <TableRow
                  hover
                  key={rowIndex}
                  onClick={() => handleOnRowClick(row.id)}
                >
                  {COLUMNS.map((column, index) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.render ? column.render(row) : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Scrollbar>
    </Box>
  );
  return (
    <>
      <TableContainer
        sx={{ height: 1, display: 'flex', flexDirection: 'column' }}
      >
        {tableData.length > 0 ? (
          FullTable
        ) : (
          <>
            {TableHeader}
            <Stack
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              flex="1"
              gap={2}
            >
              <Image
                sx={{ width: '200px' }}
                alt="empty waiting room"
                src="/assets/images/empty_table.svg"
              />
              <Typography variant="h6">Brak e-spotkań</Typography>
            </Stack>
          </>
        )}
      </TableContainer>
      <TablePaginationCustom
        count={tableData.length}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
      />
      <VisitModal
        isOpen={Boolean(selectedVisitId)}
        onClose={closeModal}
        visit={data.find((visit) => visit.id === selectedVisitId)}
      />
    </>
  );
}
