import { IconButton, useTheme } from '@mui/material';
import { useState } from 'react';
import Iconify from 'src/components/iconify';
import VisitModal from '../../visit/components/VisitModal';
import { useLiveVisitContext } from '../../visit/hooks/useLiveVisitContext';

export default function LiveVisitDetailsModalButton() {
  const theme = useTheme();
  const { data } = useLiveVisitContext();
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);

  const closeModal = () => setIsOpen(false);

  if (!data) {
    return null;
  }

  return (
    <>
      <VisitModal isOpen={isOpen} onClose={closeModal} visit={data} />
      <IconButton
        size="small"
        sx={{ backgroundColor: theme.palette.grey[300] }}
        onClick={openModal}
      >
        <Iconify icon="ic:round-insert-drive-file" />
      </IconButton>
    </>
  );
}
