import { Skeleton, Stack, useTheme } from '@mui/material';

// ---------------------------------------------------------------------

export default function VisitNotesSkeleton() {
  const theme = useTheme();

  return (
    <Stack
      sx={{
        gap: 1,
        backgroundColor: theme.palette.background.default,
        borderRadius: 2,
        boxShadow: theme.customShadows.z8,
      }}
    >
      <Stack
        sx={{
          borderTopRightRadius: theme.spacing(2),
          borderTopLeftRadius: theme.spacing(2),
          gap: 0.5,
          paddingX: 3,
          paddingY: 2,
          backgroundColor: theme.palette.grey[300],
        }}
      >
        <Stack
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Skeleton variant="rounded" width={165} height={28} />
          <Skeleton variant="rounded" width={96} height={24} />
        </Stack>
        <Skeleton variant="rounded" width={350} height={20} />
      </Stack>
      <Stack
        sx={{
          paddingX: 3,
          paddingY: 2,
          paddingTop: 1,
          gap: 2,
        }}
      >
        <Skeleton variant="rounded" width="100%" height={235} />
      </Stack>
    </Stack>
  );
}
