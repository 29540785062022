import { differenceInCalendarMonths, differenceInCalendarWeeks, differenceInCalendarDays } from "date-fns"

export const getAgeFormat = (date: string) => {
    const currentDate = new Date();
    const birthDate = new Date(date);

    const totalMonths = differenceInCalendarMonths(currentDate, birthDate);
    const years = Math.floor(totalMonths / 12);
    const months = totalMonths % 12;
    const days = differenceInCalendarDays(currentDate, birthDate);

    if (years > 0) {
        if (years > 1 && years < 5) {
            return `${years} lata`;
        }
        
        if (years > 4) {
            return `${years} lat`;
        }
        return `${years} rok`;
    }

    if (months > 0) {
        if (months > 1 && months < 5) {
            return `${months} miesiące`;
        }
        
        if (months > 4) {
            return `${months} miesięcy`;
        }
        return `${months} miesiąc`;
    }

    if (days > 6) {
        const weeks = differenceInCalendarWeeks(currentDate, birthDate);
        if (weeks > 1 && weeks < 5) {
            return `${weeks} tygodnie`;
        }
        
        if (weeks > 4) {
            return `${weeks} tygodni`;
        }
        return `${weeks} tydzień`;
    }

    if (days > 0) {
        return `${days} ${days > 1 ? 'dni' : 'dzień'}`;
    }
}
