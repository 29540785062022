// @mui
import { Divider, Stack } from '@mui/material';
// auth
import { useAuthContext } from '../../auth/useAuthContext';
// components
import { LoadingButton } from '@mui/lab';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { userApi } from 'src/api/user';
import { getFirebaseErrorMessage } from 'src/utils/getFirebaseErrorMessage';
import { CreateUserDto } from 'woofwoof-api';
import Iconify from '../../components/iconify';

// ----------------------------------------------------------------------

export default function AuthWithSocial() {
  const { mutateAsync } = useMutation({
    mutationFn: (createUserDto: CreateUserDto) =>
      userApi.userControllerCreateUser({ createUserDto }),
  });
  const { loginWithGoogle } = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const handleGoogleLogin = async () => {
    try {
      setIsLoading(true);
      if (loginWithGoogle) {
        const authResponse = await loginWithGoogle();
        await mutateAsync({
          firstName: authResponse.user?.displayName?.split(' ')[0] || '',
          lastName: authResponse.user?.displayName?.split(' ')[1] || '',
          avatar: authResponse.user?.photoURL || '',
          terms: false,
        });
      }
    } catch (error) {
      setIsLoading(false);
      enqueueSnackbar(getFirebaseErrorMessage(error.code) || error, {
        variant: 'error',
      });
    }
  };

  return (
    <div>
      <Stack direction="row" justifyContent="center" spacing={2}>
        <LoadingButton
          disabled={isLoading}
          fullWidth
          color="inherit"
          size="large"
          type="submit"
          variant="soft"
          onClick={handleGoogleLogin}
          startIcon={<Iconify icon="logos:google-icon" color="#DF3E30" />}
        >
          <span>Zaloguj się przez Google</span>
        </LoadingButton>
      </Stack>

      <Divider
        sx={{
          my: 3,
          typography: 'overline',
          color: 'text.disabled',
          '&::before, ::after': {
            borderTopStyle: 'dashed',
          },
        }}
      >
        LUB
      </Divider>
    </div>
  );
}
