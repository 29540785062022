import { createContext, useEffect, useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { visitApi } from 'src/api/visit';
import { useUserContext } from 'src/auth/useUserContext';
import { GetVisitById } from 'woofwoof-api';

// ----------------------------------------------------------------------

export type LiveVisitContextType = {
  data?: GetVisitById;
  isLoading: boolean;
  isError: boolean;
  refetch: () => void;
};

export const LiveVisitContext = createContext<LiveVisitContextType | null>(
  null,
);

type LiveVisitProviderProps = {
  children: React.ReactNode;
};

export function LiveVisitProvider({ children }: LiveVisitProviderProps) {
  const { visitId } = useParams();
  const { isUserRole } = useUserContext();
  const { refetch, data, isLoading, isError, remove } = useQuery(
    ['liveVisit'],
    () => visitApi.visitControllerGetVisitById({ visitId: visitId as string }),
    {
      retry: 1,
      enabled: Boolean(isUserRole),
      cacheTime: 0,
    },
  );

  useEffect(() => {
    return () => {
      remove();
    };
  }, [remove]);

  const memoizedValue = useMemo(() => {
    return {
      data: data?.data,
      isLoading,
      isError,
      refetch,
    };
  }, [data, isLoading, isError, refetch]);

  return (
    <LiveVisitContext.Provider value={memoizedValue}>
      {children}
    </LiveVisitContext.Provider>
  );
}
