import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useUserContext } from './useUserContext';

// ----------------------------------------------------------------------

type ScreenSizeGuardProps = {
  children: React.ReactNode;
  roles: ['PROFESSIONAL' | 'USER'];
  smallerThan: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
};
export enum Roles {
  professional = 'PROFESSIONAL',
  user = 'USER',
}

export default function ScreenSizeGuard({
  children,
  roles,
  smallerThan,
}: ScreenSizeGuardProps) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down(smallerThan));
  const { isProfessionalRole } = useUserContext();

  if (matches && isProfessionalRole) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
          width: '100vw',
          px: 3,
        }}
      >
        <Typography variant="h4" align="center">
          Aplikacja nie wspiera tego rozmiaru ekranu
        </Typography>
      </Box>
    );
  }
  return <>{children}</>;
}
