// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import { LabelColor } from 'src/components/label';
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  visit: icon('ic_videocam'),
  waitingRoom: icon('ic_user'),
  visitsHistory: icon('ic_kanban'),
  calendar: icon('ic_calendar'),
};

const getNavConfig = (profileId?: string) => [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: '',
    items: [
      ...(profileId
        ? [
            {
              title: 'E-spotkanie',
              path: PATH_DASHBOARD.general.profile.visit(profileId),
              activeForPaths: [
                PATH_DASHBOARD.general.profile.root(profileId),
                PATH_DASHBOARD.general.profile.visitsHistory(profileId),
                PATH_DASHBOARD.general.profile.files(profileId),
                PATH_DASHBOARD.general.profile.notes(profileId),
              ],
              icon: ICONS.visit,
              label: { color: 'warning' as LabelColor, text: 'LIVE' },
            },
          ]
        : []),
      {
        title: 'Poczekalnia',
        path: PATH_DASHBOARD.general.waitingRoom,
        icon: ICONS.waitingRoom,
      },
      {
        title: 'Historia e-spotkań',
        path: PATH_DASHBOARD.general.visitsHistory,
        icon: ICONS.visitsHistory,
      },
      {
        title: 'Kalendarz',
        path: PATH_DASHBOARD.general.calendar,
        icon: ICONS.calendar,
      },
    ],
  },
];

export default getNavConfig;
