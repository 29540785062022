// @mui
import { LoadingButton } from '@mui/lab';
import { Avatar, Box, Grid, Stack, Typography, useTheme } from '@mui/material';
import { format } from 'date-fns';
import { pl } from 'date-fns/locale';
import { useUserContext } from 'src/auth/useUserContext';
import {
  GetVisitById,
  GetVisitByIdStatusEnum,
  PaymentStatusEnum,
} from 'woofwoof-api';
import Iconify from '../../../../components/iconify';
import { VISIT_TYPE_MATRIX } from './BookingForm';

// ---------------------------------------------------------------------

type UpcomingVisitCardProps = {
  visit?: GetVisitById;
  isLoading: boolean;
  isFirst: boolean;
  onClick?: ({
    profileId,
    visitId,
  }: {
    profileId: string;
    visitId: string;
  }) => void;
};

// TODO: Add proper type for transaction status
export const paymentStatus = {
  [PaymentStatusEnum.Verified]: 'Opłacona',
  [PaymentStatusEnum.Created]: 'Oczekująca',
  [PaymentStatusEnum.Failed]: 'Odrzucona',
  [PaymentStatusEnum.Refunded]: 'Zwrócona',
};

export default function UpcomingVisitCard({
  visit,
  onClick,
  isFirst = false,
  isLoading = false,
}: UpcomingVisitCardProps) {
  const { isUserRole, isProfessionalRole } = useUserContext();
  const theme = useTheme();
  const isPending = visit?.status === GetVisitByIdStatusEnum.Pending;
  const isCreated = visit?.status === GetVisitByIdStatusEnum.Created;
  const isPaymentFailed = visit?.payment?.status === PaymentStatusEnum.Failed;
  const isPaymentPending = visit?.payment?.status === PaymentStatusEnum.Created;
  const isStartedByProfessional = (visit?.conversation as any)?.roomName;

  if (!visit) {
    return null;
  }

  const handleOnClick = () => {
    if (onClick && visit?.profile?.id) {
      onClick({ visitId: visit.id, profileId: visit.profile.id });
    }
  };

  return (
    <Box
      sx={{
        p: 3,
        borderRadius: 2,
        background: theme.palette.background.default,
        boxShadow: theme.customShadows.z8,
      }}
    >
      <Grid container spacing={3}>
        <Grid container item>
          <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
            <Typography
              variant="h5"
              sx={{ color: theme.palette.text.secondary }}
            >
              {isFirst ? 'Najbliższe e-spotkanie' : 'E-spotkanie'}{' '}
              <Box component="span" sx={{ color: theme.palette.text.primary }}>
                {format(new Date(visit.startTime), 'd MMMM', {
                  locale: pl,
                })}
              </Box>{' '}
              o godzinie{' '}
              <Box component="span" sx={{ color: theme.palette.text.primary }}>
                {format(new Date(visit.startTime), 'H:mm', {
                  locale: pl,
                })}
              </Box>
            </Typography>
          </Grid>
          {visit.payment?.status && isUserRole && (
            <Grid item xs={12} sm={12} md={4} lg={6} xl={6}>
              <Typography
                variant="h5"
                sx={{
                  textAlign: {
                    xs: 'left',
                    sm: 'left',
                    md: 'right',
                    lg: 'right',
                    xl: 'right',
                  },
                  color: theme.palette.text.secondary,
                }}
              >
                Płatność:{' '}
                <Box
                  component="span"
                  sx={{ color: theme.palette.text.primary }}
                >
                  {paymentStatus[visit.payment.status]}
                </Box>
              </Typography>
            </Grid>
          )}
          {visit.category && isProfessionalRole && (
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography
                variant="h5"
                sx={{
                  textAlign: {
                    xs: 'left',
                    sm: 'left',
                    md: 'left',
                    lg: 'right',
                    xl: 'right',
                  },
                  color: theme.palette.text.secondary,
                }}
              >
                Rodzaj:{' '}
                <Box
                  component="span"
                  sx={{ color: theme.palette.text.primary }}
                >
                  {VISIT_TYPE_MATRIX[visit.category] || ''}
                </Box>
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid
          container
          item
          spacing={{ xs: 1, sm: 1, md: 2, lg: 3, xl: 3 }}
          alignItems="center"
        >
          <Grid item xs={12} sm={12} md lg xl>
            <Box
              sx={{
                gap: theme.spacing(2),
                display: 'flex',
                background: theme.palette.primary.light,
                borderRadius: 2,
                p: 2,
                alignItems: 'center',
              }}
            >
              <Avatar
                src={visit?.profile?.avatar || '/assets/avatars/avatar4.png'}
                sx={{ width: theme.spacing(8), height: theme.spacing(8) }}
              />
              <Stack
                sx={{
                  gap: theme.spacing(0.5),
                  width: '65%',
                }}
              >
                <Typography variant="h5" noWrap>
                  {visit?.profile?.name}
                </Typography>
                <Typography sx={{ color: theme.palette.text.secondary }}>
                  {visit?.profile?.breed}
                </Typography>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md="auto" lg="auto" xl="auto">
            <Typography sx={{ fontSize: '24px', textAlign: 'center' }}>
              z
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md lg xl>
            <Box
              sx={{
                gap: theme.spacing(2),
                display: 'flex',
                background: theme.palette.secondary.light,
                borderRadius: 2,
                p: 2,
                alignItems: 'center',
              }}
            >
              <Avatar
                src={visit?.professional?.avatar}
                sx={{ width: theme.spacing(8), height: theme.spacing(8) }}
              />
              <Stack
                sx={{
                  gap: theme.spacing(0.5),
                  width: '65%',
                }}
              >
                <Typography variant="h5" noWrap>
                  {visit?.professional?.firstName
                    ? `${visit.professional.firstName}`
                    : `${visit?.user?.firstName} ${visit?.user?.lastName}`}
                </Typography>
                <Typography sx={{ color: theme.palette.text.secondary }}>
                  {visit?.professional?.firstName
                    ? VISIT_TYPE_MATRIX[visit.category] || ''
                    : 'Właściciel'}
                </Typography>
              </Stack>
            </Box>
          </Grid>
          {onClick && (
            <Grid item xs>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <LoadingButton
                  fullWidth
                  variant="contained"
                  color="primary"
                  size="large"
                  loading={isLoading}
                  startIcon={
                    isPaymentFailed && isUserRole ? null : (
                      <Iconify icon="eva:video-fill" />
                    )
                  }
                  disabled={
                    !isFirst ||
                    (isProfessionalRole && isPending) ||
                    (isUserRole && isCreated && !isPaymentFailed) ||
                    (isUserRole && isPaymentPending) ||
                    (isUserRole && !isStartedByProfessional && !isPaymentFailed)
                  }
                  onClick={handleOnClick}
                >
                  <span>
                    {isPaymentFailed && isUserRole
                      ? 'Ponów płatność'
                      : isPending && isProfessionalRole
                      ? 'W trakcie'
                      : isUserRole
                      ? 'Dołącz'
                      : 'Rozpocznij'}
                  </span>
                </LoadingButton>
              </Box>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
