// @mui
import { LoadingButton } from '@mui/lab';
import {
  Avatar,
  Box,
  Divider,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { format, isAfter, isEqual } from 'date-fns';
import { pl } from 'date-fns/locale';
import { useEffect, useState } from 'react';
import Iconify from 'src/components/iconify';
import {
  GetVisitById,
  GetVisitByIdProviderEnum,
  GetVisitByIdStatusEnum,
  PaymentStatusEnum,
} from 'woofwoof-api';
import { VISIT_TYPE_MATRIX } from './BookingForm';
import DocumentCarousel from './DocumentCarousel';
import UserUpcomingVisitCardCancelButton from './UserUpcomingVisitCardCancelButton';
import UserUpcomingVisitCardRescheduleButton from './UserUpcomingVisitCardRescheduleButton';
import UserUpcomingVisitCardUploadButton from './UserUpcomingVisitCardUploadButton';

// ---------------------------------------------------------------------

type UserUpcomingVisitCardProps = {
  visit?: GetVisitById;
  isLoading: boolean;
  onMainAction: ({
    profileId,
    visitId,
  }: {
    profileId: string;
    visitId: string;
  }) => void;
  onCancelVisit: ({ visitId }: { visitId: string }) => void;
  onRescheduleVisit: ({
    visitId,
    profileId,
    provider,
  }: {
    visitId: string;
    profileId: string;
    provider: GetVisitByIdProviderEnum;
  }) => void;
  onUploadFiles: (visitId: string) => void;
};

// TODO: Add proper type for transaction status
export const paymentStatus = {
  [PaymentStatusEnum.Verified]: 'Opłacona',
  [PaymentStatusEnum.Created]: 'Oczekująca',
  [PaymentStatusEnum.Failed]: 'Odrzucona',
  [PaymentStatusEnum.Refunded]: 'Zwrócona',
};

let isVisitTimeInterval: NodeJS.Timer;
let isVisitCancelOrRescheduleAvailableInterval: NodeJS.Timer;

export const NUMBER_OF_HOURS_BEFORE_LOCK = 6;

export const isTimeEqualOrPast = (inputTime: string): boolean => {
  const now = new Date();
  const visitTime = new Date(inputTime);

  return isEqual(now, visitTime) || isAfter(now, visitTime);
};

export default function UserUpcomingVisitCard({
  visit,
  onMainAction,
  isLoading = false,
  onCancelVisit,
  onRescheduleVisit,
  onUploadFiles,
}: UserUpcomingVisitCardProps) {
  const theme = useTheme();
  const [isJoinEnabled, setIsJoinEnabled] = useState(false);
  const [isRescheduleOrCancelAvailable, setIsRescheduleOrCancelAvailable] =
    useState(false);
  const isCreated = visit?.status === GetVisitByIdStatusEnum.Created;
  const isPaymentFailed = visit?.payment?.status === PaymentStatusEnum.Failed;
  const isPaymentPending = visit?.payment?.status === PaymentStatusEnum.Created;
  const isStartedByProfessional = (visit?.conversation as any)?.roomName;
  const matchesQuery = useMediaQuery(theme.breakpoints.down('lg'));

  useEffect(() => {
    if (visit?.startTime) {
      const isVisitTime = isTimeEqualOrPast(visit.startTime);
      if (!isJoinEnabled && isVisitTime) {
        setIsJoinEnabled(true);
      }
    }
  }, [isJoinEnabled, visit?.startTime]);

  useEffect(() => {
    if (visit?.startTime) {
      isVisitTimeInterval = setInterval(() => {
        const isVisitTime = isTimeEqualOrPast(visit.startTime);
        if (!isJoinEnabled && isVisitTime) {
          setIsJoinEnabled(true);
        }
      }, 10000);
    }

    return () => {
      clearInterval(isVisitTimeInterval);
    };
  }, [isJoinEnabled, visit?.startTime]);

  useEffect(() => {
    if (visit?.startTime) {
      const visitTime = new Date(visit.startTime);
      const date = new Date(
        visitTime.setHours(visitTime.getHours() - NUMBER_OF_HOURS_BEFORE_LOCK),
      ).toISOString();
      const _isRescheduleOrCancelAvailable = !isTimeEqualOrPast(date);
      if (!isRescheduleOrCancelAvailable && _isRescheduleOrCancelAvailable) {
        setIsRescheduleOrCancelAvailable(true);
      }
    }
  }, [isRescheduleOrCancelAvailable, visit?.startTime]);

  useEffect(() => {
    if (visit?.startTime) {
      isVisitCancelOrRescheduleAvailableInterval = setInterval(() => {
        const visitTime = new Date(visit.startTime);
        const date = new Date(
          visitTime.setHours(
            visitTime.getHours() - NUMBER_OF_HOURS_BEFORE_LOCK,
          ),
        ).toISOString();
        const _isRescheduleOrCancelAvailable = !isTimeEqualOrPast(date);
        if (!isRescheduleOrCancelAvailable && _isRescheduleOrCancelAvailable) {
          setIsRescheduleOrCancelAvailable(true);
        }
      }, 2000);
    }

    return () => {
      clearInterval(isVisitCancelOrRescheduleAvailableInterval);
    };
  }, [isRescheduleOrCancelAvailable, visit?.startTime]);

  if (!visit) {
    return null;
  }

  const handleOnMainActionClick = () => {
    if (visit?.profile?.id) {
      onMainAction({ visitId: visit.id, profileId: visit.profile.id });
    }
  };

  return (
    <Box
      sx={{
        borderRadius: 2,
        background: theme.palette.background.default,
        boxShadow: theme.customShadows.z8,
        marginTop: { xs: 1, sm: 1, md: 1, lg: 0, xl: 0 },
      }}
    >
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
          <Grid
            container
            item
            rowSpacing={2}
            p={3}
            paddingBottom={{ xs: 3 / 2, sm: 3 / 2, md: 3 / 2, lg: 3, xl: 3 }}
          >
            <Grid container item>
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  sx={{ color: theme.palette.text.secondary }}
                >
                  {'Najbliższe e-spotkanie'}{' '}
                  <Box
                    component="span"
                    sx={{ color: theme.palette.text.primary }}
                  >
                    {format(new Date(visit.startTime), 'd MMMM', {
                      locale: pl,
                    })}
                  </Box>{' '}
                  o godzinie{' '}
                  <Box
                    component="span"
                    sx={{ color: theme.palette.text.primary }}
                  >
                    {format(new Date(visit.startTime), 'H:mm', {
                      locale: pl,
                    })}
                  </Box>
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              item
              spacing={{ xs: 1, sm: 1, md: 2, lg: 3, xl: 3 }}
              alignItems="center"
            >
              <Grid item xs={12} sm={12} md lg xl>
                <Box
                  sx={{
                    gap: theme.spacing(2),
                    display: 'flex',
                    background: theme.palette.primary.light,
                    borderRadius: 2,
                    p: 2,
                    alignItems: 'center',
                  }}
                >
                  <Avatar
                    src={
                      visit?.profile?.avatar || '/assets/avatars/avatar4.png'
                    }
                    sx={{ width: theme.spacing(8), height: theme.spacing(8) }}
                  />
                  <Stack
                    sx={{
                      gap: theme.spacing(0.5),
                    }}
                  >
                    <Typography variant="h5">{visit?.profile?.name}</Typography>
                    <Typography sx={{ color: theme.palette.text.secondary }}>
                      {visit?.profile?.breed}
                    </Typography>
                  </Stack>
                </Box>
              </Grid>

              <Grid item xs={12} sm={12} md="auto" lg="auto" xl="auto">
                <Typography sx={{ fontSize: '24px', textAlign: 'center' }}>
                  z
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md lg xl>
                <Box
                  sx={{
                    gap: theme.spacing(2),
                    display: 'flex',
                    background: theme.palette.secondary.light,
                    borderRadius: 2,
                    p: 2,
                    alignItems: 'center',
                  }}
                >
                  <Avatar
                    src={visit?.professional?.avatar}
                    sx={{ width: theme.spacing(8), height: theme.spacing(8) }}
                  />
                  <Stack
                    sx={{
                      gap: theme.spacing(0.5),
                    }}
                  >
                    <Typography variant="h5">
                      {visit?.professional?.firstName
                        ? `${visit.professional.firstName} ${visit.professional.lastName}`
                        : `${visit?.user?.firstName} ${visit?.user?.lastName}`}
                    </Typography>
                    <Typography sx={{ color: theme.palette.text.secondary }}>
                      {visit?.professional?.firstName
                        ? VISIT_TYPE_MATRIX[visit.category] || ''
                        : 'Właściciel'}
                    </Typography>
                  </Stack>
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Stack
                sx={{
                  gap: 2,
                }}
              >
                <Stack sx={{ gap: 1 }}>
                  <Typography>Dolegliwości</Typography>
                  <Typography color="text.secondary">
                    {visit.description}
                  </Typography>
                </Stack>
                {!!visit.documents.length && (
                  <Stack sx={{ gap: 1 }}>
                    <Typography>Załączniki</Typography>
                    <DocumentCarousel documents={visit.documents as any} />
                  </Stack>
                )}
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        {!matchesQuery && (
          <Divider
            flexItem
            orientation="vertical"
            sx={{ borderStyle: 'dashed' }}
          />
        )}

        <Grid item xs>
          {matchesQuery && (
            <Divider
              flexItem
              orientation="horizontal"
              sx={{ borderStyle: 'dashed', mt: 2, mb: 1 }}
            />
          )}
          <Stack
            p={3}
            paddingTop={{ xs: 3 / 2, sm: 3 / 2, md: 3 / 2, lg: 3, xl: 3 }}
            sx={{ gap: 2, height: '100%' }}
          >
            {visit.payment && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: 2,
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    textAlign: 'right',
                    color: theme.palette.text.secondary,
                    '@media (max-width:360px)': { fontSize: '16px' },
                  }}
                >
                  Status płatności:
                </Typography>
                <Typography
                  variant="h5"
                  sx={{
                    color: theme.palette.text.primary,
                    '@media (max-width:360px)': { fontSize: '16px' },
                  }}
                >
                  {paymentStatus[visit.payment.status]}
                </Typography>
              </Box>
            )}
            <Stack sx={{ gap: 4, justifyContent: 'space-between', flex: 1 }}>
              <Stack sx={{ gap: 2 }}>
                <UserUpcomingVisitCardCancelButton
                  visit={visit}
                  isRescheduleOrCancelAvailable={isRescheduleOrCancelAvailable}
                  onCancelVisit={onCancelVisit}
                />
                <UserUpcomingVisitCardRescheduleButton
                  visit={visit}
                  isRescheduleOrCancelAvailable={isRescheduleOrCancelAvailable}
                  onRescheduleVisit={onRescheduleVisit}
                />
                <UserUpcomingVisitCardUploadButton
                  visit={visit}
                  onUploadFiles={onUploadFiles}
                />
              </Stack>
              <Stack sx={{ mb: matchesQuery ? 3 : 0, gap: 1 }}>
                <LoadingButton
                  fullWidth
                  variant="contained"
                  color="primary"
                  size="large"
                  loading={isLoading}
                  startIcon={
                    isPaymentFailed ? (
                      <Iconify icon="eva:shopping-cart-fill" />
                    ) : (
                      <Iconify icon="eva:video-fill" />
                    )
                  }
                  disabled={
                    (isCreated && !isPaymentFailed) ||
                    isPaymentPending ||
                    (!isStartedByProfessional && !isPaymentFailed) ||
                    (!isJoinEnabled && !isPaymentFailed)
                  }
                  onClick={handleOnMainActionClick}
                >
                  <span>
                    {isPaymentFailed
                      ? 'Ponów płatność'
                      : 'Rozpocznij e-spotkanie'}
                  </span>
                </LoadingButton>
                {isPaymentFailed && (
                  <Typography
                    color="error.dark"
                    variant="caption"
                    sx={{
                      textAlign: 'center',
                    }}
                  >
                    Twoja płatność została odrzucona, aby sfinalziować
                    e-spotkanie, opłać ją ponownie.
                  </Typography>
                )}
                {!isPaymentFailed &&
                  !isStartedByProfessional &&
                  isJoinEnabled && (
                    <Typography
                      color="text.secondary"
                      variant="caption"
                      sx={{
                        textAlign: 'center',
                      }}
                    >
                      Oczekiwanie na rozpoczęcie e-spotkania przez specjalistę.
                    </Typography>
                  )}
                {!isPaymentFailed && !isJoinEnabled && !isPaymentPending && (
                  <Typography
                    color="text.secondary"
                    variant="caption"
                    sx={{
                      textAlign: 'center',
                    }}
                  >
                    Dołączenie do e-spoktania będzie możliwe o zarezerwowanej
                    godzinie.
                  </Typography>
                )}
                {isPaymentPending && !isJoinEnabled && !isPaymentFailed && (
                  <Typography
                    color="text.secondary"
                    variant="caption"
                    sx={{
                      textAlign: 'center',
                    }}
                  >
                    Twoja płatność jest przetwarzana.
                  </Typography>
                )}
                {isJoinEnabled && isStartedByProfessional && (
                  <Typography
                    color="text.secondary"
                    variant="caption"
                    sx={{
                      textAlign: 'center',
                    }}
                  >
                    Specjalista rozpoczął e-spotkanie. Możesz dołączyć.
                  </Typography>
                )}
              </Stack>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}
