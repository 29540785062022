import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import {
  EmailAuthProvider,
  GithubAuthProvider,
  GoogleAuthProvider,
  TwitterAuthProvider,
  User,
  UserCredential,
  applyActionCode,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  deleteUser,
  getAuth,
  onAuthStateChanged,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  updatePassword,
} from 'firebase/auth';
import { getPerformance } from 'firebase/performance';
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
// config
import { FIREBASE_API } from '../config-global';
//
import { PATH_AUTH } from 'src/routes/paths';
import {
  ActionMapType,
  AuthStateType,
  AuthUserType,
  FirebaseContextType,
} from './types';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

enum Types {
  INITIAL = 'INITIAL',
}

type Payload = {
  [Types.INITIAL]: {
    isAuthenticated: boolean;
    user: AuthUserType;
  };
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext<FirebaseContextType | null>(null);

// ----------------------------------------------------------------------

const app = initializeApp(FIREBASE_API);

getAnalytics(app);
getPerformance(app);

const GOOGLE_PROVIDER = new GoogleAuthProvider();

const GITHUB_PROVIDER = new GithubAuthProvider();

const TWITTER_PROVIDER = new TwitterAuthProvider();

type AuthProviderProps = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  // LOGOUT
  const logout = useCallback(async () => {
    await signOut(getAuth());
  }, []);

  const initialize = useCallback(() => {
    try {
      onAuthStateChanged(getAuth(), async (user) => {
        if (user) {
          if (user.emailVerified) {
            dispatch({
              type: Types.INITIAL,
              payload: {
                ...state,
                isAuthenticated: true,
                user: {
                  ...user,
                },
              },
            });
          } else {
            dispatch({
              type: Types.INITIAL,
              payload: {
                ...state,
                isAuthenticated: false,
                user: null,
              },
            });

            await signOut(getAuth());
            window.location.replace(PATH_AUTH.confirmation);
          }
        } else {
          dispatch({
            type: Types.INITIAL,
            payload: {
              ...state,
              isAuthenticated: false,
              user: null,
            },
          });
        }
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(
    async (email: string, password: string): Promise<User> => {
      const userCredentials = await signInWithEmailAndPassword(
        getAuth(),
        email,
        password,
      );

      return userCredentials.user;
    },
    [],
  );

  const loginWithCustomToken = useCallback(
    async (customToken: string): Promise<User | null> => {
      try {
        const userCredential = await signInWithCustomToken(
          getAuth(),
          customToken,
        );

        dispatch({
          type: Types.INITIAL,
          payload: {
            isAuthenticated: true,
            user: userCredential.user,
          },
        });

        return userCredential.user;
      } catch (error) {
        return null;
      }
    },
    [],
  );

  const loginWithGoogle = useCallback(async (): Promise<UserCredential> => {
    return signInWithPopup(getAuth(), GOOGLE_PROVIDER);
  }, []);

  const loginWithGithub = useCallback(() => {
    signInWithPopup(getAuth(), GITHUB_PROVIDER);
  }, []);

  const loginWithTwitter = useCallback(() => {
    signInWithPopup(getAuth(), TWITTER_PROVIDER);
  }, []);

  // REGISTER
  const register = useCallback(async (email: string, password: string) => {
    dispatch({
      type: Types.INITIAL,
      payload: {
        isAuthenticated: false,
        user: null,
      },
    });

    return createUserWithEmailAndPassword(getAuth(), email, password);
  }, []);

  // DELETE
  const removeUser = useCallback(async (user: User) => {
    await deleteUser(user);
  }, []);

  // SEND RESET PASSWORD LINK
  const sendPasswordResetLink = useCallback(async (email: string) => {
    const auth = getAuth();
    await sendPasswordResetEmail(auth, email);
  }, []);

  // CONFIRM PASSWORD RESET
  const resetPassword = useCallback(
    async (oobCode: string, newPassword: string) => {
      const auth = getAuth();
      if (!oobCode && !newPassword) return;
      return await confirmPasswordReset(auth, oobCode, newPassword);
    },
    [],
  );
  // UPDATE PASSWORD
  const changePassword = useCallback(
    async (oldPassword: string, newPassword: string) => {
      const user = getAuth().currentUser;

      if (user?.email) {
        const credential = EmailAuthProvider.credential(
          user.email,
          oldPassword,
        );

        await reauthenticateWithCredential(user, credential);
        await updatePassword(user, newPassword);
      }
    },
    [],
  );

  const verifyEmail = useCallback(async (oobCode: string) => {
    await applyActionCode(getAuth(), oobCode);
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      method: 'firebase',
      login,
      loginWithGoogle,
      loginWithGithub,
      loginWithTwitter,
      register,
      logout,
      removeUser,
      changePassword,
      verifyEmail,
      sendPasswordResetLink,
      resetPassword,
      loginWithCustomToken,
    }),
    [
      state.isAuthenticated,
      state.isInitialized,
      state.user,
      login,
      loginWithGithub,
      loginWithGoogle,
      loginWithTwitter,
      register,
      logout,
      removeUser,
      changePassword,
      verifyEmail,
      sendPasswordResetLink,
      resetPassword,
      loginWithCustomToken,
    ],
  );

  return (
    <AuthContext.Provider value={memoizedValue}>
      {children}
    </AuthContext.Provider>
  );
}
