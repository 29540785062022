import { Stack } from '@mui/material';
import VisitDetails from 'src/sections/@dashboard/visit/components/VisitDetails';
import VisitDetailsSkeleton from 'src/sections/@dashboard/visit/components/VisitDetailsSkeleton';
import VisitSteps from 'src/sections/@dashboard/visit/components/VisitSteps';
import VisitStepsSkeleton from 'src/sections/@dashboard/visit/components/VisitStepsSkeleton';
import { useCurrentVisitContext } from 'src/sections/@dashboard/visit/hooks/useCurrentVisitContext';

export default function ProfileTabsVisitPage() {
  const { isLoading } = useCurrentVisitContext();

  return (
    <Stack sx={{ height: 1, gap: 3 }}>
      {!isLoading ? <VisitSteps /> : <VisitStepsSkeleton />}
      {!isLoading ? <VisitDetails /> : <VisitDetailsSkeleton />}
    </Stack>
  );
}
