import client, { axiosBaseUrl } from './client';
import { ConversationApi } from 'woofwoof-api';

export const conversationApi = new ConversationApi(
  {
    basePath: axiosBaseUrl,
    isJsonMime: () => false,
  },
  undefined,
  //@ts-ignore
  client,
);
