import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, BoxProps, Link, useTheme } from '@mui/material';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
  variant?: 'dark' | 'blue';
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, variant = 'dark', sx, ...other }, ref) => {
    const theme = useTheme();

    const PRIMARY_MAIN = theme.palette.text.primary;

    const logoDark = (
      <svg
        id="Warstwa_1"
        data-name="Warstwa 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 566.93 187.09"
        width="100%"
        height="100%"
      >
        <polygon
          className="cls-1"
          points="144.21 162.68 108.85 69.5 85.5 69.5 128.57 182.85 158.49 182.85 201.56 69.5 179.57 69.5 144.21 162.68"
          fill={PRIMARY_MAIN}
        />
        <path
          className="cls-1"
          d="m318.72,123.91c0-32.42-18.59-58.03-54.63-58.03-38.77,0-57.36,26.98-57.36,60.07s17,60.53,57.36,60.53,52.37-22.22,54.41-37.86h-20.86c-3.17,11.79-12.24,21.08-32.87,21.08-19.5,0-34-8.61-35.82-37.18h89.32c.45-2.95.45-5.67.45-8.61Zm-89.55-7.93c2.72-25.16,15.64-33.32,35.14-33.32s31.51,10.43,32.64,33.32h-67.78Z"
          fill={PRIMARY_MAIN}
        />
        <path
          className="cls-1"
          d="m370.77,43.89h-11.34l-9.29,23.12-23.58,8.16v11.79h21.54v62.57c0,22.67,6.8,33.32,34.23,33.32h22.44v-18.14h-34v-77.76h36.27v-17.46h-36.27v-25.62Z"
          fill={PRIMARY_MAIN}
        />
        <path
          className="cls-1"
          d="m486.31,116.43l-24.48-3.63c-12.24-1.81-15.64-7.48-15.64-14.28,0-9.07,7.03-16.1,24.48-16.1s25.84,8.84,26.98,19.27h22.67c-1.59-21.31-19.72-36.04-48.97-36.04s-47.15,13.15-47.15,34.91c0,17.23,10.65,28.11,30.38,31.06l28.11,4.31c12.01,1.81,17.23,7.03,17.23,16.32,0,10.43-4.99,17.46-25.16,17.46-21.99,0-31.28-10.88-32.19-24.71h-21.08c0,23.12,15.19,41.49,52.59,41.49,32.87,0,48.06-12.01,48.06-35.36,0-17.46-7.71-30.38-35.82-34.69Z"
          fill={PRIMARY_MAIN}
        />
        <rect
          className="cls-1"
          x="544.26"
          y="69.5"
          width="22.67"
          height="113.35"
          fill={PRIMARY_MAIN}
        />
        <polygon
          className="cls-1"
          points="47.04 .61 34.21 .61 25.66 26.45 0 35.07 0 47.99 25.66 56.6 34.21 82.45 47.04 82.45 55.59 56.6 81.24 47.99 81.24 35.07 55.59 26.45 47.04 .61"
          fill={PRIMARY_MAIN}
        />
      </svg>
    );

    const logoBlue = (
      <svg
        width="113px"
        height="40px"
        viewBox="0 0 230 76"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M34.6336 27.9098L52.082 73.8265H64.204L81.6523 27.9098H72.7445L58.4185 65.6533L44.0925 27.9098H34.6336Z"
          fill="#50CEFF"
        />
        <path
          d="M106.979 75.2958C123.142 75.2958 128.193 66.2961 129.019 59.9596H120.57C119.285 64.735 115.611 68.5001 107.255 68.5001C99.3568 68.5001 93.4795 65.0105 92.7448 53.4395H128.927C129.111 52.2456 129.111 51.1436 129.111 49.9498C129.111 36.8176 121.581 26.4404 106.979 26.4404C91.2755 26.4404 83.7451 37.3686 83.7451 50.7763C83.7451 64.3676 90.6326 75.2958 106.979 75.2958ZM92.8366 46.7356C93.9386 36.5421 99.1731 33.2361 107.071 33.2361C115.244 33.2361 119.836 37.4604 120.295 46.7356H92.8366Z"
          fill="#50CEFF"
        />
        <path
          d="M154.881 73.8265H163.972V66.4798H150.197V34.9809H164.891V27.9098H150.197V17.5326H145.606L141.841 26.8996L132.29 30.2056V34.9809H141.014V60.327C141.014 69.5103 143.769 73.8265 154.881 73.8265Z"
          fill="#50CEFF"
        />
        <path
          d="M179.276 58.4903H170.736C170.736 67.8573 176.889 75.2958 192.041 75.2958C205.357 75.2958 211.51 70.4286 211.51 60.9698C211.51 53.8986 208.388 48.6641 197 46.9193L187.082 45.4499C182.123 44.7153 180.746 42.4194 180.746 39.6644C180.746 35.9911 183.592 33.1443 190.664 33.1443C197.735 33.1443 201.133 36.7258 201.592 40.9501H210.775C210.132 32.3178 202.786 26.3486 190.939 26.3486C178.909 26.3486 171.838 31.6749 171.838 40.4909C171.838 47.4703 176.154 51.8783 184.144 53.0721L195.531 54.817C200.398 55.5516 202.51 57.6638 202.51 61.429C202.51 65.6533 200.49 68.5001 192.317 68.5001C183.409 68.5001 179.644 64.0921 179.276 58.4903Z"
          fill="#50CEFF"
        />
        <path
          d="M220.475 73.8265H229.658V27.9098H220.475V73.8265Z"
          fill="#50CEFF"
        />
        <path
          d="M22.5179 10.4696L19.0536 0H13.8571L10.3929 10.4696L0 13.9594V19.1942L10.3929 22.684L13.8571 33.1536H19.0536L22.5179 22.684L32.9107 19.1942V13.9594L22.5179 10.4696Z"
          fill="#50CEFF"
        />
      </svg>
    );

    const logo = (
      <Box
        ref={ref}
        component="div"
        sx={{
          display: 'inline-flex',
          ...sx,
        }}
        {...other}
      >
        {variant === 'dark' ? logoDark : logoBlue}
      </Box>
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} to="/" sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  },
);

export default Logo;
