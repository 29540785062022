type ErrorMessage = string;

export const EMAIL_ALREADY_IN_USE: ErrorMessage =
  'Psiakość! Adres e-mail już istnieje. Zaloguj się lub użyj opcji przypomnienia hasła.';

export const INVALID_EMAIL: ErrorMessage = 
  'Email musi być prawidłowy.';

export const INVALID_LOGIN_CREDENTIALS: ErrorMessage =
  'Psiakość! Nieprawidłowy adres e-mail lub hasło.';

export const WEAK_PASSWORD: ErrorMessage =
  'Hasło musi mieć co najmniej 6 znaków.';

export const DEFAULT_ERROR_MESSAGE: ErrorMessage =
  'Coś poszło nie tak :( Spróbuj ponownie później.';
