import { useState } from 'react';
import * as Yup from 'yup';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import { Card, Grid, IconButton, InputAdornment, Stack } from '@mui/material';
// auth
// components
import { useSnackbar } from 'notistack';
import { useAuthContext } from 'src/auth/useAuthContext';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import Iconify from 'src/components/iconify';
import { ref } from 'yup';

// ----------------------------------------------------------------------

type FormValuesProps = {
  oldPassword: string;
  password: string;
  repeatPassword: string;
};

export default function AccountChangePassword() {
  const { changePassword } = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const RegisterSchema: Yup.Schema<FormValuesProps> = Yup.object().shape({
    oldPassword: Yup.string().required('Stare hasło jest wymagane'),
    password: Yup.string()
      .required('Hasło jest wymagane')
      .min(6, 'Hasło musi mieć minimum 6 znaków'),
    repeatPassword: Yup.string()
      .required('Powtórzenie hasła jest wymagane')
      .oneOf([ref('password')], 'Hasła muszą być identyczne'),
  });

  const defaultValues = {
    password: '',
    repeatPassword: '',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const { handleSubmit, reset } = methods;

  const onSubmit = async ({ password, oldPassword }: FormValuesProps) => {
    setIsLoading(true);
    if (changePassword) {
      try {
        await changePassword(oldPassword, password);
        setIsLoading(false);
        reset();
        enqueueSnackbar('Hasło zostało zmienione', {
          variant: 'success',
        });
      } catch (error) {
        setIsLoading(false);
        enqueueSnackbar(error.message || error, {
          variant: 'error',
        });
      }
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Card sx={{ p: 3 }}>
            <Stack spacing={3}>
              <RHFTextField
                name="oldPassword"
                label="Stare hasło"
                type={showOldPassword ? 'text' : 'password'}
                disabled={isLoading}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowOldPassword(!showOldPassword)}
                        edge="end"
                      >
                        <Iconify
                          icon={
                            showOldPassword
                              ? 'eva:eye-fill'
                              : 'eva:eye-off-fill'
                          }
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <RHFTextField
                name="password"
                label="Hasło"
                helperText="Hasło musi mieć minimum 6 znaków"
                type={showPassword ? 'text' : 'password'}
                disabled={isLoading}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        <Iconify
                          icon={
                            showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'
                          }
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <RHFTextField
                name="repeatPassword"
                label="Powtórz hasło"
                type={showRepeatPassword ? 'text' : 'password'}
                disabled={isLoading}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          setShowRepeatPassword(!showRepeatPassword)
                        }
                        edge="end"
                      >
                        <Iconify
                          icon={
                            showRepeatPassword
                              ? 'eva:eye-fill'
                              : 'eva:eye-off-fill'
                          }
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>

            <Stack flexDirection="row" justifyContent="flex-end" sx={{ mt: 3 }}>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isLoading}
              >
                <span>Zapisz</span>
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
