import { memo } from 'react';
// @mui
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

function VetsiIcon({ ...other }: BoxProps) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }} {...other}>
      <svg
        width={(other.width as string) || 20}
        height={(other.height as string) || 20}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.0703 6.92966L11.3159 1.6665H8.68429L6.92991 6.92966L1.66675 8.68405V11.3156L6.92991 13.07L8.68429 18.3332H11.3159L13.0703 13.07L18.3334 11.3156V8.68405L13.0703 6.92966Z"
          fill={(other.color as string) || '#50CEFF'}
        />
      </svg>
    </Box>
  );
}

export default memo(VetsiIcon);
