import client, { axiosBaseUrl } from './client';
import { VisitApi } from 'woofwoof-api';

export const visitApi = new VisitApi(
  {
    basePath: axiosBaseUrl,
    isJsonMime: () => false,
  },
  undefined,
  //@ts-ignore
  client,
);
