import { Button, IconButton, Stack, Typography, alpha } from '@mui/material';
import {
  addDays,
  format,
  isAfter,
  isBefore,
  isToday,
  isTomorrow,
  startOfDay,
} from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { pl } from 'date-fns/locale';
import React, { useState } from 'react';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar/Scrollbar';
import { useResponsiveBreakpoints } from 'src/hooks/useResponsiveBreakpoints';
import DateSkeleton from './VisitCalendarHoursSkeleton';

interface IProps {
  dates: Array<{ date: Date; available: boolean }>;
  onSelect: (date: Date) => void;
  disabled?: boolean;
  isLoading?: boolean;
}

interface IDateAvailability {
  date: Date;
  available: boolean;
}

const CalendarComponent: React.FC<IProps> = ({
  dates,
  onSelect,
  disabled,
  isLoading,
}) => {
  const today = startOfDay(new Date());
  const [startOfWeek, setStartOfWeek] = useState<Date>(today);
  const [selectedHour, setSelectedHour] = useState<Date | null>(null);
  const [showAllHours, setShowAllHours] = useState(false);

  const maxDate = addDays(today, 11);

  const { isXs, isSm, isMd, isLg, isTabletSm, isTabletMd } =
    useResponsiveBreakpoints();

  const isSameDayHourAndMinute = (date1: Date, date2: Date) => {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate() &&
      date1.getHours() === date2.getHours() &&
      date1.getMinutes() === date2.getMinutes()
    );
  };

  const getDayName = (date: Date) => {
    if (isToday(date)) {
      return 'Dziś';
    }

    if (isTomorrow(date)) {
      return 'Jutro';
    }
    return format(date, 'EEE', { locale: pl }).replace('.', '');
  };

  const moveWeek = (days: number) => {
    setStartOfWeek((prevDate) => {
      const newDate = addDays(prevDate, days);
      const today = startOfDay(new Date());
      if (isBefore(newDate, today)) {
        return today;
      }
      return newDate;
    });
  };

  const daysLength = isSm ? 2 : isMd ? 3 : isTabletSm ? 4 : isLg ? 6 : 4;

  const days = Array.from({ length: daysLength }).map((_, i) =>
    addDays(startOfWeek, i),
  );
  const dateAvailabilities: IDateAvailability[][] = days.map((day) =>
    dates
      .filter(
        ({ date }) =>
          date.getDate() === day.getDate() &&
          date.getMonth() === day.getMonth() &&
          date.getFullYear() === day.getFullYear(),
      )
      // .slice(0, showAllHours ? undefined : 5)
      .map(({ date, available }) => ({
        date,
        available,
      })),
  );
  const isDateSpanEmpty = dateAvailabilities.every((day) => day.length === 0);

  return (
    <Stack>
      <Stack
        direction="row"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <IconButton
          onClick={() => moveWeek(-daysLength)}
          disabled={
            isBefore(startOfWeek, today) ||
            startOfWeek.getTime() === today.getTime() ||
            disabled
          }
          size="small"
          sx={{
            bgcolor: alpha('#50CEFF', 0.16),
            '&:hover': { bgcolor: 'primary.dark' },
            '&.Mui-disabled': { bgcolor: 'transparent' },
          }}
        >
          <Iconify icon="ic:round-chevron-left" width={40} />
        </IconButton>

        <Stack
          direction="column"
          gap={2}
          flex={1}
          justifyContent="center"
          sx={{
            mx: { md: 0, lg: 2 },
            width: 'calc(100% - 128px)',
            '@media (max-width:475px)': {
              width: 'calc(100% - 88px)',
              mx: 2,
            },
            '@media (max-width:360px)': {
              width: 'calc(100% - 128px)',
            },
            '@media (max-width:330px)': {
              width: 'calc(100% - 108px)',
            },
            '@media (max-width:300px)': {
              width: 'calc(100% - 78px)',
            },
          }}
        >
          <Stack flexDirection="row" flex={1} justifyContent="space-between">
            {dateAvailabilities.map((dayAvailabilities, i) => (
              <Stack
                sx={{
                  width: '83px',
                  '@media (max-width:475px)': {
                    width: '45px',
                  },
                }}
                key={i}
              >
                <Stack>
                  <Typography
                    variant="body2"
                    sx={{ textTransform: 'capitalize', textAlign: 'center' }}
                  >
                    {getDayName(days[i])}
                  </Typography>
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    sx={{ textTransform: 'capitalize', textAlign: 'center' }}
                  >
                    {formatInTimeZone(days[i], 'Europe/Warsaw', 'd LLL', {
                      locale: pl,
                    })}
                  </Typography>
                </Stack>
              </Stack>
            ))}
          </Stack>

          <Scrollbar sx={{ height: 210 }}>
            <Stack
              flexDirection="row"
              flex={1}
              justifyContent="space-between"
              height={210}
              position="relative"
            >
              {isDateSpanEmpty && !isLoading && (
                <Stack
                  sx={{
                    position: 'absolute',
                    justifyContent: 'center',
                    alignItems: 'center',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                  }}
                >
                  <Typography variant="body2" color="text.secondary">
                    Brak dostępnych terminów
                  </Typography>
                </Stack>
              )}
              {dateAvailabilities.map((dayAvailabilities, i) => (
                <Stack spacing={2} key={i}>
                  {!isLoading ? (
                    <Stack gap={1}>
                      {dayAvailabilities.map(({ date, available }, i) => {
                        const isSelected =
                          selectedHour &&
                          isSameDayHourAndMinute(selectedHour, date);

                        return (
                          <Button
                            key={i}
                            variant="contained"
                            onClick={() => {
                              if (available) {
                                setSelectedHour(date);
                                onSelect(date);
                              }
                            }}
                            disabled={!available || disabled}
                            size="small"
                            sx={{
                              width: '83px',
                              '@media (max-width:475px)': {
                                width: '45px',
                              },
                              bgcolor: isSelected
                                ? 'primary.main'
                                : alpha('#50CEFF', 0.16),
                              color: isSelected
                                ? 'text.primary'
                                : 'primary.dark',
                              '&:hover': {
                                color: 'text.primary',
                              },
                              '&.Mui-disabled': {
                                bgcolor: alpha('#9AA5A9', 0.08),
                                textDecoration:
                                  !available && !disabled
                                    ? 'line-through'
                                    : 'none',
                              },
                            }}
                          >
                            <span>
                              {formatInTimeZone(date, 'Europe/Warsaw', 'H:mm')}
                            </span>
                          </Button>
                        );
                      })}
                      <Button
                        key={i}
                        disabled
                        size="small"
                        sx={{
                          width: '83px',
                          '@media (max-width:475px)': {
                            width: '45px',
                          },
                        }}
                      >
                        <span></span>
                      </Button>
                    </Stack>
                  ) : (
                    <DateSkeleton count={daysLength} />
                  )}
                </Stack>
              ))}
            </Stack>
          </Scrollbar>
        </Stack>
        <IconButton
          size="small"
          onClick={() => moveWeek(daysLength)}
          disabled={isAfter(startOfWeek, maxDate) || disabled}
          sx={{
            bgcolor: alpha('#50CEFF', 0.16),
            '&:hover': { bgcolor: 'primary.dark' },
            '&.Mui-disabled': { bgcolor: 'transparent' },
          }}
        >
          <Iconify icon="ic:round-chevron-right" width={40} />
        </IconButton>
      </Stack>
      {/* <Box display="flex" justifyContent="center">
          <Button
            color="inherit"
            variant="text"
            size="medium"
            onClick={() => setShowAllHours((prev) => !prev)}
            disabled={disabled}
            endIcon={
              showAllHours ? (
                <Iconify icon="ic:round-keyboard-arrow-up" width="20" />
              ) : (
                <Iconify icon="ic:round-keyboard-arrow-down" width="20" />
              )
            }
          >
            {showAllHours ? 'Pokaż mniej godzin' : 'Pokaż więcej godzin'}
          </Button>
        </Box> */}
    </Stack>
  );
};

export default CalendarComponent;
