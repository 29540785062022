import { ReactNode, useEffect, useRef, useState } from 'react';
import { Rnd } from 'react-rnd';

import { Card, useTheme } from '@mui/material';

type RndModalProps = {
  open: boolean;
  size?: {
    width: number;
    height: number;
  };
  position?: {
    x: number;
    y: number;
  };
  onResizeStop?: ({ width, height }: { width: number; height: number }) => void;
  onDragStop?: ({ x, y }: { x: number; y: number }) => void;
  children?: ReactNode;
};

const DEFAULT_WIDTH = 350;
const DEFAULT_HEIGHT = 400;
const DEFAULT_X = 24;
const DEFAULT_Y = 24;
export const FULL_SIZE_MARGIN = 24;

export const DEFAULT_PROPS = {
  width: DEFAULT_WIDTH,
  height: DEFAULT_HEIGHT,
  x: DEFAULT_X,
  y: DEFAULT_Y,
};

export default function RndModal({
  children,
  onDragStop,
  onResizeStop,
  size,
  position,
  open,
}: RndModalProps) {
  const ref = useRef<Rnd>(null);
  const [props, setProps] = useState(DEFAULT_PROPS);

  const theme = useTheme();

  useEffect(() => {
    if ((size || position) && open) {
      const sizeToUpdate = size || {};
      const positionToUpdate = position || {};
      setProps((state) => ({
        ...state,
        ...sizeToUpdate,
        ...positionToUpdate,
      }));
    }
  }, [size, position, open]);

  useEffect(() => {
    const handleOrientationChange = () => {
      if (window.matchMedia('(orientation: portrait)').matches) {
        onDragStop && onDragStop({ x: DEFAULT_X, y: DEFAULT_Y });
      }
      if (window.matchMedia('(orientation: landscape)').matches) {
        onDragStop && onDragStop({ x: DEFAULT_X, y: DEFAULT_Y });
      }
    };
    window.addEventListener('resize', handleOrientationChange);

    return () => {
      window.removeEventListener('resize', handleOrientationChange);
    };
  }, [onDragStop]);

  return open ? (
    <Rnd
      ref={ref}
      bounds="parent"
      size={{
        width: props.width,
        height: props.height,
      }}
      position={{ x: props.x, y: props.y }}
      onDragStop={(_e, d) => {
        setProps((currentState) => ({ ...currentState, x: d.x, y: d.y }));
        onDragStop && onDragStop({ x: d.x, y: d.y });
      }}
      onResizeStop={(_e, _dir, elementRef, _delta, position) => {
        setProps({
          width: Number(elementRef.style.width.replace('px', '')),
          height: Number(elementRef.style.height.replace('px', '')),
          ...position,
        });
        onResizeStop &&
          onResizeStop({
            width: Number(elementRef.style.width.replace('px', '')),
            height: Number(elementRef.style.height.replace('px', '')),
          });
      }}
      style={{
        zIndex: theme.zIndex.modal,
        position: 'fixed',
        pointerEvents: "auto"
        
      }}
      dragHandleClassName="drag-handle"
      resizeHandleStyles={{
        top: { display: 'none' },
        topLeft: { left: 0, top: 0 },
        topRight: { right: 0, top: 0 },
        right: { display: 'none' },
        bottom: { display: 'none' },
        bottomLeft: { left: 0, bottom: 0 },
        bottomRight: { right: 0, bottom: 0 },
        left: { display: 'none' },
      }}
      minWidth={DEFAULT_WIDTH}
      minHeight={DEFAULT_HEIGHT}
      maxHeight={document.body.clientHeight - 2 * FULL_SIZE_MARGIN}
      maxWidth={document.body.clientWidth - 2 * FULL_SIZE_MARGIN}
    >
      <Card sx={{ position: 'relative', height: '100%' }}>{children}</Card>
    </Rnd>
  ) : null;
}
