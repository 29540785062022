import { Stack } from '@mui/material';
import { AccountBusiness } from 'src/sections/@dashboard/user/account';


export default function AccountTabsBusinessPage() {
  return (
    <Stack sx={{ height: 1 }}>
      <AccountBusiness />
    </Stack>
  );
}
