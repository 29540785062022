import { useState } from 'react';
import imageCompression from 'browser-image-compression';
import { toBase64 } from 'src/utils/toBase64';
import { MAX_IMAGE_SIZE } from 'src/config-global';

const useImageCompression = () => {
  const [imageLoading, setImageLoading] = useState(false);
  const [image, setImage] = useState<string | null>(null);

  const compressImage = async (acceptedFiles: File[]) => {
    setImageLoading(true);
    try {
      const options = {
        maxSizeMB: MAX_IMAGE_SIZE,
        useWebWorker: true,
      };
      const compressedFile = await imageCompression(acceptedFiles[0], options);
      const compressedBase64 = await toBase64(compressedFile);
      return compressedBase64;
    } catch (error) {
      setImageLoading(false);
      setImage(null);
    } finally {
      setImageLoading(false);
    }
  };

  return { compressImage, imageLoading };
};

export default useImageCompression;
