import { CircularProgress, Stack, useTheme } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { conversationApi } from 'src/api/conversation';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { ConversationJointDto } from 'woofwoof-api';
import { useLiveVisitContext } from '../../visit/hooks/useLiveVisitContext';
import useVideoContext from '../hooks/useVideoContext';
import Room from './Room';

export default function LiveVisitVideo() {
  const theme = useTheme();
  const [isJoinLoading, setIsJoinLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { mutateAsync: join, reset } = useMutation({
    mutationFn: (conversationJointDto: ConversationJointDto) =>
      conversationApi.conversationControllerJoin({ conversationJointDto }),
  });
  const { isLoading, data } = useLiveVisitContext();
  const { room, connect, isConnecting, error } = useVideoContext();

  useEffect(() => {
    const connectToRoom = async () => {
      try {
        setIsJoinLoading(true);
        // TODO: Fix type
        const response: any = await join({
          roomName: (data?.conversation as any)?.roomName,
        });
        connect(response?.data?.token, (data?.conversation as any)?.roomName);
        setIsJoinLoading(false);
      } catch (e) {
        setIsJoinLoading(false);
        enqueueSnackbar('Wystąpił problem z dołączeniem do rozmowy', {
          variant: 'error',
        });
        navigate(PATH_DASHBOARD.general.profiles.list);
        throw new Error(JSON.stringify(e));
      }
    };

    if (!isConnecting && !room && !error && data) {
      connectToRoom();
    }
  }, [
    data,
    connect,
    isConnecting,
    room,
    error,
    join,
    enqueueSnackbar,
    navigate,
  ]);

  useEffect(() => {
    return () => {
      reset();
    };
  }, [room, reset]);

  return (
    <Stack
      sx={{
        flex: 1,
        backgroundColor: theme.palette.text.primary,
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      {isConnecting || isLoading || isJoinLoading || !room ? (
        <CircularProgress sx={{ color: '#F4ECE5' }} />
      ) : (
        <Room />
      )}
    </Stack>
  );
}
