/* tslint:disable */
/* eslint-disable */
/**
 * WoofWoof
 * API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AbortVisitDto
 */
export interface AbortVisitDto {
    /**
     * 
     * @type {string}
     * @memberof AbortVisitDto
     */
    'visitId': string;
    /**
     * 
     * @type {string}
     * @memberof AbortVisitDto
     */
    'abortReason': AbortVisitDtoAbortReasonEnum;
    /**
     * 
     * @type {string}
     * @memberof AbortVisitDto
     */
    'abortNote': string;
}

export const AbortVisitDtoAbortReasonEnum = {
    Reschedule: 'reschedule',
    ConnectionIssue: 'connection_issue',
    IncorrectProfessional: 'incorrect_professional',
    Other: 'other'
} as const;

export type AbortVisitDtoAbortReasonEnum = typeof AbortVisitDtoAbortReasonEnum[keyof typeof AbortVisitDtoAbortReasonEnum];

/**
 * 
 * @export
 * @interface AddNotesDto
 */
export interface AddNotesDto {
    /**
     * 
     * @type {string}
     * @memberof AddNotesDto
     */
    'notes': string;
    /**
     * 
     * @type {string}
     * @memberof AddNotesDto
     */
    'internalNotes'?: string;
}
/**
 * 
 * @export
 * @interface AvailableSlotsDto
 */
export interface AvailableSlotsDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof AvailableSlotsDto
     */
    'slots': Array<string>;
}
/**
 * 
 * @export
 * @interface ConversationCancelDto
 */
export interface ConversationCancelDto {
    /**
     * 
     * @type {string}
     * @memberof ConversationCancelDto
     */
    'conversationId': string;
}
/**
 * 
 * @export
 * @interface ConversationCompleteDto
 */
export interface ConversationCompleteDto {
    /**
     * 
     * @type {string}
     * @memberof ConversationCompleteDto
     */
    'conversationId': string;
}
/**
 * 
 * @export
 * @interface ConversationJointDto
 */
export interface ConversationJointDto {
    /**
     * 
     * @type {string}
     * @memberof ConversationJointDto
     */
    'roomName': string;
}
/**
 * 
 * @export
 * @interface ConversationStartDto
 */
export interface ConversationStartDto {
    /**
     * 
     * @type {string}
     * @memberof ConversationStartDto
     */
    'conversationId': string;
}
/**
 * 
 * @export
 * @interface CreateProfessionalDto
 */
export interface CreateProfessionalDto {
    /**
     * 
     * @type {string}
     * @memberof CreateProfessionalDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProfessionalDto
     */
    'middleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProfessionalDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProfessionalDto
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProfessionalDto
     */
    'dob'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProfessionalDto
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProfessionalDto
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProfessionalDto
     */
    'companyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProfessionalDto
     */
    'companyAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProfessionalDto
     */
    'companyCity'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProfessionalDto
     */
    'companyZip'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProfessionalDto
     */
    'vatNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProfessionalDto
     */
    'pwzNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProfessionalDto
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProfessionalDto
     */
    'zip'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateProfessionalDto
     */
    'marketing'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateProfessionalDto
     */
    'terms': boolean;
}
/**
 * 
 * @export
 * @interface CreateProfileDto
 */
export interface CreateProfileDto {
    /**
     * 
     * @type {string}
     * @memberof CreateProfileDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProfileDto
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProfileDto
     */
    'background'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProfileDto
     */
    'dob': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProfileDto
     */
    'breed': string;
    /**
     * 
     * @type {number}
     * @memberof CreateProfileDto
     */
    'weight': number;
    /**
     * 
     * @type {string}
     * @memberof CreateProfileDto
     */
    'chipNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProfileDto
     */
    'gender': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProfileDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface CreateUserDto
 */
export interface CreateUserDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    'middleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    'dob'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    'zip'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUserDto
     */
    'marketing'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUserDto
     */
    'terms': boolean;
}
/**
 * 
 * @export
 * @interface CustomerDto
 */
export interface CustomerDto {
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'logoUrl': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'link': string;
}
/**
 * 
 * @export
 * @interface DocumentFileDto
 */
export interface DocumentFileDto {
    /**
     * 
     * @type {string}
     * @memberof DocumentFileDto
     */
    'filename': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentFileDto
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface DocumentFileResponseDto
 */
export interface DocumentFileResponseDto {
    /**
     * 
     * @type {string}
     * @memberof DocumentFileResponseDto
     */
    'filename': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentFileResponseDto
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentFileResponseDto
     */
    'visitId'?: string;
}
/**
 * 
 * @export
 * @interface ExternalIdDto
 */
export interface ExternalIdDto {
    /**
     * 
     * @type {string}
     * @memberof ExternalIdDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ExternalIdDto
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface GetCouponsDto
 */
export interface GetCouponsDto {
    /**
     * 
     * @type {string}
     * @memberof GetCouponsDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof GetCouponsDto
     */
    'description': string;
    /**
     * 
     * @type {CustomerDto}
     * @memberof GetCouponsDto
     */
    'customer': CustomerDto;
    /**
     * 
     * @type {string}
     * @memberof GetCouponsDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GetCouponsDto
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof GetCouponsDto
     */
    'discount'?: string;
}
/**
 * 
 * @export
 * @interface GetProfileDto
 */
export interface GetProfileDto {
    /**
     * 
     * @type {string}
     * @memberof GetProfileDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GetProfileDto
     */
    'avatar': string;
    /**
     * 
     * @type {string}
     * @memberof GetProfileDto
     */
    'background'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProfileDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GetProfileDto
     */
    'breed': string;
    /**
     * 
     * @type {string}
     * @memberof GetProfileDto
     */
    'dob': string;
    /**
     * 
     * @type {number}
     * @memberof GetProfileDto
     */
    'weight': number;
    /**
     * 
     * @type {string}
     * @memberof GetProfileDto
     */
    'chipNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProfileDto
     */
    'gender': string;
    /**
     * 
     * @type {string}
     * @memberof GetProfileDto
     */
    'description'?: string;
    /**
     * 
     * @type {object}
     * @memberof GetProfileDto
     */
    'lastVisit': object | null;
    /**
     * 
     * @type {object}
     * @memberof GetProfileDto
     */
    'futureVisit': object | null;
    /**
     * 
     * @type {string}
     * @memberof GetProfileDto
     */
    'userId': string;
    /**
     * 
     * @type {Array<object>}
     * @memberof GetProfileDto
     */
    'documents'?: Array<object>;
}
/**
 * 
 * @export
 * @interface GetUserDto
 */
export interface GetUserDto {
    /**
     * 
     * @type {string}
     * @memberof GetUserDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GetUserDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof GetUserDto
     */
    'middleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof GetUserDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof GetUserDto
     */
    'dob': string;
    /**
     * 
     * @type {string}
     * @memberof GetUserDto
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof GetUserDto
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserDto
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserDto
     */
    'companyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserDto
     */
    'companyAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserDto
     */
    'companyCity'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserDto
     */
    'companyZip'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserDto
     */
    'vatNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserDto
     */
    'pwzNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserDto
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof GetUserDto
     */
    'zip': string;
    /**
     * 
     * @type {boolean}
     * @memberof GetUserDto
     */
    'verified': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetUserDto
     */
    'onboarded': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetUserDto
     */
    'uid': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetUserDto
     */
    'roles': Array<string>;
    /**
     * 
     * @type {Array<object>}
     * @memberof GetUserDto
     */
    'availabilitySlots'?: Array<object>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetUserDto
     */
    'categories'?: Array<GetUserDtoCategoriesEnum>;
    /**
     * 
     * @type {object}
     * @memberof GetUserDto
     */
    'agreements'?: object;
}

export const GetUserDtoCategoriesEnum = {
    Veterinarian: 'veterinarian',
    Dietitian: 'dietitian',
    Behaviorist: 'behaviorist',
    Physiotherapist: 'physiotherapist'
} as const;

export type GetUserDtoCategoriesEnum = typeof GetUserDtoCategoriesEnum[keyof typeof GetUserDtoCategoriesEnum];

/**
 * 
 * @export
 * @interface GetVisitById
 */
export interface GetVisitById {
    /**
     * 
     * @type {string}
     * @memberof GetVisitById
     */
    'id': string;
    /**
     * 
     * @type {User}
     * @memberof GetVisitById
     */
    'user': User | null;
    /**
     * 
     * @type {Professional}
     * @memberof GetVisitById
     */
    'professional': Professional | null;
    /**
     * 
     * @type {Profile}
     * @memberof GetVisitById
     */
    'profile': Profile | null;
    /**
     * 
     * @type {object}
     * @memberof GetVisitById
     */
    'conversation': object | null;
    /**
     * 
     * @type {string}
     * @memberof GetVisitById
     */
    'startTime': string;
    /**
     * 
     * @type {string}
     * @memberof GetVisitById
     */
    'endTime': string;
    /**
     * 
     * @type {string}
     * @memberof GetVisitById
     */
    'category': GetVisitByIdCategoryEnum;
    /**
     * 
     * @type {Payment}
     * @memberof GetVisitById
     */
    'payment': Payment | null;
    /**
     * 
     * @type {string}
     * @memberof GetVisitById
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVisitById
     */
    'internalNotes'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetVisitById
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof GetVisitById
     */
    'status': GetVisitByIdStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof GetVisitById
     */
    'aiNote'?: string;
    /**
     * 
     * @type {Array<object>}
     * @memberof GetVisitById
     */
    'documents': Array<object>;
    /**
     * 
     * @type {PromoCode}
     * @memberof GetVisitById
     */
    'promoCode': PromoCode | null;
    /**
     * 
     * @type {string}
     * @memberof GetVisitById
     */
    'provider': GetVisitByIdProviderEnum;
}

export const GetVisitByIdCategoryEnum = {
    Veterinarian: 'veterinarian',
    Dietitian: 'dietitian',
    Behaviorist: 'behaviorist',
    Physiotherapist: 'physiotherapist'
} as const;

export type GetVisitByIdCategoryEnum = typeof GetVisitByIdCategoryEnum[keyof typeof GetVisitByIdCategoryEnum];
export const GetVisitByIdStatusEnum = {
    Created: 'CREATED',
    Pending: 'PENDING',
    Completed: 'COMPLETED',
    Cancelled: 'CANCELLED'
} as const;

export type GetVisitByIdStatusEnum = typeof GetVisitByIdStatusEnum[keyof typeof GetVisitByIdStatusEnum];
export const GetVisitByIdProviderEnum = {
    Petsy: 'petsy'
} as const;

export type GetVisitByIdProviderEnum = typeof GetVisitByIdProviderEnum[keyof typeof GetVisitByIdProviderEnum];

/**
 * 
 * @export
 * @interface GetVisitCategoriesDto
 */
export interface GetVisitCategoriesDto {
    /**
     * 
     * @type {string}
     * @memberof GetVisitCategoriesDto
     */
    'name': GetVisitCategoriesDtoNameEnum;
    /**
     * 
     * @type {number}
     * @memberof GetVisitCategoriesDto
     */
    'price': number;
}

export const GetVisitCategoriesDtoNameEnum = {
    Veterinarian: 'veterinarian',
    Dietitian: 'dietitian',
    Behaviorist: 'behaviorist',
    Physiotherapist: 'physiotherapist'
} as const;

export type GetVisitCategoriesDtoNameEnum = typeof GetVisitCategoriesDtoNameEnum[keyof typeof GetVisitCategoriesDtoNameEnum];

/**
 * 
 * @export
 * @interface OrderDto
 */
export interface OrderDto {
    /**
     * 
     * @type {number}
     * @memberof OrderDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    'returnUrl': string;
}
/**
 * 
 * @export
 * @interface Payment
 */
export interface Payment {
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'status': PaymentStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'paymentUrl': string;
}

export const PaymentStatusEnum = {
    Created: 'created',
    Verified: 'verified',
    Failed: 'failed',
    Refunded: 'refunded'
} as const;

export type PaymentStatusEnum = typeof PaymentStatusEnum[keyof typeof PaymentStatusEnum];

/**
 * 
 * @export
 * @interface PetDto
 */
export interface PetDto {
    /**
     * 
     * @type {number}
     * @memberof PetDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PetDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PetDto
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof PetDto
     */
    'gender': string;
    /**
     * 
     * @type {string}
     * @memberof PetDto
     */
    'birthDate': string;
    /**
     * 
     * @type {string}
     * @memberof PetDto
     */
    'size': string;
    /**
     * 
     * @type {string}
     * @memberof PetDto
     */
    'breed': string;
    /**
     * 
     * @type {string}
     * @memberof PetDto
     */
    'about': string;
    /**
     * 
     * @type {string}
     * @memberof PetDto
     */
    'avatarUrl'?: string;
}
/**
 * 
 * @export
 * @interface PetsitterDto
 */
export interface PetsitterDto {
    /**
     * 
     * @type {number}
     * @memberof PetsitterDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PetsitterDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof PetsitterDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof PetsitterDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof PetsitterDto
     */
    'avatarUrl': string;
}
/**
 * 
 * @export
 * @interface PreviewUserDto
 */
export interface PreviewUserDto {
    /**
     * 
     * @type {string}
     * @memberof PreviewUserDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof PreviewUserDto
     */
    'middleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PreviewUserDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof PreviewUserDto
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof PreviewUserDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof PreviewUserDto
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof PreviewUserDto
     */
    'city': string;
}
/**
 * 
 * @export
 * @interface Professional
 */
export interface Professional {
    /**
     * 
     * @type {string}
     * @memberof Professional
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Professional
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof Professional
     */
    'middleName': string;
    /**
     * 
     * @type {string}
     * @memberof Professional
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof Professional
     */
    'avatar': string;
}
/**
 * 
 * @export
 * @interface Profile
 */
export interface Profile {
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'avatar': string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'breed': string;
}
/**
 * 
 * @export
 * @interface PromoCode
 */
export interface PromoCode {
    /**
     * 
     * @type {number}
     * @memberof PromoCode
     */
    'discountPercentage': number;
    /**
     * 
     * @type {string}
     * @memberof PromoCode
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface RateVisitDto
 */
export interface RateVisitDto {
    /**
     * 
     * @type {string}
     * @memberof RateVisitDto
     */
    'review'?: string;
    /**
     * 
     * @type {number}
     * @memberof RateVisitDto
     */
    'rate': number;
}
/**
 * 
 * @export
 * @interface RegisterProfessionalDto
 */
export interface RegisterProfessionalDto {
    /**
     * 
     * @type {string}
     * @memberof RegisterProfessionalDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterProfessionalDto
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterProfessionalDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterProfessionalDto
     */
    'middleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterProfessionalDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterProfessionalDto
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterProfessionalDto
     */
    'dob'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterProfessionalDto
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterProfessionalDto
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterProfessionalDto
     */
    'companyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterProfessionalDto
     */
    'companyAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterProfessionalDto
     */
    'companyCity'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterProfessionalDto
     */
    'companyZip'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterProfessionalDto
     */
    'vatNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterProfessionalDto
     */
    'pwzNumber': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterProfessionalDto
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterProfessionalDto
     */
    'zip'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RegisterProfessionalDto
     */
    'marketing'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RegisterProfessionalDto
     */
    'terms': boolean;
}
/**
 * 
 * @export
 * @interface RegisterUserDto
 */
export interface RegisterUserDto {
    /**
     * 
     * @type {string}
     * @memberof RegisterUserDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserDto
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserDto
     */
    'middleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserDto
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserDto
     */
    'dob'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserDto
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserDto
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserDto
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserDto
     */
    'zip'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RegisterUserDto
     */
    'marketing'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RegisterUserDto
     */
    'terms': boolean;
}
/**
 * 
 * @export
 * @interface RequestVisitDto
 */
export interface RequestVisitDto {
    /**
     * 
     * @type {OrderDto}
     * @memberof RequestVisitDto
     */
    'order': OrderDto;
    /**
     * 
     * @type {PetsitterDto}
     * @memberof RequestVisitDto
     */
    'petsitter': PetsitterDto;
    /**
     * 
     * @type {Array<PetDto>}
     * @memberof RequestVisitDto
     */
    'pet': Array<PetDto>;
}
/**
 * 
 * @export
 * @interface RescheduleVisitDto
 */
export interface RescheduleVisitDto {
    /**
     * 
     * @type {string}
     * @memberof RescheduleVisitDto
     */
    'visitId': string;
    /**
     * 
     * @type {string}
     * @memberof RescheduleVisitDto
     */
    'newStartTime': string;
}
/**
 * 
 * @export
 * @interface ScheduleVisitDto
 */
export interface ScheduleVisitDto {
    /**
     * 
     * @type {string}
     * @memberof ScheduleVisitDto
     */
    'startTime': string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleVisitDto
     */
    'profileId': string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleVisitDto
     */
    'profileName': string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleVisitDto
     */
    'category': ScheduleVisitDtoCategoryEnum;
    /**
     * 
     * @type {string}
     * @memberof ScheduleVisitDto
     */
    'description': string;
    /**
     * 
     * @type {Array<object>}
     * @memberof ScheduleVisitDto
     */
    'documents'?: Array<object>;
    /**
     * 
     * @type {string}
     * @memberof ScheduleVisitDto
     */
    'promoCode'?: string;
    /**
     * 
     * @type {ExternalIdDto}
     * @memberof ScheduleVisitDto
     */
    'externalId'?: ExternalIdDto;
}

export const ScheduleVisitDtoCategoryEnum = {
    Veterinarian: 'veterinarian',
    Dietitian: 'dietitian',
    Behaviorist: 'behaviorist',
    Physiotherapist: 'physiotherapist'
} as const;

export type ScheduleVisitDtoCategoryEnum = typeof ScheduleVisitDtoCategoryEnum[keyof typeof ScheduleVisitDtoCategoryEnum];

/**
 * 
 * @export
 * @interface ScheduleVisitResponseDto
 */
export interface ScheduleVisitResponseDto {
    /**
     * 
     * @type {string}
     * @memberof ScheduleVisitResponseDto
     */
    'redirectUrl': string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleVisitResponseDto
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof ScheduleVisitResponseDto
     */
    'amount': number;
}
/**
 * 
 * @export
 * @interface UpdateProfileDto
 */
export interface UpdateProfileDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateProfileDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProfileDto
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProfileDto
     */
    'background'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProfileDto
     */
    'dob'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProfileDto
     */
    'breed'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateProfileDto
     */
    'weight'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateProfileDto
     */
    'chipNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProfileDto
     */
    'gender'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProfileDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface UpdateUserDto
 */
export interface UpdateUserDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'middleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'dob'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'companyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'vatNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'pwzNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'companyAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'companyCity'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'companyZip'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'zip'?: string;
    /**
     * 
     * @type {Array<object>}
     * @memberof UpdateUserDto
     */
    'availabilitySlots'?: Array<object>;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserDto
     */
    'marketing'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserDto
     */
    'terms'?: boolean;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'middleName': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'avatar': string;
}
/**
 * 
 * @export
 * @interface ValidatePromoCodeDto
 */
export interface ValidatePromoCodeDto {
    /**
     * 
     * @type {string}
     * @memberof ValidatePromoCodeDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof ValidatePromoCodeDto
     */
    'category': ValidatePromoCodeDtoCategoryEnum;
}

export const ValidatePromoCodeDtoCategoryEnum = {
    Veterinarian: 'veterinarian',
    Dietitian: 'dietitian',
    Behaviorist: 'behaviorist',
    Physiotherapist: 'physiotherapist'
} as const;

export type ValidatePromoCodeDtoCategoryEnum = typeof ValidatePromoCodeDtoCategoryEnum[keyof typeof ValidatePromoCodeDtoCategoryEnum];

/**
 * 
 * @export
 * @interface ValidatePromoCodeResponseDto
 */
export interface ValidatePromoCodeResponseDto {
    /**
     * 
     * @type {number}
     * @memberof ValidatePromoCodeResponseDto
     */
    'discountPercentage': number;
    /**
     * 
     * @type {number}
     * @memberof ValidatePromoCodeResponseDto
     */
    'discountedPrice'?: number;
}
/**
 * 
 * @export
 * @interface VisitStats
 */
export interface VisitStats {
    /**
     * 
     * @type {VisitStatsDetail}
     * @memberof VisitStats
     */
    'COMPLETED'?: VisitStatsDetail;
    /**
     * 
     * @type {VisitStatsDetail}
     * @memberof VisitStats
     */
    'CREATED'?: VisitStatsDetail;
    /**
     * 
     * @type {VisitStatsDetail}
     * @memberof VisitStats
     */
    'PENDING'?: VisitStatsDetail;
}
/**
 * 
 * @export
 * @interface VisitStatsDetail
 */
export interface VisitStatsDetail {
    /**
     * 
     * @type {number}
     * @memberof VisitStatsDetail
     */
    'totalSaleAmount': number;
    /**
     * 
     * @type {number}
     * @memberof VisitStatsDetail
     */
    'count': number;
}

/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Send verification email upon user registration with email/pass
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerVerifyUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/verify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Send verification email upon user registration with email/pass
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerVerifyUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerVerifyUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @summary Send verification email upon user registration with email/pass
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerVerifyUser(options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.authControllerVerifyUser(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @summary Send verification email upon user registration with email/pass
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerVerifyUser(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerVerifyUser(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AutopayPublicApi - axios parameter creator
 * @export
 */
export const AutopayPublicApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autopayPublicControllerHandleITN: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/autopay/public/payment/verify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AutopayPublicApi - functional programming interface
 * @export
 */
export const AutopayPublicApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AutopayPublicApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async autopayPublicControllerHandleITN(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.autopayPublicControllerHandleITN(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AutopayPublicApi - factory interface
 * @export
 */
export const AutopayPublicApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AutopayPublicApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autopayPublicControllerHandleITN(options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.autopayPublicControllerHandleITN(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AutopayPublicApi - object-oriented interface
 * @export
 * @class AutopayPublicApi
 * @extends {BaseAPI}
 */
export class AutopayPublicApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutopayPublicApi
     */
    public autopayPublicControllerHandleITN(options?: AxiosRequestConfig) {
        return AutopayPublicApiFp(this.configuration).autopayPublicControllerHandleITN(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConversationApi - axios parameter creator
 * @export
 */
export const ConversationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancel a pending conversation. Professional only.
         * @param {ConversationCancelDto} conversationCancelDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationControllerCancel: async (conversationCancelDto: ConversationCancelDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'conversationCancelDto' is not null or undefined
            assertParamExists('conversationControllerCancel', 'conversationCancelDto', conversationCancelDto)
            const localVarPath = `/api/conversation/cancel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(conversationCancelDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Complete a pending conversation. Professional only.
         * @param {ConversationCompleteDto} conversationCompleteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationControllerEnd: async (conversationCompleteDto: ConversationCompleteDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'conversationCompleteDto' is not null or undefined
            assertParamExists('conversationControllerEnd', 'conversationCompleteDto', conversationCompleteDto)
            const localVarPath = `/api/conversation/complete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(conversationCompleteDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all conversations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationControllerGetAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/conversation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get conversation by id
         * @param {string} conversationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationControllerGetConversationById: async (conversationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('conversationControllerGetConversationById', 'conversationId', conversationId)
            const localVarPath = `/api/conversation/{conversationId}`
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Join started conversation
         * @param {ConversationJointDto} conversationJointDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationControllerJoin: async (conversationJointDto: ConversationJointDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'conversationJointDto' is not null or undefined
            assertParamExists('conversationControllerJoin', 'conversationJointDto', conversationJointDto)
            const localVarPath = `/api/conversation/join`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(conversationJointDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Start created conversation. Professional only.
         * @param {ConversationStartDto} conversationStartDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationControllerStart: async (conversationStartDto: ConversationStartDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'conversationStartDto' is not null or undefined
            assertParamExists('conversationControllerStart', 'conversationStartDto', conversationStartDto)
            const localVarPath = `/api/conversation/start`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(conversationStartDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConversationApi - functional programming interface
 * @export
 */
export const ConversationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConversationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Cancel a pending conversation. Professional only.
         * @param {ConversationCancelDto} conversationCancelDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async conversationControllerCancel(conversationCancelDto: ConversationCancelDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.conversationControllerCancel(conversationCancelDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Complete a pending conversation. Professional only.
         * @param {ConversationCompleteDto} conversationCompleteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async conversationControllerEnd(conversationCompleteDto: ConversationCompleteDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.conversationControllerEnd(conversationCompleteDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all conversations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async conversationControllerGetAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.conversationControllerGetAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get conversation by id
         * @param {string} conversationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async conversationControllerGetConversationById(conversationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.conversationControllerGetConversationById(conversationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Join started conversation
         * @param {ConversationJointDto} conversationJointDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async conversationControllerJoin(conversationJointDto: ConversationJointDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.conversationControllerJoin(conversationJointDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Start created conversation. Professional only.
         * @param {ConversationStartDto} conversationStartDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async conversationControllerStart(conversationStartDto: ConversationStartDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.conversationControllerStart(conversationStartDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConversationApi - factory interface
 * @export
 */
export const ConversationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConversationApiFp(configuration)
    return {
        /**
         * 
         * @summary Cancel a pending conversation. Professional only.
         * @param {ConversationApiConversationControllerCancelRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationControllerCancel(requestParameters: ConversationApiConversationControllerCancelRequest, options?: AxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.conversationControllerCancel(requestParameters.conversationCancelDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Complete a pending conversation. Professional only.
         * @param {ConversationApiConversationControllerEndRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationControllerEnd(requestParameters: ConversationApiConversationControllerEndRequest, options?: AxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.conversationControllerEnd(requestParameters.conversationCompleteDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all conversations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationControllerGetAll(options?: AxiosRequestConfig): AxiosPromise<Array<object>> {
            return localVarFp.conversationControllerGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get conversation by id
         * @param {ConversationApiConversationControllerGetConversationByIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationControllerGetConversationById(requestParameters: ConversationApiConversationControllerGetConversationByIdRequest, options?: AxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.conversationControllerGetConversationById(requestParameters.conversationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Join started conversation
         * @param {ConversationApiConversationControllerJoinRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationControllerJoin(requestParameters: ConversationApiConversationControllerJoinRequest, options?: AxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.conversationControllerJoin(requestParameters.conversationJointDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Start created conversation. Professional only.
         * @param {ConversationApiConversationControllerStartRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationControllerStart(requestParameters: ConversationApiConversationControllerStartRequest, options?: AxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.conversationControllerStart(requestParameters.conversationStartDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for conversationControllerCancel operation in ConversationApi.
 * @export
 * @interface ConversationApiConversationControllerCancelRequest
 */
export interface ConversationApiConversationControllerCancelRequest {
    /**
     * 
     * @type {ConversationCancelDto}
     * @memberof ConversationApiConversationControllerCancel
     */
    readonly conversationCancelDto: ConversationCancelDto
}

/**
 * Request parameters for conversationControllerEnd operation in ConversationApi.
 * @export
 * @interface ConversationApiConversationControllerEndRequest
 */
export interface ConversationApiConversationControllerEndRequest {
    /**
     * 
     * @type {ConversationCompleteDto}
     * @memberof ConversationApiConversationControllerEnd
     */
    readonly conversationCompleteDto: ConversationCompleteDto
}

/**
 * Request parameters for conversationControllerGetConversationById operation in ConversationApi.
 * @export
 * @interface ConversationApiConversationControllerGetConversationByIdRequest
 */
export interface ConversationApiConversationControllerGetConversationByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof ConversationApiConversationControllerGetConversationById
     */
    readonly conversationId: string
}

/**
 * Request parameters for conversationControllerJoin operation in ConversationApi.
 * @export
 * @interface ConversationApiConversationControllerJoinRequest
 */
export interface ConversationApiConversationControllerJoinRequest {
    /**
     * 
     * @type {ConversationJointDto}
     * @memberof ConversationApiConversationControllerJoin
     */
    readonly conversationJointDto: ConversationJointDto
}

/**
 * Request parameters for conversationControllerStart operation in ConversationApi.
 * @export
 * @interface ConversationApiConversationControllerStartRequest
 */
export interface ConversationApiConversationControllerStartRequest {
    /**
     * 
     * @type {ConversationStartDto}
     * @memberof ConversationApiConversationControllerStart
     */
    readonly conversationStartDto: ConversationStartDto
}

/**
 * ConversationApi - object-oriented interface
 * @export
 * @class ConversationApi
 * @extends {BaseAPI}
 */
export class ConversationApi extends BaseAPI {
    /**
     * 
     * @summary Cancel a pending conversation. Professional only.
     * @param {ConversationApiConversationControllerCancelRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationApi
     */
    public conversationControllerCancel(requestParameters: ConversationApiConversationControllerCancelRequest, options?: AxiosRequestConfig) {
        return ConversationApiFp(this.configuration).conversationControllerCancel(requestParameters.conversationCancelDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Complete a pending conversation. Professional only.
     * @param {ConversationApiConversationControllerEndRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationApi
     */
    public conversationControllerEnd(requestParameters: ConversationApiConversationControllerEndRequest, options?: AxiosRequestConfig) {
        return ConversationApiFp(this.configuration).conversationControllerEnd(requestParameters.conversationCompleteDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all conversations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationApi
     */
    public conversationControllerGetAll(options?: AxiosRequestConfig) {
        return ConversationApiFp(this.configuration).conversationControllerGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get conversation by id
     * @param {ConversationApiConversationControllerGetConversationByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationApi
     */
    public conversationControllerGetConversationById(requestParameters: ConversationApiConversationControllerGetConversationByIdRequest, options?: AxiosRequestConfig) {
        return ConversationApiFp(this.configuration).conversationControllerGetConversationById(requestParameters.conversationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Join started conversation
     * @param {ConversationApiConversationControllerJoinRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationApi
     */
    public conversationControllerJoin(requestParameters: ConversationApiConversationControllerJoinRequest, options?: AxiosRequestConfig) {
        return ConversationApiFp(this.configuration).conversationControllerJoin(requestParameters.conversationJointDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Start created conversation. Professional only.
     * @param {ConversationApiConversationControllerStartRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationApi
     */
    public conversationControllerStart(requestParameters: ConversationApiConversationControllerStartRequest, options?: AxiosRequestConfig) {
        return ConversationApiFp(this.configuration).conversationControllerStart(requestParameters.conversationStartDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CouponsApi - axios parameter creator
 * @export
 */
export const CouponsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all valid coupons
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        couponsControllerGetAllCoupons: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/coupons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CouponsApi - functional programming interface
 * @export
 */
export const CouponsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CouponsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all valid coupons
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async couponsControllerGetAllCoupons(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetCouponsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.couponsControllerGetAllCoupons(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CouponsApi - factory interface
 * @export
 */
export const CouponsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CouponsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all valid coupons
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        couponsControllerGetAllCoupons(options?: AxiosRequestConfig): AxiosPromise<Array<GetCouponsDto>> {
            return localVarFp.couponsControllerGetAllCoupons(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CouponsApi - object-oriented interface
 * @export
 * @class CouponsApi
 * @extends {BaseAPI}
 */
export class CouponsApi extends BaseAPI {
    /**
     * 
     * @summary Get all valid coupons
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponsApi
     */
    public couponsControllerGetAllCoupons(options?: AxiosRequestConfig) {
        return CouponsApiFp(this.configuration).couponsControllerGetAllCoupons(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PaymentCronApi - axios parameter creator
 * @export
 */
export const PaymentCronApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Run Payment Cron Job
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentCronControllerRun: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/payment/cron`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentCronApi - functional programming interface
 * @export
 */
export const PaymentCronApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentCronApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Run Payment Cron Job
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentCronControllerRun(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentCronControllerRun(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PaymentCronApi - factory interface
 * @export
 */
export const PaymentCronApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentCronApiFp(configuration)
    return {
        /**
         * 
         * @summary Run Payment Cron Job
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentCronControllerRun(options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.paymentCronControllerRun(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentCronApi - object-oriented interface
 * @export
 * @class PaymentCronApi
 * @extends {BaseAPI}
 */
export class PaymentCronApi extends BaseAPI {
    /**
     * 
     * @summary Run Payment Cron Job
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentCronApi
     */
    public paymentCronControllerRun(options?: AxiosRequestConfig) {
        return PaymentCronApiFp(this.configuration).paymentCronControllerRun(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PetsyApi - axios parameter creator
 * @export
 */
export const PetsyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary [Petsy] Request new visit
         * @param {string} xApiKey api key
         * @param {RequestVisitDto} requestVisitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        petsyControllerRequestNewVisit: async (xApiKey: string, requestVisitDto: RequestVisitDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xApiKey' is not null or undefined
            assertParamExists('petsyControllerRequestNewVisit', 'xApiKey', xApiKey)
            // verify required parameter 'requestVisitDto' is not null or undefined
            assertParamExists('petsyControllerRequestNewVisit', 'requestVisitDto', requestVisitDto)
            const localVarPath = `/petsy/request-new-visit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xApiKey != null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestVisitDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PetsyApi - functional programming interface
 * @export
 */
export const PetsyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PetsyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary [Petsy] Request new visit
         * @param {string} xApiKey api key
         * @param {RequestVisitDto} requestVisitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async petsyControllerRequestNewVisit(xApiKey: string, requestVisitDto: RequestVisitDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.petsyControllerRequestNewVisit(xApiKey, requestVisitDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PetsyApi - factory interface
 * @export
 */
export const PetsyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PetsyApiFp(configuration)
    return {
        /**
         * 
         * @summary [Petsy] Request new visit
         * @param {PetsyApiPetsyControllerRequestNewVisitRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        petsyControllerRequestNewVisit(requestParameters: PetsyApiPetsyControllerRequestNewVisitRequest, options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.petsyControllerRequestNewVisit(requestParameters.xApiKey, requestParameters.requestVisitDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for petsyControllerRequestNewVisit operation in PetsyApi.
 * @export
 * @interface PetsyApiPetsyControllerRequestNewVisitRequest
 */
export interface PetsyApiPetsyControllerRequestNewVisitRequest {
    /**
     * api key
     * @type {string}
     * @memberof PetsyApiPetsyControllerRequestNewVisit
     */
    readonly xApiKey: string

    /**
     * 
     * @type {RequestVisitDto}
     * @memberof PetsyApiPetsyControllerRequestNewVisit
     */
    readonly requestVisitDto: RequestVisitDto
}

/**
 * PetsyApi - object-oriented interface
 * @export
 * @class PetsyApi
 * @extends {BaseAPI}
 */
export class PetsyApi extends BaseAPI {
    /**
     * 
     * @summary [Petsy] Request new visit
     * @param {PetsyApiPetsyControllerRequestNewVisitRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PetsyApi
     */
    public petsyControllerRequestNewVisit(requestParameters: PetsyApiPetsyControllerRequestNewVisitRequest, options?: AxiosRequestConfig) {
        return PetsyApiFp(this.configuration).petsyControllerRequestNewVisit(requestParameters.xApiKey, requestParameters.requestVisitDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProfileApi - axios parameter creator
 * @export
 */
export const ProfileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add documents to user profile
         * @param {string} profileId 
         * @param {Array<DocumentFileDto>} documentFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileControllerAddProfileDocuments: async (profileId: string, documentFileDto: Array<DocumentFileDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'profileId' is not null or undefined
            assertParamExists('profileControllerAddProfileDocuments', 'profileId', profileId)
            // verify required parameter 'documentFileDto' is not null or undefined
            assertParamExists('profileControllerAddProfileDocuments', 'documentFileDto', documentFileDto)
            const localVarPath = `/api/profile/{profileId}/documents`
                .replace(`{${"profileId"}}`, encodeURIComponent(String(profileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentFileDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new profile
         * @param {CreateProfileDto} createProfileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileControllerCreateProfile: async (createProfileDto: CreateProfileDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createProfileDto' is not null or undefined
            assertParamExists('profileControllerCreateProfile', 'createProfileDto', createProfileDto)
            const localVarPath = `/api/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProfileDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete (inactivate) logged in user profile by id
         * @param {string} profileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileControllerDeleteUser: async (profileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'profileId' is not null or undefined
            assertParamExists('profileControllerDeleteUser', 'profileId', profileId)
            const localVarPath = `/api/profile/{profileId}`
                .replace(`{${"profileId"}}`, encodeURIComponent(String(profileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all user profiles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileControllerGetAllUserProfiles: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user profile documents
         * @param {string} profileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileControllerGetProfileDocuments: async (profileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'profileId' is not null or undefined
            assertParamExists('profileControllerGetProfileDocuments', 'profileId', profileId)
            const localVarPath = `/api/profile/{profileId}/documents`
                .replace(`{${"profileId"}}`, encodeURIComponent(String(profileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user profile documents. Professional only.
         * @param {string} profileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileControllerGetProfileDocumentsPreview: async (profileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'profileId' is not null or undefined
            assertParamExists('profileControllerGetProfileDocumentsPreview', 'profileId', profileId)
            const localVarPath = `/api/profile/{profileId}/documents/preview`
                .replace(`{${"profileId"}}`, encodeURIComponent(String(profileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get profile by id
         * @param {string} profileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileControllerGetUserProfile: async (profileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'profileId' is not null or undefined
            assertParamExists('profileControllerGetUserProfile', 'profileId', profileId)
            const localVarPath = `/api/profile/{profileId}`
                .replace(`{${"profileId"}}`, encodeURIComponent(String(profileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Preview profile by id. Available for professional only.
         * @param {string} profileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileControllerPreviewProfileId: async (profileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'profileId' is not null or undefined
            assertParamExists('profileControllerPreviewProfileId', 'profileId', profileId)
            const localVarPath = `/api/profile/{profileId}/preview`
                .replace(`{${"profileId"}}`, encodeURIComponent(String(profileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update logged in user profile by id
         * @param {string} profileId 
         * @param {UpdateProfileDto} updateProfileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileControllerUpdateUser: async (profileId: string, updateProfileDto: UpdateProfileDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'profileId' is not null or undefined
            assertParamExists('profileControllerUpdateUser', 'profileId', profileId)
            // verify required parameter 'updateProfileDto' is not null or undefined
            assertParamExists('profileControllerUpdateUser', 'updateProfileDto', updateProfileDto)
            const localVarPath = `/api/profile/{profileId}`
                .replace(`{${"profileId"}}`, encodeURIComponent(String(profileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProfileDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProfileApi - functional programming interface
 * @export
 */
export const ProfileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProfileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add documents to user profile
         * @param {string} profileId 
         * @param {Array<DocumentFileDto>} documentFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileControllerAddProfileDocuments(profileId: string, documentFileDto: Array<DocumentFileDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DocumentFileResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileControllerAddProfileDocuments(profileId, documentFileDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new profile
         * @param {CreateProfileDto} createProfileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileControllerCreateProfile(createProfileDto: CreateProfileDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProfileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileControllerCreateProfile(createProfileDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete (inactivate) logged in user profile by id
         * @param {string} profileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileControllerDeleteUser(profileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProfileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileControllerDeleteUser(profileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all user profiles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileControllerGetAllUserProfiles(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetProfileDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileControllerGetAllUserProfiles(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user profile documents
         * @param {string} profileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileControllerGetProfileDocuments(profileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DocumentFileResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileControllerGetProfileDocuments(profileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user profile documents. Professional only.
         * @param {string} profileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileControllerGetProfileDocumentsPreview(profileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DocumentFileResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileControllerGetProfileDocumentsPreview(profileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get profile by id
         * @param {string} profileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileControllerGetUserProfile(profileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProfileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileControllerGetUserProfile(profileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Preview profile by id. Available for professional only.
         * @param {string} profileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileControllerPreviewProfileId(profileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProfileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileControllerPreviewProfileId(profileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update logged in user profile by id
         * @param {string} profileId 
         * @param {UpdateProfileDto} updateProfileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileControllerUpdateUser(profileId: string, updateProfileDto: UpdateProfileDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProfileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileControllerUpdateUser(profileId, updateProfileDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProfileApi - factory interface
 * @export
 */
export const ProfileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProfileApiFp(configuration)
    return {
        /**
         * 
         * @summary Add documents to user profile
         * @param {ProfileApiProfileControllerAddProfileDocumentsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileControllerAddProfileDocuments(requestParameters: ProfileApiProfileControllerAddProfileDocumentsRequest, options?: AxiosRequestConfig): AxiosPromise<Array<DocumentFileResponseDto>> {
            return localVarFp.profileControllerAddProfileDocuments(requestParameters.profileId, requestParameters.documentFileDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new profile
         * @param {ProfileApiProfileControllerCreateProfileRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileControllerCreateProfile(requestParameters: ProfileApiProfileControllerCreateProfileRequest, options?: AxiosRequestConfig): AxiosPromise<GetProfileDto> {
            return localVarFp.profileControllerCreateProfile(requestParameters.createProfileDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete (inactivate) logged in user profile by id
         * @param {ProfileApiProfileControllerDeleteUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileControllerDeleteUser(requestParameters: ProfileApiProfileControllerDeleteUserRequest, options?: AxiosRequestConfig): AxiosPromise<GetProfileDto> {
            return localVarFp.profileControllerDeleteUser(requestParameters.profileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all user profiles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileControllerGetAllUserProfiles(options?: AxiosRequestConfig): AxiosPromise<Array<GetProfileDto>> {
            return localVarFp.profileControllerGetAllUserProfiles(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user profile documents
         * @param {ProfileApiProfileControllerGetProfileDocumentsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileControllerGetProfileDocuments(requestParameters: ProfileApiProfileControllerGetProfileDocumentsRequest, options?: AxiosRequestConfig): AxiosPromise<Array<DocumentFileResponseDto>> {
            return localVarFp.profileControllerGetProfileDocuments(requestParameters.profileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user profile documents. Professional only.
         * @param {ProfileApiProfileControllerGetProfileDocumentsPreviewRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileControllerGetProfileDocumentsPreview(requestParameters: ProfileApiProfileControllerGetProfileDocumentsPreviewRequest, options?: AxiosRequestConfig): AxiosPromise<Array<DocumentFileResponseDto>> {
            return localVarFp.profileControllerGetProfileDocumentsPreview(requestParameters.profileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get profile by id
         * @param {ProfileApiProfileControllerGetUserProfileRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileControllerGetUserProfile(requestParameters: ProfileApiProfileControllerGetUserProfileRequest, options?: AxiosRequestConfig): AxiosPromise<GetProfileDto> {
            return localVarFp.profileControllerGetUserProfile(requestParameters.profileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Preview profile by id. Available for professional only.
         * @param {ProfileApiProfileControllerPreviewProfileIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileControllerPreviewProfileId(requestParameters: ProfileApiProfileControllerPreviewProfileIdRequest, options?: AxiosRequestConfig): AxiosPromise<GetProfileDto> {
            return localVarFp.profileControllerPreviewProfileId(requestParameters.profileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update logged in user profile by id
         * @param {ProfileApiProfileControllerUpdateUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileControllerUpdateUser(requestParameters: ProfileApiProfileControllerUpdateUserRequest, options?: AxiosRequestConfig): AxiosPromise<GetProfileDto> {
            return localVarFp.profileControllerUpdateUser(requestParameters.profileId, requestParameters.updateProfileDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for profileControllerAddProfileDocuments operation in ProfileApi.
 * @export
 * @interface ProfileApiProfileControllerAddProfileDocumentsRequest
 */
export interface ProfileApiProfileControllerAddProfileDocumentsRequest {
    /**
     * 
     * @type {string}
     * @memberof ProfileApiProfileControllerAddProfileDocuments
     */
    readonly profileId: string

    /**
     * 
     * @type {Array<DocumentFileDto>}
     * @memberof ProfileApiProfileControllerAddProfileDocuments
     */
    readonly documentFileDto: Array<DocumentFileDto>
}

/**
 * Request parameters for profileControllerCreateProfile operation in ProfileApi.
 * @export
 * @interface ProfileApiProfileControllerCreateProfileRequest
 */
export interface ProfileApiProfileControllerCreateProfileRequest {
    /**
     * 
     * @type {CreateProfileDto}
     * @memberof ProfileApiProfileControllerCreateProfile
     */
    readonly createProfileDto: CreateProfileDto
}

/**
 * Request parameters for profileControllerDeleteUser operation in ProfileApi.
 * @export
 * @interface ProfileApiProfileControllerDeleteUserRequest
 */
export interface ProfileApiProfileControllerDeleteUserRequest {
    /**
     * 
     * @type {string}
     * @memberof ProfileApiProfileControllerDeleteUser
     */
    readonly profileId: string
}

/**
 * Request parameters for profileControllerGetProfileDocuments operation in ProfileApi.
 * @export
 * @interface ProfileApiProfileControllerGetProfileDocumentsRequest
 */
export interface ProfileApiProfileControllerGetProfileDocumentsRequest {
    /**
     * 
     * @type {string}
     * @memberof ProfileApiProfileControllerGetProfileDocuments
     */
    readonly profileId: string
}

/**
 * Request parameters for profileControllerGetProfileDocumentsPreview operation in ProfileApi.
 * @export
 * @interface ProfileApiProfileControllerGetProfileDocumentsPreviewRequest
 */
export interface ProfileApiProfileControllerGetProfileDocumentsPreviewRequest {
    /**
     * 
     * @type {string}
     * @memberof ProfileApiProfileControllerGetProfileDocumentsPreview
     */
    readonly profileId: string
}

/**
 * Request parameters for profileControllerGetUserProfile operation in ProfileApi.
 * @export
 * @interface ProfileApiProfileControllerGetUserProfileRequest
 */
export interface ProfileApiProfileControllerGetUserProfileRequest {
    /**
     * 
     * @type {string}
     * @memberof ProfileApiProfileControllerGetUserProfile
     */
    readonly profileId: string
}

/**
 * Request parameters for profileControllerPreviewProfileId operation in ProfileApi.
 * @export
 * @interface ProfileApiProfileControllerPreviewProfileIdRequest
 */
export interface ProfileApiProfileControllerPreviewProfileIdRequest {
    /**
     * 
     * @type {string}
     * @memberof ProfileApiProfileControllerPreviewProfileId
     */
    readonly profileId: string
}

/**
 * Request parameters for profileControllerUpdateUser operation in ProfileApi.
 * @export
 * @interface ProfileApiProfileControllerUpdateUserRequest
 */
export interface ProfileApiProfileControllerUpdateUserRequest {
    /**
     * 
     * @type {string}
     * @memberof ProfileApiProfileControllerUpdateUser
     */
    readonly profileId: string

    /**
     * 
     * @type {UpdateProfileDto}
     * @memberof ProfileApiProfileControllerUpdateUser
     */
    readonly updateProfileDto: UpdateProfileDto
}

/**
 * ProfileApi - object-oriented interface
 * @export
 * @class ProfileApi
 * @extends {BaseAPI}
 */
export class ProfileApi extends BaseAPI {
    /**
     * 
     * @summary Add documents to user profile
     * @param {ProfileApiProfileControllerAddProfileDocumentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public profileControllerAddProfileDocuments(requestParameters: ProfileApiProfileControllerAddProfileDocumentsRequest, options?: AxiosRequestConfig) {
        return ProfileApiFp(this.configuration).profileControllerAddProfileDocuments(requestParameters.profileId, requestParameters.documentFileDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new profile
     * @param {ProfileApiProfileControllerCreateProfileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public profileControllerCreateProfile(requestParameters: ProfileApiProfileControllerCreateProfileRequest, options?: AxiosRequestConfig) {
        return ProfileApiFp(this.configuration).profileControllerCreateProfile(requestParameters.createProfileDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete (inactivate) logged in user profile by id
     * @param {ProfileApiProfileControllerDeleteUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public profileControllerDeleteUser(requestParameters: ProfileApiProfileControllerDeleteUserRequest, options?: AxiosRequestConfig) {
        return ProfileApiFp(this.configuration).profileControllerDeleteUser(requestParameters.profileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all user profiles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public profileControllerGetAllUserProfiles(options?: AxiosRequestConfig) {
        return ProfileApiFp(this.configuration).profileControllerGetAllUserProfiles(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user profile documents
     * @param {ProfileApiProfileControllerGetProfileDocumentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public profileControllerGetProfileDocuments(requestParameters: ProfileApiProfileControllerGetProfileDocumentsRequest, options?: AxiosRequestConfig) {
        return ProfileApiFp(this.configuration).profileControllerGetProfileDocuments(requestParameters.profileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user profile documents. Professional only.
     * @param {ProfileApiProfileControllerGetProfileDocumentsPreviewRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public profileControllerGetProfileDocumentsPreview(requestParameters: ProfileApiProfileControllerGetProfileDocumentsPreviewRequest, options?: AxiosRequestConfig) {
        return ProfileApiFp(this.configuration).profileControllerGetProfileDocumentsPreview(requestParameters.profileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get profile by id
     * @param {ProfileApiProfileControllerGetUserProfileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public profileControllerGetUserProfile(requestParameters: ProfileApiProfileControllerGetUserProfileRequest, options?: AxiosRequestConfig) {
        return ProfileApiFp(this.configuration).profileControllerGetUserProfile(requestParameters.profileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Preview profile by id. Available for professional only.
     * @param {ProfileApiProfileControllerPreviewProfileIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public profileControllerPreviewProfileId(requestParameters: ProfileApiProfileControllerPreviewProfileIdRequest, options?: AxiosRequestConfig) {
        return ProfileApiFp(this.configuration).profileControllerPreviewProfileId(requestParameters.profileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update logged in user profile by id
     * @param {ProfileApiProfileControllerUpdateUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public profileControllerUpdateUser(requestParameters: ProfileApiProfileControllerUpdateUserRequest, options?: AxiosRequestConfig) {
        return ProfileApiFp(this.configuration).profileControllerUpdateUser(requestParameters.profileId, requestParameters.updateProfileDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PromoCodeApi - axios parameter creator
 * @export
 */
export const PromoCodeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Validate promo code
         * @param {ValidatePromoCodeDto} validatePromoCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promoCodeControllerVerifyUser: async (validatePromoCodeDto: ValidatePromoCodeDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'validatePromoCodeDto' is not null or undefined
            assertParamExists('promoCodeControllerVerifyUser', 'validatePromoCodeDto', validatePromoCodeDto)
            const localVarPath = `/api/promocode/verify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(validatePromoCodeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PromoCodeApi - functional programming interface
 * @export
 */
export const PromoCodeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PromoCodeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Validate promo code
         * @param {ValidatePromoCodeDto} validatePromoCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promoCodeControllerVerifyUser(validatePromoCodeDto: ValidatePromoCodeDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValidatePromoCodeResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promoCodeControllerVerifyUser(validatePromoCodeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PromoCodeApi - factory interface
 * @export
 */
export const PromoCodeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PromoCodeApiFp(configuration)
    return {
        /**
         * 
         * @summary Validate promo code
         * @param {PromoCodeApiPromoCodeControllerVerifyUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promoCodeControllerVerifyUser(requestParameters: PromoCodeApiPromoCodeControllerVerifyUserRequest, options?: AxiosRequestConfig): AxiosPromise<ValidatePromoCodeResponseDto> {
            return localVarFp.promoCodeControllerVerifyUser(requestParameters.validatePromoCodeDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for promoCodeControllerVerifyUser operation in PromoCodeApi.
 * @export
 * @interface PromoCodeApiPromoCodeControllerVerifyUserRequest
 */
export interface PromoCodeApiPromoCodeControllerVerifyUserRequest {
    /**
     * 
     * @type {ValidatePromoCodeDto}
     * @memberof PromoCodeApiPromoCodeControllerVerifyUser
     */
    readonly validatePromoCodeDto: ValidatePromoCodeDto
}

/**
 * PromoCodeApi - object-oriented interface
 * @export
 * @class PromoCodeApi
 * @extends {BaseAPI}
 */
export class PromoCodeApi extends BaseAPI {
    /**
     * 
     * @summary Validate promo code
     * @param {PromoCodeApiPromoCodeControllerVerifyUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromoCodeApi
     */
    public promoCodeControllerVerifyUser(requestParameters: PromoCodeApiPromoCodeControllerVerifyUserRequest, options?: AxiosRequestConfig) {
        return PromoCodeApiFp(this.configuration).promoCodeControllerVerifyUser(requestParameters.validatePromoCodeDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * Przelewy24Api - axios parameter creator
 * @export
 */
export const Przelewy24ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        przelewy24ControllerGetAllUserTransactions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/przelewy24`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        przelewy24PublicControllerRefundTransaction: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/przelewy24/public/payment/refund`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        przelewy24PublicControllerVerifyTransaction: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/przelewy24/public/payment/verify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * Przelewy24Api - functional programming interface
 * @export
 */
export const Przelewy24ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = Przelewy24ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async przelewy24ControllerGetAllUserTransactions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.przelewy24ControllerGetAllUserTransactions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async przelewy24PublicControllerRefundTransaction(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.przelewy24PublicControllerRefundTransaction(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async przelewy24PublicControllerVerifyTransaction(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.przelewy24PublicControllerVerifyTransaction(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * Przelewy24Api - factory interface
 * @export
 */
export const Przelewy24ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = Przelewy24ApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        przelewy24ControllerGetAllUserTransactions(options?: AxiosRequestConfig): AxiosPromise<Array<object>> {
            return localVarFp.przelewy24ControllerGetAllUserTransactions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        przelewy24PublicControllerRefundTransaction(options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.przelewy24PublicControllerRefundTransaction(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        przelewy24PublicControllerVerifyTransaction(options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.przelewy24PublicControllerVerifyTransaction(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Przelewy24Api - object-oriented interface
 * @export
 * @class Przelewy24Api
 * @extends {BaseAPI}
 */
export class Przelewy24Api extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Przelewy24Api
     */
    public przelewy24ControllerGetAllUserTransactions(options?: AxiosRequestConfig) {
        return Przelewy24ApiFp(this.configuration).przelewy24ControllerGetAllUserTransactions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Przelewy24Api
     */
    public przelewy24PublicControllerRefundTransaction(options?: AxiosRequestConfig) {
        return Przelewy24ApiFp(this.configuration).przelewy24PublicControllerRefundTransaction(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Przelewy24Api
     */
    public przelewy24PublicControllerVerifyTransaction(options?: AxiosRequestConfig) {
        return Przelewy24ApiFp(this.configuration).przelewy24PublicControllerVerifyTransaction(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RegisterApi - axios parameter creator
 * @export
 */
export const RegisterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Register professional user with email and password
         * @param {RegisterProfessionalDto} registerProfessionalDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerControllerRegisterProfessionalUser: async (registerProfessionalDto: RegisterProfessionalDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registerProfessionalDto' is not null or undefined
            assertParamExists('registerControllerRegisterProfessionalUser', 'registerProfessionalDto', registerProfessionalDto)
            const localVarPath = `/register/professional`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerProfessionalDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Register user with email and password
         * @param {RegisterUserDto} registerUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerControllerRegisterUser: async (registerUserDto: RegisterUserDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registerUserDto' is not null or undefined
            assertParamExists('registerControllerRegisterUser', 'registerUserDto', registerUserDto)
            const localVarPath = `/register/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RegisterApi - functional programming interface
 * @export
 */
export const RegisterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RegisterApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Register professional user with email and password
         * @param {RegisterProfessionalDto} registerProfessionalDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerControllerRegisterProfessionalUser(registerProfessionalDto: RegisterProfessionalDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerControllerRegisterProfessionalUser(registerProfessionalDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Register user with email and password
         * @param {RegisterUserDto} registerUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerControllerRegisterUser(registerUserDto: RegisterUserDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerControllerRegisterUser(registerUserDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RegisterApi - factory interface
 * @export
 */
export const RegisterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RegisterApiFp(configuration)
    return {
        /**
         * 
         * @summary Register professional user with email and password
         * @param {RegisterApiRegisterControllerRegisterProfessionalUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerControllerRegisterProfessionalUser(requestParameters: RegisterApiRegisterControllerRegisterProfessionalUserRequest, options?: AxiosRequestConfig): AxiosPromise<GetUserDto> {
            return localVarFp.registerControllerRegisterProfessionalUser(requestParameters.registerProfessionalDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Register user with email and password
         * @param {RegisterApiRegisterControllerRegisterUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerControllerRegisterUser(requestParameters: RegisterApiRegisterControllerRegisterUserRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.registerControllerRegisterUser(requestParameters.registerUserDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for registerControllerRegisterProfessionalUser operation in RegisterApi.
 * @export
 * @interface RegisterApiRegisterControllerRegisterProfessionalUserRequest
 */
export interface RegisterApiRegisterControllerRegisterProfessionalUserRequest {
    /**
     * 
     * @type {RegisterProfessionalDto}
     * @memberof RegisterApiRegisterControllerRegisterProfessionalUser
     */
    readonly registerProfessionalDto: RegisterProfessionalDto
}

/**
 * Request parameters for registerControllerRegisterUser operation in RegisterApi.
 * @export
 * @interface RegisterApiRegisterControllerRegisterUserRequest
 */
export interface RegisterApiRegisterControllerRegisterUserRequest {
    /**
     * 
     * @type {RegisterUserDto}
     * @memberof RegisterApiRegisterControllerRegisterUser
     */
    readonly registerUserDto: RegisterUserDto
}

/**
 * RegisterApi - object-oriented interface
 * @export
 * @class RegisterApi
 * @extends {BaseAPI}
 */
export class RegisterApi extends BaseAPI {
    /**
     * 
     * @summary Register professional user with email and password
     * @param {RegisterApiRegisterControllerRegisterProfessionalUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegisterApi
     */
    public registerControllerRegisterProfessionalUser(requestParameters: RegisterApiRegisterControllerRegisterProfessionalUserRequest, options?: AxiosRequestConfig) {
        return RegisterApiFp(this.configuration).registerControllerRegisterProfessionalUser(requestParameters.registerProfessionalDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Register user with email and password
     * @param {RegisterApiRegisterControllerRegisterUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegisterApi
     */
    public registerControllerRegisterUser(requestParameters: RegisterApiRegisterControllerRegisterUserRequest, options?: AxiosRequestConfig) {
        return RegisterApiFp(this.configuration).registerControllerRegisterUser(requestParameters.registerUserDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create professional after successfully logging in with Firebase
         * @param {CreateProfessionalDto} createProfessionalDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerCreateProfessionalUser: async (createProfessionalDto: CreateProfessionalDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createProfessionalDto' is not null or undefined
            assertParamExists('userControllerCreateProfessionalUser', 'createProfessionalDto', createProfessionalDto)
            const localVarPath = `/api/user/professional`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProfessionalDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create user after successfully logging in with Firebase
         * @param {CreateUserDto} createUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerCreateUser: async (createUserDto: CreateUserDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUserDto' is not null or undefined
            assertParamExists('userControllerCreateUser', 'createUserDto', createUserDto)
            const localVarPath = `/api/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get logged in user data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetCurrentUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Preview user. Available for professional only
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerPreviewUser: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('userControllerPreviewUser', 'userId', userId)
            const localVarPath = `/api/user/{userId}/preview`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set existing user as an admin. Requires an existing admin user in database to execute
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerSetAsAdmin: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('userControllerSetAsAdmin', 'userId', userId)
            const localVarPath = `/api/user/{userId}/set-as-admin`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set existing user as professional. Requires admin role to execute
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerSetAsProfessional: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('userControllerSetAsProfessional', 'userId', userId)
            const localVarPath = `/api/user/{userId}/set-as-professional`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update logged in user
         * @param {UpdateUserDto} updateUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUpdateUser: async (updateUserDto: UpdateUserDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateUserDto' is not null or undefined
            assertParamExists('userControllerUpdateUser', 'updateUserDto', updateUserDto)
            const localVarPath = `/api/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create professional after successfully logging in with Firebase
         * @param {CreateProfessionalDto} createProfessionalDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerCreateProfessionalUser(createProfessionalDto: CreateProfessionalDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerCreateProfessionalUser(createProfessionalDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create user after successfully logging in with Firebase
         * @param {CreateUserDto} createUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerCreateUser(createUserDto: CreateUserDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerCreateUser(createUserDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get logged in user data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerGetCurrentUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerGetCurrentUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Preview user. Available for professional only
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerPreviewUser(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PreviewUserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerPreviewUser(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set existing user as an admin. Requires an existing admin user in database to execute
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerSetAsAdmin(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerSetAsAdmin(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set existing user as professional. Requires admin role to execute
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerSetAsProfessional(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerSetAsProfessional(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update logged in user
         * @param {UpdateUserDto} updateUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerUpdateUser(updateUserDto: UpdateUserDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerUpdateUser(updateUserDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary Create professional after successfully logging in with Firebase
         * @param {UserApiUserControllerCreateProfessionalUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerCreateProfessionalUser(requestParameters: UserApiUserControllerCreateProfessionalUserRequest, options?: AxiosRequestConfig): AxiosPromise<GetUserDto> {
            return localVarFp.userControllerCreateProfessionalUser(requestParameters.createProfessionalDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create user after successfully logging in with Firebase
         * @param {UserApiUserControllerCreateUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerCreateUser(requestParameters: UserApiUserControllerCreateUserRequest, options?: AxiosRequestConfig): AxiosPromise<GetUserDto> {
            return localVarFp.userControllerCreateUser(requestParameters.createUserDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get logged in user data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetCurrentUser(options?: AxiosRequestConfig): AxiosPromise<GetUserDto> {
            return localVarFp.userControllerGetCurrentUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Preview user. Available for professional only
         * @param {UserApiUserControllerPreviewUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerPreviewUser(requestParameters: UserApiUserControllerPreviewUserRequest, options?: AxiosRequestConfig): AxiosPromise<PreviewUserDto> {
            return localVarFp.userControllerPreviewUser(requestParameters.userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set existing user as an admin. Requires an existing admin user in database to execute
         * @param {UserApiUserControllerSetAsAdminRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerSetAsAdmin(requestParameters: UserApiUserControllerSetAsAdminRequest, options?: AxiosRequestConfig): AxiosPromise<GetUserDto> {
            return localVarFp.userControllerSetAsAdmin(requestParameters.userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set existing user as professional. Requires admin role to execute
         * @param {UserApiUserControllerSetAsProfessionalRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerSetAsProfessional(requestParameters: UserApiUserControllerSetAsProfessionalRequest, options?: AxiosRequestConfig): AxiosPromise<GetUserDto> {
            return localVarFp.userControllerSetAsProfessional(requestParameters.userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update logged in user
         * @param {UserApiUserControllerUpdateUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUpdateUser(requestParameters: UserApiUserControllerUpdateUserRequest, options?: AxiosRequestConfig): AxiosPromise<GetUserDto> {
            return localVarFp.userControllerUpdateUser(requestParameters.updateUserDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for userControllerCreateProfessionalUser operation in UserApi.
 * @export
 * @interface UserApiUserControllerCreateProfessionalUserRequest
 */
export interface UserApiUserControllerCreateProfessionalUserRequest {
    /**
     * 
     * @type {CreateProfessionalDto}
     * @memberof UserApiUserControllerCreateProfessionalUser
     */
    readonly createProfessionalDto: CreateProfessionalDto
}

/**
 * Request parameters for userControllerCreateUser operation in UserApi.
 * @export
 * @interface UserApiUserControllerCreateUserRequest
 */
export interface UserApiUserControllerCreateUserRequest {
    /**
     * 
     * @type {CreateUserDto}
     * @memberof UserApiUserControllerCreateUser
     */
    readonly createUserDto: CreateUserDto
}

/**
 * Request parameters for userControllerPreviewUser operation in UserApi.
 * @export
 * @interface UserApiUserControllerPreviewUserRequest
 */
export interface UserApiUserControllerPreviewUserRequest {
    /**
     * 
     * @type {string}
     * @memberof UserApiUserControllerPreviewUser
     */
    readonly userId: string
}

/**
 * Request parameters for userControllerSetAsAdmin operation in UserApi.
 * @export
 * @interface UserApiUserControllerSetAsAdminRequest
 */
export interface UserApiUserControllerSetAsAdminRequest {
    /**
     * 
     * @type {string}
     * @memberof UserApiUserControllerSetAsAdmin
     */
    readonly userId: string
}

/**
 * Request parameters for userControllerSetAsProfessional operation in UserApi.
 * @export
 * @interface UserApiUserControllerSetAsProfessionalRequest
 */
export interface UserApiUserControllerSetAsProfessionalRequest {
    /**
     * 
     * @type {string}
     * @memberof UserApiUserControllerSetAsProfessional
     */
    readonly userId: string
}

/**
 * Request parameters for userControllerUpdateUser operation in UserApi.
 * @export
 * @interface UserApiUserControllerUpdateUserRequest
 */
export interface UserApiUserControllerUpdateUserRequest {
    /**
     * 
     * @type {UpdateUserDto}
     * @memberof UserApiUserControllerUpdateUser
     */
    readonly updateUserDto: UpdateUserDto
}

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary Create professional after successfully logging in with Firebase
     * @param {UserApiUserControllerCreateProfessionalUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerCreateProfessionalUser(requestParameters: UserApiUserControllerCreateProfessionalUserRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerCreateProfessionalUser(requestParameters.createProfessionalDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create user after successfully logging in with Firebase
     * @param {UserApiUserControllerCreateUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerCreateUser(requestParameters: UserApiUserControllerCreateUserRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerCreateUser(requestParameters.createUserDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get logged in user data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerGetCurrentUser(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerGetCurrentUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Preview user. Available for professional only
     * @param {UserApiUserControllerPreviewUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerPreviewUser(requestParameters: UserApiUserControllerPreviewUserRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerPreviewUser(requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set existing user as an admin. Requires an existing admin user in database to execute
     * @param {UserApiUserControllerSetAsAdminRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerSetAsAdmin(requestParameters: UserApiUserControllerSetAsAdminRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerSetAsAdmin(requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set existing user as professional. Requires admin role to execute
     * @param {UserApiUserControllerSetAsProfessionalRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerSetAsProfessional(requestParameters: UserApiUserControllerSetAsProfessionalRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerSetAsProfessional(requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update logged in user
     * @param {UserApiUserControllerUpdateUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerUpdateUser(requestParameters: UserApiUserControllerUpdateUserRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerUpdateUser(requestParameters.updateUserDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VisitApi - axios parameter creator
 * @export
 */
export const VisitApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Abort ongoing visit
         * @param {AbortVisitDto} abortVisitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        visitControllerAbortVisit: async (abortVisitDto: AbortVisitDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'abortVisitDto' is not null or undefined
            assertParamExists('visitControllerAbortVisit', 'abortVisitDto', abortVisitDto)
            const localVarPath = `/api/visit/abort`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(abortVisitDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add documents to a visit.
         * @param {string} visitId 
         * @param {Array<DocumentFileDto>} documentFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        visitControllerAddDocuments: async (visitId: string, documentFileDto: Array<DocumentFileDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'visitId' is not null or undefined
            assertParamExists('visitControllerAddDocuments', 'visitId', visitId)
            // verify required parameter 'documentFileDto' is not null or undefined
            assertParamExists('visitControllerAddDocuments', 'documentFileDto', documentFileDto)
            const localVarPath = `/api/visit/{visitId}/documents`
                .replace(`{${"visitId"}}`, encodeURIComponent(String(visitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentFileDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add notes to a visit. Professional only.
         * @param {string} visitId 
         * @param {AddNotesDto} addNotesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        visitControllerAddNotes: async (visitId: string, addNotesDto: AddNotesDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'visitId' is not null or undefined
            assertParamExists('visitControllerAddNotes', 'visitId', visitId)
            // verify required parameter 'addNotesDto' is not null or undefined
            assertParamExists('visitControllerAddNotes', 'addNotesDto', addNotesDto)
            const localVarPath = `/api/visit/{visitId}/addNotes`
                .replace(`{${"visitId"}}`, encodeURIComponent(String(visitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addNotesDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel existing visit
         * @param {string} visitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        visitControllerCancelVisit: async (visitId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'visitId' is not null or undefined
            assertParamExists('visitControllerCancelVisit', 'visitId', visitId)
            const localVarPath = `/api/visit/cancel/{visitId}`
                .replace(`{${"visitId"}}`, encodeURIComponent(String(visitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all visits
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        visitControllerGetAllVisits: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/visit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all available time slots
         * @param {string} startDate 
         * @param {string} category 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        visitControllerGetAvailableVisitSlots: async (startDate: string, category: string, endDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('visitControllerGetAvailableVisitSlots', 'startDate', startDate)
            // verify required parameter 'category' is not null or undefined
            assertParamExists('visitControllerGetAvailableVisitSlots', 'category', category)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('visitControllerGetAvailableVisitSlots', 'endDate', endDate)
            const localVarPath = `/api/visit/available-slots`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get pending visit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        visitControllerGetCurrentPendingVisit: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/visit/date/current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get professional visit history
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        visitControllerGetProfessionalVisitHistory: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/visit/history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get profile visit history. Professional only.
         * @param {string} profileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        visitControllerGetProfileVisitHistoryPreview: async (profileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'profileId' is not null or undefined
            assertParamExists('visitControllerGetProfileVisitHistoryPreview', 'profileId', profileId)
            const localVarPath = `/api/visit/history/profile/{profileId}/preview`
                .replace(`{${"profileId"}}`, encodeURIComponent(String(profileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get professional today visits
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        visitControllerGetTodaysVisits: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/visit/date/today`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user upcoming visits
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        visitControllerGetUserUpcomingVisits: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/visit/date/upcoming`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get visit by id
         * @param {string} visitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        visitControllerGetVisitById: async (visitId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'visitId' is not null or undefined
            assertParamExists('visitControllerGetVisitById', 'visitId', visitId)
            const localVarPath = `/api/visit/{visitId}`
                .replace(`{${"visitId"}}`, encodeURIComponent(String(visitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get profile visit history
         * @param {string} profileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        visitControllerGetVisitHistory: async (profileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'profileId' is not null or undefined
            assertParamExists('visitControllerGetVisitHistory', 'profileId', profileId)
            const localVarPath = `/api/visit/history/profile/{profileId}`
                .replace(`{${"profileId"}}`, encodeURIComponent(String(profileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get professional visit summary a.k.a. stats
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        visitControllerGetVisitStats: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/visit/stats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Rate visit after completed.
         * @param {string} visitId 
         * @param {RateVisitDto} rateVisitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        visitControllerRateVisit: async (visitId: string, rateVisitDto: RateVisitDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'visitId' is not null or undefined
            assertParamExists('visitControllerRateVisit', 'visitId', visitId)
            // verify required parameter 'rateVisitDto' is not null or undefined
            assertParamExists('visitControllerRateVisit', 'rateVisitDto', rateVisitDto)
            const localVarPath = `/api/visit/{visitId}/rate`
                .replace(`{${"visitId"}}`, encodeURIComponent(String(visitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rateVisitDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reschedule existing visit
         * @param {RescheduleVisitDto} rescheduleVisitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        visitControllerRescheduleVisit: async (rescheduleVisitDto: RescheduleVisitDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rescheduleVisitDto' is not null or undefined
            assertParamExists('visitControllerRescheduleVisit', 'rescheduleVisitDto', rescheduleVisitDto)
            const localVarPath = `/api/visit/reschedule`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rescheduleVisitDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Schedule a new visit. Note: there needs to be at least one professional setup.
         * @param {ScheduleVisitDto} scheduleVisitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        visitControllerScheduleVisit: async (scheduleVisitDto: ScheduleVisitDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scheduleVisitDto' is not null or undefined
            assertParamExists('visitControllerScheduleVisit', 'scheduleVisitDto', scheduleVisitDto)
            const localVarPath = `/api/visit/schedule`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scheduleVisitDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VisitApi - functional programming interface
 * @export
 */
export const VisitApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VisitApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Abort ongoing visit
         * @param {AbortVisitDto} abortVisitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async visitControllerAbortVisit(abortVisitDto: AbortVisitDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.visitControllerAbortVisit(abortVisitDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add documents to a visit.
         * @param {string} visitId 
         * @param {Array<DocumentFileDto>} documentFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async visitControllerAddDocuments(visitId: string, documentFileDto: Array<DocumentFileDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DocumentFileResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.visitControllerAddDocuments(visitId, documentFileDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add notes to a visit. Professional only.
         * @param {string} visitId 
         * @param {AddNotesDto} addNotesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async visitControllerAddNotes(visitId: string, addNotesDto: AddNotesDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.visitControllerAddNotes(visitId, addNotesDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cancel existing visit
         * @param {string} visitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async visitControllerCancelVisit(visitId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.visitControllerCancelVisit(visitId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all visits
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async visitControllerGetAllVisits(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetVisitById>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.visitControllerGetAllVisits(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all available time slots
         * @param {string} startDate 
         * @param {string} category 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async visitControllerGetAvailableVisitSlots(startDate: string, category: string, endDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AvailableSlotsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.visitControllerGetAvailableVisitSlots(startDate, category, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get pending visit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async visitControllerGetCurrentPendingVisit(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetVisitById>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.visitControllerGetCurrentPendingVisit(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get professional visit history
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async visitControllerGetProfessionalVisitHistory(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetVisitById>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.visitControllerGetProfessionalVisitHistory(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get profile visit history. Professional only.
         * @param {string} profileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async visitControllerGetProfileVisitHistoryPreview(profileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetVisitById>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.visitControllerGetProfileVisitHistoryPreview(profileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get professional today visits
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async visitControllerGetTodaysVisits(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetVisitById>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.visitControllerGetTodaysVisits(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user upcoming visits
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async visitControllerGetUserUpcomingVisits(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetVisitById>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.visitControllerGetUserUpcomingVisits(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get visit by id
         * @param {string} visitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async visitControllerGetVisitById(visitId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetVisitById>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.visitControllerGetVisitById(visitId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get profile visit history
         * @param {string} profileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async visitControllerGetVisitHistory(profileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetVisitById>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.visitControllerGetVisitHistory(profileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get professional visit summary a.k.a. stats
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async visitControllerGetVisitStats(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VisitStats>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.visitControllerGetVisitStats(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Rate visit after completed.
         * @param {string} visitId 
         * @param {RateVisitDto} rateVisitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async visitControllerRateVisit(visitId: string, rateVisitDto: RateVisitDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.visitControllerRateVisit(visitId, rateVisitDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reschedule existing visit
         * @param {RescheduleVisitDto} rescheduleVisitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async visitControllerRescheduleVisit(rescheduleVisitDto: RescheduleVisitDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.visitControllerRescheduleVisit(rescheduleVisitDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Schedule a new visit. Note: there needs to be at least one professional setup.
         * @param {ScheduleVisitDto} scheduleVisitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async visitControllerScheduleVisit(scheduleVisitDto: ScheduleVisitDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScheduleVisitResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.visitControllerScheduleVisit(scheduleVisitDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VisitApi - factory interface
 * @export
 */
export const VisitApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VisitApiFp(configuration)
    return {
        /**
         * 
         * @summary Abort ongoing visit
         * @param {VisitApiVisitControllerAbortVisitRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        visitControllerAbortVisit(requestParameters: VisitApiVisitControllerAbortVisitRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.visitControllerAbortVisit(requestParameters.abortVisitDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add documents to a visit.
         * @param {VisitApiVisitControllerAddDocumentsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        visitControllerAddDocuments(requestParameters: VisitApiVisitControllerAddDocumentsRequest, options?: AxiosRequestConfig): AxiosPromise<Array<DocumentFileResponseDto>> {
            return localVarFp.visitControllerAddDocuments(requestParameters.visitId, requestParameters.documentFileDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add notes to a visit. Professional only.
         * @param {VisitApiVisitControllerAddNotesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        visitControllerAddNotes(requestParameters: VisitApiVisitControllerAddNotesRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.visitControllerAddNotes(requestParameters.visitId, requestParameters.addNotesDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancel existing visit
         * @param {VisitApiVisitControllerCancelVisitRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        visitControllerCancelVisit(requestParameters: VisitApiVisitControllerCancelVisitRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.visitControllerCancelVisit(requestParameters.visitId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all visits
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        visitControllerGetAllVisits(options?: AxiosRequestConfig): AxiosPromise<Array<GetVisitById>> {
            return localVarFp.visitControllerGetAllVisits(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all available time slots
         * @param {VisitApiVisitControllerGetAvailableVisitSlotsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        visitControllerGetAvailableVisitSlots(requestParameters: VisitApiVisitControllerGetAvailableVisitSlotsRequest, options?: AxiosRequestConfig): AxiosPromise<AvailableSlotsDto> {
            return localVarFp.visitControllerGetAvailableVisitSlots(requestParameters.startDate, requestParameters.category, requestParameters.endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get pending visit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        visitControllerGetCurrentPendingVisit(options?: AxiosRequestConfig): AxiosPromise<GetVisitById> {
            return localVarFp.visitControllerGetCurrentPendingVisit(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get professional visit history
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        visitControllerGetProfessionalVisitHistory(options?: AxiosRequestConfig): AxiosPromise<Array<GetVisitById>> {
            return localVarFp.visitControllerGetProfessionalVisitHistory(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get profile visit history. Professional only.
         * @param {VisitApiVisitControllerGetProfileVisitHistoryPreviewRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        visitControllerGetProfileVisitHistoryPreview(requestParameters: VisitApiVisitControllerGetProfileVisitHistoryPreviewRequest, options?: AxiosRequestConfig): AxiosPromise<Array<GetVisitById>> {
            return localVarFp.visitControllerGetProfileVisitHistoryPreview(requestParameters.profileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get professional today visits
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        visitControllerGetTodaysVisits(options?: AxiosRequestConfig): AxiosPromise<Array<GetVisitById>> {
            return localVarFp.visitControllerGetTodaysVisits(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user upcoming visits
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        visitControllerGetUserUpcomingVisits(options?: AxiosRequestConfig): AxiosPromise<Array<GetVisitById>> {
            return localVarFp.visitControllerGetUserUpcomingVisits(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get visit by id
         * @param {VisitApiVisitControllerGetVisitByIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        visitControllerGetVisitById(requestParameters: VisitApiVisitControllerGetVisitByIdRequest, options?: AxiosRequestConfig): AxiosPromise<GetVisitById> {
            return localVarFp.visitControllerGetVisitById(requestParameters.visitId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get profile visit history
         * @param {VisitApiVisitControllerGetVisitHistoryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        visitControllerGetVisitHistory(requestParameters: VisitApiVisitControllerGetVisitHistoryRequest, options?: AxiosRequestConfig): AxiosPromise<Array<GetVisitById>> {
            return localVarFp.visitControllerGetVisitHistory(requestParameters.profileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get professional visit summary a.k.a. stats
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        visitControllerGetVisitStats(options?: AxiosRequestConfig): AxiosPromise<VisitStats> {
            return localVarFp.visitControllerGetVisitStats(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Rate visit after completed.
         * @param {VisitApiVisitControllerRateVisitRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        visitControllerRateVisit(requestParameters: VisitApiVisitControllerRateVisitRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.visitControllerRateVisit(requestParameters.visitId, requestParameters.rateVisitDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reschedule existing visit
         * @param {VisitApiVisitControllerRescheduleVisitRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        visitControllerRescheduleVisit(requestParameters: VisitApiVisitControllerRescheduleVisitRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.visitControllerRescheduleVisit(requestParameters.rescheduleVisitDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Schedule a new visit. Note: there needs to be at least one professional setup.
         * @param {VisitApiVisitControllerScheduleVisitRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        visitControllerScheduleVisit(requestParameters: VisitApiVisitControllerScheduleVisitRequest, options?: AxiosRequestConfig): AxiosPromise<ScheduleVisitResponseDto> {
            return localVarFp.visitControllerScheduleVisit(requestParameters.scheduleVisitDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for visitControllerAbortVisit operation in VisitApi.
 * @export
 * @interface VisitApiVisitControllerAbortVisitRequest
 */
export interface VisitApiVisitControllerAbortVisitRequest {
    /**
     * 
     * @type {AbortVisitDto}
     * @memberof VisitApiVisitControllerAbortVisit
     */
    readonly abortVisitDto: AbortVisitDto
}

/**
 * Request parameters for visitControllerAddDocuments operation in VisitApi.
 * @export
 * @interface VisitApiVisitControllerAddDocumentsRequest
 */
export interface VisitApiVisitControllerAddDocumentsRequest {
    /**
     * 
     * @type {string}
     * @memberof VisitApiVisitControllerAddDocuments
     */
    readonly visitId: string

    /**
     * 
     * @type {Array<DocumentFileDto>}
     * @memberof VisitApiVisitControllerAddDocuments
     */
    readonly documentFileDto: Array<DocumentFileDto>
}

/**
 * Request parameters for visitControllerAddNotes operation in VisitApi.
 * @export
 * @interface VisitApiVisitControllerAddNotesRequest
 */
export interface VisitApiVisitControllerAddNotesRequest {
    /**
     * 
     * @type {string}
     * @memberof VisitApiVisitControllerAddNotes
     */
    readonly visitId: string

    /**
     * 
     * @type {AddNotesDto}
     * @memberof VisitApiVisitControllerAddNotes
     */
    readonly addNotesDto: AddNotesDto
}

/**
 * Request parameters for visitControllerCancelVisit operation in VisitApi.
 * @export
 * @interface VisitApiVisitControllerCancelVisitRequest
 */
export interface VisitApiVisitControllerCancelVisitRequest {
    /**
     * 
     * @type {string}
     * @memberof VisitApiVisitControllerCancelVisit
     */
    readonly visitId: string
}

/**
 * Request parameters for visitControllerGetAvailableVisitSlots operation in VisitApi.
 * @export
 * @interface VisitApiVisitControllerGetAvailableVisitSlotsRequest
 */
export interface VisitApiVisitControllerGetAvailableVisitSlotsRequest {
    /**
     * 
     * @type {string}
     * @memberof VisitApiVisitControllerGetAvailableVisitSlots
     */
    readonly startDate: string

    /**
     * 
     * @type {string}
     * @memberof VisitApiVisitControllerGetAvailableVisitSlots
     */
    readonly category: string

    /**
     * 
     * @type {string}
     * @memberof VisitApiVisitControllerGetAvailableVisitSlots
     */
    readonly endDate: string
}

/**
 * Request parameters for visitControllerGetProfileVisitHistoryPreview operation in VisitApi.
 * @export
 * @interface VisitApiVisitControllerGetProfileVisitHistoryPreviewRequest
 */
export interface VisitApiVisitControllerGetProfileVisitHistoryPreviewRequest {
    /**
     * 
     * @type {string}
     * @memberof VisitApiVisitControllerGetProfileVisitHistoryPreview
     */
    readonly profileId: string
}

/**
 * Request parameters for visitControllerGetVisitById operation in VisitApi.
 * @export
 * @interface VisitApiVisitControllerGetVisitByIdRequest
 */
export interface VisitApiVisitControllerGetVisitByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof VisitApiVisitControllerGetVisitById
     */
    readonly visitId: string
}

/**
 * Request parameters for visitControllerGetVisitHistory operation in VisitApi.
 * @export
 * @interface VisitApiVisitControllerGetVisitHistoryRequest
 */
export interface VisitApiVisitControllerGetVisitHistoryRequest {
    /**
     * 
     * @type {string}
     * @memberof VisitApiVisitControllerGetVisitHistory
     */
    readonly profileId: string
}

/**
 * Request parameters for visitControllerRateVisit operation in VisitApi.
 * @export
 * @interface VisitApiVisitControllerRateVisitRequest
 */
export interface VisitApiVisitControllerRateVisitRequest {
    /**
     * 
     * @type {string}
     * @memberof VisitApiVisitControllerRateVisit
     */
    readonly visitId: string

    /**
     * 
     * @type {RateVisitDto}
     * @memberof VisitApiVisitControllerRateVisit
     */
    readonly rateVisitDto: RateVisitDto
}

/**
 * Request parameters for visitControllerRescheduleVisit operation in VisitApi.
 * @export
 * @interface VisitApiVisitControllerRescheduleVisitRequest
 */
export interface VisitApiVisitControllerRescheduleVisitRequest {
    /**
     * 
     * @type {RescheduleVisitDto}
     * @memberof VisitApiVisitControllerRescheduleVisit
     */
    readonly rescheduleVisitDto: RescheduleVisitDto
}

/**
 * Request parameters for visitControllerScheduleVisit operation in VisitApi.
 * @export
 * @interface VisitApiVisitControllerScheduleVisitRequest
 */
export interface VisitApiVisitControllerScheduleVisitRequest {
    /**
     * 
     * @type {ScheduleVisitDto}
     * @memberof VisitApiVisitControllerScheduleVisit
     */
    readonly scheduleVisitDto: ScheduleVisitDto
}

/**
 * VisitApi - object-oriented interface
 * @export
 * @class VisitApi
 * @extends {BaseAPI}
 */
export class VisitApi extends BaseAPI {
    /**
     * 
     * @summary Abort ongoing visit
     * @param {VisitApiVisitControllerAbortVisitRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitApi
     */
    public visitControllerAbortVisit(requestParameters: VisitApiVisitControllerAbortVisitRequest, options?: AxiosRequestConfig) {
        return VisitApiFp(this.configuration).visitControllerAbortVisit(requestParameters.abortVisitDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add documents to a visit.
     * @param {VisitApiVisitControllerAddDocumentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitApi
     */
    public visitControllerAddDocuments(requestParameters: VisitApiVisitControllerAddDocumentsRequest, options?: AxiosRequestConfig) {
        return VisitApiFp(this.configuration).visitControllerAddDocuments(requestParameters.visitId, requestParameters.documentFileDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add notes to a visit. Professional only.
     * @param {VisitApiVisitControllerAddNotesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitApi
     */
    public visitControllerAddNotes(requestParameters: VisitApiVisitControllerAddNotesRequest, options?: AxiosRequestConfig) {
        return VisitApiFp(this.configuration).visitControllerAddNotes(requestParameters.visitId, requestParameters.addNotesDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancel existing visit
     * @param {VisitApiVisitControllerCancelVisitRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitApi
     */
    public visitControllerCancelVisit(requestParameters: VisitApiVisitControllerCancelVisitRequest, options?: AxiosRequestConfig) {
        return VisitApiFp(this.configuration).visitControllerCancelVisit(requestParameters.visitId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all visits
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitApi
     */
    public visitControllerGetAllVisits(options?: AxiosRequestConfig) {
        return VisitApiFp(this.configuration).visitControllerGetAllVisits(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all available time slots
     * @param {VisitApiVisitControllerGetAvailableVisitSlotsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitApi
     */
    public visitControllerGetAvailableVisitSlots(requestParameters: VisitApiVisitControllerGetAvailableVisitSlotsRequest, options?: AxiosRequestConfig) {
        return VisitApiFp(this.configuration).visitControllerGetAvailableVisitSlots(requestParameters.startDate, requestParameters.category, requestParameters.endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get pending visit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitApi
     */
    public visitControllerGetCurrentPendingVisit(options?: AxiosRequestConfig) {
        return VisitApiFp(this.configuration).visitControllerGetCurrentPendingVisit(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get professional visit history
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitApi
     */
    public visitControllerGetProfessionalVisitHistory(options?: AxiosRequestConfig) {
        return VisitApiFp(this.configuration).visitControllerGetProfessionalVisitHistory(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get profile visit history. Professional only.
     * @param {VisitApiVisitControllerGetProfileVisitHistoryPreviewRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitApi
     */
    public visitControllerGetProfileVisitHistoryPreview(requestParameters: VisitApiVisitControllerGetProfileVisitHistoryPreviewRequest, options?: AxiosRequestConfig) {
        return VisitApiFp(this.configuration).visitControllerGetProfileVisitHistoryPreview(requestParameters.profileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get professional today visits
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitApi
     */
    public visitControllerGetTodaysVisits(options?: AxiosRequestConfig) {
        return VisitApiFp(this.configuration).visitControllerGetTodaysVisits(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user upcoming visits
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitApi
     */
    public visitControllerGetUserUpcomingVisits(options?: AxiosRequestConfig) {
        return VisitApiFp(this.configuration).visitControllerGetUserUpcomingVisits(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get visit by id
     * @param {VisitApiVisitControllerGetVisitByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitApi
     */
    public visitControllerGetVisitById(requestParameters: VisitApiVisitControllerGetVisitByIdRequest, options?: AxiosRequestConfig) {
        return VisitApiFp(this.configuration).visitControllerGetVisitById(requestParameters.visitId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get profile visit history
     * @param {VisitApiVisitControllerGetVisitHistoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitApi
     */
    public visitControllerGetVisitHistory(requestParameters: VisitApiVisitControllerGetVisitHistoryRequest, options?: AxiosRequestConfig) {
        return VisitApiFp(this.configuration).visitControllerGetVisitHistory(requestParameters.profileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get professional visit summary a.k.a. stats
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitApi
     */
    public visitControllerGetVisitStats(options?: AxiosRequestConfig) {
        return VisitApiFp(this.configuration).visitControllerGetVisitStats(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Rate visit after completed.
     * @param {VisitApiVisitControllerRateVisitRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitApi
     */
    public visitControllerRateVisit(requestParameters: VisitApiVisitControllerRateVisitRequest, options?: AxiosRequestConfig) {
        return VisitApiFp(this.configuration).visitControllerRateVisit(requestParameters.visitId, requestParameters.rateVisitDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reschedule existing visit
     * @param {VisitApiVisitControllerRescheduleVisitRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitApi
     */
    public visitControllerRescheduleVisit(requestParameters: VisitApiVisitControllerRescheduleVisitRequest, options?: AxiosRequestConfig) {
        return VisitApiFp(this.configuration).visitControllerRescheduleVisit(requestParameters.rescheduleVisitDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Schedule a new visit. Note: there needs to be at least one professional setup.
     * @param {VisitApiVisitControllerScheduleVisitRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitApi
     */
    public visitControllerScheduleVisit(requestParameters: VisitApiVisitControllerScheduleVisitRequest, options?: AxiosRequestConfig) {
        return VisitApiFp(this.configuration).visitControllerScheduleVisit(requestParameters.scheduleVisitDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VisitCategoriesApi - axios parameter creator
 * @export
 */
export const VisitCategoriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get visit categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        visitCategoriesControllerGetAllVisitCategories: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/visit/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VisitCategoriesApi - functional programming interface
 * @export
 */
export const VisitCategoriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VisitCategoriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get visit categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async visitCategoriesControllerGetAllVisitCategories(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetVisitCategoriesDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.visitCategoriesControllerGetAllVisitCategories(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VisitCategoriesApi - factory interface
 * @export
 */
export const VisitCategoriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VisitCategoriesApiFp(configuration)
    return {
        /**
         * 
         * @summary Get visit categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        visitCategoriesControllerGetAllVisitCategories(options?: AxiosRequestConfig): AxiosPromise<Array<GetVisitCategoriesDto>> {
            return localVarFp.visitCategoriesControllerGetAllVisitCategories(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VisitCategoriesApi - object-oriented interface
 * @export
 * @class VisitCategoriesApi
 * @extends {BaseAPI}
 */
export class VisitCategoriesApi extends BaseAPI {
    /**
     * 
     * @summary Get visit categories
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitCategoriesApi
     */
    public visitCategoriesControllerGetAllVisitCategories(options?: AxiosRequestConfig) {
        return VisitCategoriesApiFp(this.configuration).visitCategoriesControllerGetAllVisitCategories(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VisitCronApi - axios parameter creator
 * @export
 */
export const VisitCronApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Run Visit Cron Job
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vistCronControllerRun: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/visit/cron`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VisitCronApi - functional programming interface
 * @export
 */
export const VisitCronApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VisitCronApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Run Visit Cron Job
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vistCronControllerRun(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vistCronControllerRun(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VisitCronApi - factory interface
 * @export
 */
export const VisitCronApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VisitCronApiFp(configuration)
    return {
        /**
         * 
         * @summary Run Visit Cron Job
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vistCronControllerRun(options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.vistCronControllerRun(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VisitCronApi - object-oriented interface
 * @export
 * @class VisitCronApi
 * @extends {BaseAPI}
 */
export class VisitCronApi extends BaseAPI {
    /**
     * 
     * @summary Run Visit Cron Job
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisitCronApi
     */
    public vistCronControllerRun(options?: AxiosRequestConfig) {
        return VisitCronApiFp(this.configuration).vistCronControllerRun(options).then((request) => request(this.axios, this.basePath));
    }
}


