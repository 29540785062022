import Iconify from 'src/components/iconify';
import useMainParticipant from '../hooks/useMainParticipant';
import useVideoContext from '../hooks/useVideoContext';
import Participant from './Participant';

export default function MainParticipant() {
  const { room } = useVideoContext();
  const mainParticipant = useMainParticipant();

  return (
    <Participant
      participant={mainParticipant}
      isLocalParticipant={(mainParticipant as any) === room?.localParticipant}
      NoVideo={
        <Iconify icon="eva:video-off-fill" color="#F4ECE5" width="48px" />
      }
    />
  );
}
