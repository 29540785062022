// @mui
// layouts
//
import { Button, Typography, useTheme } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useAuthContext } from 'src/auth/useAuthContext';
import { useSnackbar } from 'notistack';
import { getFirebaseErrorMessage } from 'src/utils/getFirebaseErrorMessage';
import LoadingScreen from 'src/components/loading-screen';
import LoginHeader from 'src/layouts/login/LoginHeader';
import { PATH_AUTH } from 'src/routes/paths';
import Image from '../../components/image';

// ----------------------------------------------------------------------

export default function Verification() {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const navigtate = useNavigate();
  const { search } = useLocation();
  const { verifyEmail } = useAuthContext();
  const [state, setState] = useState({
    isLoading: true,
    error: false,
    success: false,
  });

  const query = useMemo(() => new URLSearchParams(search), [search]);
  const mode = query.get('mode');
  const actionCode = query.get('oobCode');

  useEffect(() => {
    const verify = async () => {
      if (!actionCode || mode !== 'verifyEmail') {
        setState(() => ({ success: false, isLoading: false, error: true }));
      }

      if (actionCode && mode === 'verifyEmail' && verifyEmail) {
        try {
          setState((prevState) => ({ ...prevState, isLoading: true }));
          await verifyEmail(actionCode);
          setState((prevState) => ({
            ...prevState,
            success: true,
            isLoading: false,
          }));
        } catch (error) {
          setState(() => ({ success: false, isLoading: false, error: true }));
          enqueueSnackbar(getFirebaseErrorMessage(error.code) || error, {
            variant: 'error',
          });
        }
      }
    };

    verify();
  }, [actionCode, mode, verifyEmail]);

  const onButtonClick = () => navigtate(PATH_AUTH.login);

  if (state.isLoading) {
    return <LoadingScreen />;
  }

  return (
    <Stack sx={{ height: '100%' }}>
     
      <Stack
        sx={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          gap: 5,
          px: { xs: 3, sm: 3, md: 3, lg: 0, xl: 0 },
          paddingTop: 16,
          paddingBottom: 8,
        }}
      >
        <Image
          disabledEffect
          visibleByDefault
          alt="confirmation-image"
          src={
            state.error
              ? '/assets/illustrations/laptop_paw_error.svg'
              : '/assets/illustrations/laptop_paw_success.svg'
          }
          sx={{
            maxWidth: { xs: 320, sm: 320, md: 386, lg: 453, xl: 453 },
          }}
        />
        <Stack
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2,
            maxWidth: 600,
          }}
        >
          <Box>
            <Typography
              variant="h3"
              sx={{ color: theme.palette.primary.main, textAlign: 'center' }}
            >
              {state.error ? 'Paws-orry, coś poszło nie tak.' : 'Pawsome!'}
            </Typography>
          </Box>
          <Typography variant="h6" sx={{ textAlign: 'center' }}>
            {state.error
              ? 'Nie mogliśmy zweryfikować Twojego adresu e-mail. Spróbuj ponownie klikając w link weryfikacyjny w wiadomości e-mail.'
              : 'Twój adres został zweryfikowany. Teraz, gdy jesteś częścią Vetsi, możesz cieszyć się pełnym dostępem do naszej platformy.'}
          </Typography>
        </Stack>
        <Stack sx={{ gap: 3 }}>
          <Button variant="contained" size="large" onClick={onButtonClick}>
            Strona logowania
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}
