import { createContext, useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';
import { visitApi } from 'src/api/visit';
import { GetVisitById } from 'woofwoof-api';

// ----------------------------------------------------------------------

export type VisitsContextType = {
  upcomingVisits?: GetVisitById[];
  nextVisit?: GetVisitById;
  refetch: () => void;
  isLoading: boolean;
  isError: boolean;
};

export const VisitsContext = createContext<VisitsContextType | null>(null);

type VisitsProviderProps = {
  children: React.ReactNode;
};

export function VisitsProvider({ children }: VisitsProviderProps) {
  const { refetch, data, isLoading, isError } = useQuery(
    ['visits'],
    () => visitApi.visitControllerGetUserUpcomingVisits(),
    {
      retry: 1,
      refetchInterval: 10000,
      cacheTime: 0,
    },
  );

  const memoizedValue = useMemo(() => {
    const nextVisit = data?.data[0];
    const upcomingVisits = data?.data;

    return {
      nextVisit,
      upcomingVisits,
      isLoading,
      isError,
      refetch,
    };
  }, [data, isLoading, isError, refetch]);

  return (
    <VisitsContext.Provider value={memoizedValue}>
      {children}
    </VisitsContext.Provider>
  );
}
