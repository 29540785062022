// @mui
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { profileApi } from 'src/api/profile';
import { GetProfileDto } from 'woofwoof-api';

// ----------------------------------------------------------------------

const getError = (message?: string) => {
  console.log(message);
  if (!message) {
    return 'Wystąpił błąd podczas usuwania profilu';
  }

  if (message === 'Cannot delete profile with upcoming visits') {
    return 'Nie można usunąć profilu z zaplanowanymi wizytami';
  }
};

type DeleteProfileModalProps = {
  isOpen: boolean;
  onClose: () => void;
  profile: GetProfileDto;
  redirect?: string;
  refetch: () => void;
};

export default function DeleteProfileModal({
  isOpen,
  onClose,
  profile,
  redirect,
  refetch,
}: DeleteProfileModalProps) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (deleteProfileDto: { profileId: GetProfileDto['id'] }) =>
      profileApi.profileControllerDeleteUser(deleteProfileDto),
  });

  const handleOnDelete = async () => {
    try {
      await mutateAsync({ profileId: profile.id });
      refetch();
      onClose();
      enqueueSnackbar('Twój zwierzak został usunięty', { variant: 'success' });
      redirect && navigate(redirect);
    } catch (error) {
      enqueueSnackbar(getError(error?.data?.message), {
        variant: 'error',
      });
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Próbujesz usunąć swojego zwierzaka</DialogTitle>

      <DialogContent sx={{ maxWidth: '400px' }}>
        <DialogContentText id="alert-dialog-description">
          Czy na pewno chcesz usunąć zwierzaka z listy? Ta akcja jest
          nieodwracalna i spowoduje trwałe usunięcie zwierzaka.
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" color="inherit" onClick={onClose}>
          Anuluj
        </Button>
        <LoadingButton
          variant="contained"
          color="error"
          onClick={handleOnDelete}
          disabled={isLoading}
          loading={isLoading}
        >
          <span>Usuń</span>
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
