import client, { axiosBaseUrl } from './client';
import { UserApi } from 'woofwoof-api';

export const userApi = new UserApi(
  {
    basePath: axiosBaseUrl,
    isJsonMime: () => false,
  },
  undefined,
  //@ts-ignore
  client,
);
