import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import { IconButton, InputAdornment, Stack } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import { useAuthContext } from 'src/auth/useAuthContext';
import { getFirebaseErrorMessage } from 'src/utils/getFirebaseErrorMessage';
import FormProvider, { RHFTextField } from '../../components/hook-form';
import Iconify from '../../components/iconify';
import { useSnackbar } from '../../components/snackbar';

// ----------------------------------------------------------------------

type FormValuesProps = {
  password: string;
  confirmPassword: string;
};

export default function AuthNewPasswordForm() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const { resetPassword } = useAuthContext();
  const [showPassword, setShowPassword] = useState(false);

  const VerifyCodeSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, 'Hasło musi mieć przynajmniej 6 znaków')
      .required('Hasło jest wymagane'),
    confirmPassword: Yup.string()
      .required('Powtórzenie hasła jest wymagane')
      .oneOf([Yup.ref('password')], 'Hasła muszą być identyczne'),
  });

  let oobCode: string | null = searchParams.get('oobCode');

  const defaultValues = {
    password: '',
    confirmPassword: '',
  };

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(VerifyCodeSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting, errors },
  } = methods;

  const onSubmit = async (confirmPassword: string) => {
    try {
      if (oobCode) {
        if (resetPassword) {
          await resetPassword(oobCode, confirmPassword);
        }
      } else {
        enqueueSnackbar('Coś poszło nie tak :(');
      }

      enqueueSnackbar('Twoje hasło zostało zmienione!');
      navigate(PATH_DASHBOARD.root);
    } catch (error) {
      enqueueSnackbar(getFirebaseErrorMessage(error.code) || error, {
        variant: 'error',
      });
    }
  };

  return (
    <FormProvider
      methods={methods}
      onSubmit={handleSubmit((data) => onSubmit(data.confirmPassword))}
    >
      <Stack spacing={3}>
        <RHFTextField
          name="password"
          label="Nowe hasło"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <RHFTextField
          name="confirmPassword"
          label="Powtórz hasło"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          sx={{ mt: 3 }}
        >
          <span>Zresetuj hasło</span>
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
