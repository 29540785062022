// ----------------------------------------------------------------------

export function remToPx(value: string) {
  return Math.round(parseFloat(value) * 16);
}

export function pxToRem(value: number) {
  return `${value / 16}rem`;
}

export function responsiveFontSizes(fontSize: number, lineHeight: number) {
  return {
    '@media (max-width:600px)': {
      fontSize: pxToRem(fontSize),
      lineHeight,
    },
  };
}

// ----------------------------------------------------------------------

const FONT_PRIMARY = 'PPTelegraf, sans-serif'; // Google Font
// const FONT_SECONDARY = 'CircularStd, sans-serif'; // Local Font

const typography = {
  fontFamily: FONT_PRIMARY,
  fontWeightRegular: 500,
  fontWeightMedium: 500,
  fontWeightBold: 500,
  h1: {
    fontWeight: 500,
    lineHeight: 80 / 64,
    fontSize: pxToRem(64),
    ...responsiveFontSizes(40, 50 / 40),
  },
  h2: {
    fontWeight: 500,
    lineHeight: 64 / 48,
    fontSize: pxToRem(48),
    ...responsiveFontSizes(32, 42 / 32),
  },
  h3: {
    fontWeight: 500,
    lineHeight: 48 / 32,
    fontSize: pxToRem(32),
    ...responsiveFontSizes(24, 36 / 24),
  },
  h4: {
    fontWeight: 500,
    lineHeight: 36 / 24,
    fontSize: pxToRem(24),
    ...responsiveFontSizes(20, 30 / 20),
  },
  h5: {
    fontWeight: 500,
    lineHeight: 30 / 20,
    fontSize: pxToRem(20),
    ...responsiveFontSizes(18, 27 / 18),
  },
  h6: {
    fontWeight: 500,
    lineHeight: 28 / 18,
    fontSize: pxToRem(18),
    ...responsiveFontSizes(17, 26 / 17),
  },
  subtitle1: {
    fontWeight: 500,
    lineHeight: 24 / 16,
    fontSize: pxToRem(16),
  },
  subtitle2: {
    fontWeight: 500,
    lineHeight: 22 / 14,
    fontSize: pxToRem(14),
  },
  body1: {
    lineHeight: 24 / 16,
    fontSize: pxToRem(16),
  },
  body2: {
    lineHeight: 22 / 14,
    fontSize: pxToRem(14),
  },
  caption: {
    lineHeight: 18 / 12,
    fontSize: pxToRem(12),
  },
  overline: {
    fontWeight: 500,
    lineHeight: 18 / 12,
    fontSize: pxToRem(12),
    textTransform: 'uppercase',
  },
  button: {
    fontWeight: 500,
    lineHeight: 26 / 15,
    fontSize: pxToRem(15),
  },
} as const;

export default typography;
