// @mui

import { Skeleton, Stack, useTheme } from '@mui/material';

// ---------------------------------------------------------------------

export default function VisitModalNotesDetailsSkeleton() {
  const theme = useTheme();

  return (
    <Stack
      sx={{
        gap: 1,
        p: 3,
        bgcolor: theme.palette.grey[300],
        borderRadius: 2,
        flexDirection: 'column',
        height: 1,
      }}
    >
      <Skeleton variant="rounded" height="24px" width="160px" sx={{ mb: 1 }} />
      <Skeleton variant="rounded" height="18px" width="100%" />
      <Skeleton variant="rounded" height="18px" width="100%" />
      <Skeleton variant="rounded" height="18px" width="100%" />
      <Skeleton variant="rounded" height="18px" width="100%" />
      <Skeleton variant="rounded" height="18px" width="100%" />
      <Skeleton variant="rounded" height="18px" width="100%" />
      <Skeleton variant="rounded" height="18px" width="100%" />
      <Skeleton variant="rounded" height="18px" width="80%" />
    </Stack>
  );
}
