// @mui
import { Button } from '@mui/material';
import { useState } from 'react';

import { useResponsiveBreakpoints } from 'src/hooks/ueResponsiveBreakpoints';
import { GetProfileDto } from 'woofwoof-api';
import { useProfilesContext } from '../hooks/useProfilesContext';
import ProfileModal from './ProfileModal';

// ----------------------------------------------------------------------

type ProfilesCardMenuProps = {
  profile?: GetProfileDto;
  isSmall?: boolean;
};

export default function ProfileCardEmptyProfileButton({
  profile,
  isSmall,
}: ProfilesCardMenuProps) {
  const { refetch } = useProfilesContext();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const { isXs, isSm, isMd } = useResponsiveBreakpoints();

  const openEditModal = () => {
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
  };

  if (!profile) {
    return null;
  }

  return (
    <>
      <Button
        sx={{ minWidth: isXs || isSm || isMd ? 1 : 'auto' }}
        variant="contained"
        color="error"
        size="small"
        fullWidth={isSmall}
        onClick={openEditModal}
      >
        Uzupełnij profil zwierzaka
      </Button>
      <ProfileModal
        isOpen={isEditModalOpen}
        onClose={closeEditModal}
        profile={profile}
        onSubmited={refetch}
      />
    </>
  );
}
