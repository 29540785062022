// @mui
import { Box, BoxProps } from '@mui/material';
// hooks
import useResponsive from '../../hooks/useResponsive';
// config
import { HEADER, NAV } from '../../config-global';
// components
import { useTheme } from '@mui/material';
import { useUserContext } from 'src/auth/useUserContext';
import { useSettingsContext } from '../../components/settings';

// ----------------------------------------------------------------------

export const SPACING = 8;

export default function Main({ children, sx, ...other }: BoxProps) {
  const { themeLayout } = useSettingsContext();
  const { isUserRole } = useUserContext();
  const theme = useTheme();

  const isNavHorizontal = themeLayout === 'horizontal';

  const isNavMini = themeLayout === 'mini';

  const isDesktop = useResponsive('up', 'lg');

  const paddingTop = isUserRole
    ? HEADER.H_DASHBOARD_DESKTOP_OFFSET + 40
    : HEADER.H_DASHBOARD_DESKTOP_OFFSET + HEADER.H_MOBILE + 40;

  if (isNavHorizontal) {
    return (
      <Box
        component="main"
        sx={{
          display: 'flex',
          flex: 1,
          minHeight: '100vh',
          backgroundColor: theme.palette.background.neutral,
          pt: `${HEADER.H_MOBILE}px`,
          pb: 3,
          ...(isDesktop && {
            px: 2,
            pt: `${paddingTop}px`,
            pb: `40px`,
          }),
        }}
      >
        {children}
      </Box>
    );
  }

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: `${HEADER.H_MOBILE + SPACING}px`,
        ...(isDesktop && {
          px: 2,
          py: `${HEADER.H_DASHBOARD_DESKTOP + SPACING}px`,
          width: `calc(100% - ${NAV.W_DASHBOARD}px)`,
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_DASHBOARD_MINI}px)`,
          }),
        }),
        ...sx,
      }}
      {...other}
    >
      {children}
    </Box>
  );
}
