import { useContext } from 'react';
import { LiveVisitContext } from '../context/LiveVisitContext';
//
// ----------------------------------------------------------------------

export const useLiveVisitContext = () => {
  const context = useContext(LiveVisitContext);

  if (!context)
    throw new Error(
      'useLiveVisitContext context must be used inside LiveVisitProvider',
    );

  return context;
};
