// @mui
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { visitApi } from 'src/api/visit';
import {
  GetVisitById,
  VisitApiVisitControllerCancelVisitRequest,
} from 'woofwoof-api';

// ----------------------------------------------------------------------

type CancelVisitModalProps = {
  isOpen: boolean;
  onClose: () => void;
  visit: GetVisitById;
  refetch: () => void;
};

export default function CancelVisitModal({
  isOpen,
  onClose,
  visit,
  refetch,
}: CancelVisitModalProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (visitId: VisitApiVisitControllerCancelVisitRequest) =>
      visitApi.visitControllerCancelVisit(visitId),
  });

  const handleOnDelete = async () => {
    try {
      await mutateAsync({ visitId: visit.id });
      refetch();
      onClose();
      enqueueSnackbar('E-spotkanie zostało anulowane', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(error.message || error, {
        variant: 'error',
      });
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Próbujesz anulować e-spotkanie</DialogTitle>

      <DialogContent sx={{ maxWidth: '400px' }}>
        <DialogContentText id="alert-dialog-description">
          Czy na pewno chcesz anulować e-spotkanie? Ta akcja jest nieodwracalna
          i spowoduje anulowanie e-spotkania.
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" color="inherit" onClick={onClose}>
          Powrót
        </Button>
        <LoadingButton
          variant="contained"
          color="error"
          onClick={handleOnDelete}
          disabled={isLoading}
          loading={isLoading}
        >
          <span>Potwierdzam</span>
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
