import { VideoRoomMonitor } from '@twilio/video-room-monitor';
import { useCallback, useEffect, useState } from 'react';
import Video, { LocalAudioTrack, LocalVideoTrack, Room } from 'twilio-video';
import { Callback } from '../../../../../../types';
import { isMobile } from '../../../../../../utils/twilio';

// @ts-ignore
window.TwilioVideo = Video;

export default function useRoom(onError: Callback) {
  const [room, setRoom] = useState<Room | null>(null);
  const [isConnecting, setIsConnecting] = useState(false);

  const connect = useCallback(
    (token: string, roomName: string) => {
      setIsConnecting(true);
      return Video.connect(token, {
        name: roomName,
        video: { width: 1280, height: 720 },
        networkQuality: { local: 1, remote: 1 },
        audio: true,
        preferredVideoCodecs: 'auto',
      }).then(
        (newRoom) => {
          setRoom(newRoom);
          VideoRoomMonitor.registerVideoRoom(newRoom);
          const disconnect = () => newRoom.disconnect();

          // This app can add up to 16 'participantDisconnected' listeners to the room object, which can trigger
          // a warning from the EventEmitter object. Here we increase the max listeners to suppress the warning.
          newRoom.setMaxListeners(16);

          newRoom.once('disconnected', () => {
            // Reset the room only after all other `disconnected` listeners have been called.
            setTimeout(() => setRoom(null));
            window.removeEventListener('beforeunload', disconnect);

            if (isMobile) {
              window.removeEventListener('pagehide', disconnect);
            }
          });

          // @ts-ignore
          window.twilioRoom = newRoom;

          newRoom.localParticipant.videoTracks.forEach((publication) =>
            // All video tracks are published with 'low' priority because the video track
            // that is displayed in the 'MainParticipant' component will have it's priority
            // set to 'high' via track.setPriority()
            publication.setPriority('low'),
          );

          setIsConnecting(false);

          // Add a listener to disconnect from the room when a user closes their browser
          window.addEventListener('beforeunload', disconnect);

          if (isMobile) {
            // Add a listener to disconnect from the room when a mobile user closes their browser
            window.addEventListener('pagehide', disconnect);
          }
        },
        (error) => {
          onError(error);
          setIsConnecting(false);
        },
      );
    },
    [onError],
  );

  useEffect(() => {
    return () => {
      if (room?.localParticipant.state === 'connected') {
        room.localParticipant.tracks.forEach((trackPublication) => {
          const track = trackPublication.track as
            | LocalVideoTrack
            | LocalAudioTrack;
          if (trackPublication.track.kind !== 'data') {
            track.stop();
            track.disable();
            trackPublication.unpublish();
          }
        });

        room.disconnect();
        room.removeAllListeners();
      }
    };
  }, [room]);

  return { room, isConnecting, connect };
}
