import { matchPath, useLocation } from 'react-router-dom';

// ----------------------------------------------------------------------

type ReturnType = {
  active: boolean;
  isExternalLink: boolean;
};

export default function useActiveLink(
  path: string,
  deep = true,
  activeForPaths?: string[],
): ReturnType {
  const { pathname } = useLocation();

  const normalActive = path
    ? !!matchPath({ path, end: true }, pathname)
    : false;

  const forPathsActive =
    path && activeForPaths
      ? [path, ...activeForPaths]
          .map((_path) => !!matchPath({ path: _path, end: true }, pathname))
          .some((active) => !!active)
      : false;

  const deepActive = path ? !!matchPath({ path, end: false }, pathname) : false;

  return {
    active: deep ? deepActive || forPathsActive : normalActive,
    isExternalLink: path.includes('http'),
  };
}
