import { useContext } from 'react';
import { MicrophoneAndCameraPermissionsModalContext } from '../context/MicrophoneAndCameraPermissionsModalProvider';
//
// ----------------------------------------------------------------------

export const useMicrophoneAndCameraPermissionsModalContext = () => {
  const context = useContext(MicrophoneAndCameraPermissionsModalContext);

  if (!context)
    throw new Error(
      'useMicrophoneAndCameraPermissionsModalContext context must be used inside MicrophoneAndCameraPermissionsModalProvider',
    );

  return context;
};
