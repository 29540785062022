import { IconButtonProps, Theme } from '@mui/material';

// ----------------------------------------------------------------------

export default function IconButton(theme: Theme) {
  const rootStyle = (ownerState: IconButtonProps) => {
    const smallSize = ownerState.size === 'small';

    const mediumSize = ownerState.size === 'small';

    const largeSize = ownerState.size === 'large';

    const size = {
      ...(smallSize && {
        height: 36,
        width: 36,
      }),
      ...(mediumSize && {
        height: 40,
        width: 40,
      }),
      ...(largeSize && {
        height: 48,
        width: 48,
      }),
    };

    return [size];
  };

  return {
    MuiIconButton: {
      styleOverrides: {
        root: ({ ownerState }: { ownerState: IconButtonProps }) =>
          rootStyle(ownerState),
      },
    },
  };
}
