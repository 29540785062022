import {
  Box,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material';
import { useUserContext } from 'src/auth/useUserContext';

export default function WaitingRoomTableSkeleton() {
  const theme = useTheme();
  const { isProfessionalRole } = useUserContext();
  const BodyRow = (
    <TableRow>
      <TableCell align="left">
        <Box
          sx={{
            gap: theme.spacing(2),
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Skeleton variant="circular" height="40px" width="40px" />
          <Skeleton variant="rounded" height="22px" width="140px" />
        </Box>
      </TableCell>
      <TableCell align="left">
        <Box
          sx={{
            gap: theme.spacing(2),
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Skeleton variant="circular" height="40px" width="40px" />
          <Skeleton variant="rounded" height="22px" width="140px" />
        </Box>
      </TableCell>
      <TableCell align="left">
        <Skeleton variant="rounded" height="24px" width="134px" />
      </TableCell>
      <TableCell align="left">
        <Skeleton variant="rounded" height="24px" width="134px" />
      </TableCell>
    </TableRow>
  );

  return (
    <Stack
      sx={{
        minHeight: '560px',
        height: 1,
      }}
    >
      <Stack
        p={3}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Skeleton variant="rounded" height="28px" width="260px" />
        {isProfessionalRole && (
          <Skeleton variant="rounded" height="32px" width="120px" />
        )}
      </Stack>

      <TableContainer
        sx={{ height: 1, display: 'flex', flexDirection: 'column' }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                align="left"
                sx={{ backgroundColor: theme.palette.grey[300] }}
              >
                <Skeleton variant="rounded" height="24px" width="134px" />
              </TableCell>
              <TableCell
                align="left"
                sx={{ backgroundColor: theme.palette.grey[300] }}
              >
                <Skeleton variant="rounded" height="24px" width="134px" />
              </TableCell>
              <TableCell
                align="left"
                sx={{ backgroundColor: theme.palette.grey[300] }}
              >
                <Skeleton variant="rounded" height="24px" width="134px" />
              </TableCell>
              <TableCell
                align="left"
                sx={{ backgroundColor: theme.palette.grey[300] }}
              >
                <Skeleton variant="rounded" height="24px" width="134px" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {BodyRow}
            {BodyRow}
            {BodyRow}
            {BodyRow}
            {BodyRow}
            {BodyRow}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
}
