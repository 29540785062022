// @mui
import {
  AppBar,
  Box,
  IconButton,
  Stack,
  Toolbar,
  useTheme,
} from '@mui/material';
import { useResponsiveBreakpoints } from 'src/hooks/useResponsiveBreakpoints';

import { HEADER } from '../../config-global';
//

import Image from 'src/components/image/Image';

// ----------------------------------------------------------------------

type Props = {
  onOpenNav?: VoidFunction;
};

export default function Header({ onOpenNav }: Props) {
  const theme = useTheme();

  const renderContent = (
    <>
      <Stack justifyContent="center" alignItems="center">
        <Image
          disabledEffect
          visibleByDefault
          alt="Vetsi Logo"
          src={'/assets/logo/logo_dashboard.svg'}
        />
      </Stack>
      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1.5 }}
      >
        {/* <LanguagePopover /> */}
      </Stack>
    </>
  );

  return (
    <>
      <AppBar
        sx={{
          boxShadow: theme.customShadows.z8,
          height: { xs: HEADER.H_MOBILE, lg: HEADER.H_MAIN_DESKTOP },
          zIndex: theme.zIndex.appBar + 1,
          backgroundColor: theme.palette.background.default,
          position: 'fixed',
        }}
      >
        <Toolbar
          sx={{
            height: 1,
            minHeight: { sm: HEADER.H_MOBILE, md: HEADER.H_MOBILE, lg: 1 },
            px: { sm: 2, lg: 5 },
          }}
        >
          {renderContent}
        </Toolbar>
      </AppBar>
    </>
  );
}
