import { Stack } from '@mui/material';
import { AccountGeneral } from 'src/sections/@dashboard/user/account';

export default function AccountTabsGeneralPage() {
  return (
    <Stack sx={{ height: 1 }}>
      <AccountGeneral />
    </Stack>
  );
}
