import {
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  AudioTrackPublication,
  LocalAudioTrack,
  LocalParticipant,
  LocalTrack,
  LocalVideoTrack,
  RemoteAudioTrack,
  RemoteParticipant,
  RemoteTrack,
  RemoteVideoTrack,
  Track,
  VideoTrackPublication,
} from 'twilio-video';

type ParticipantProps = {
  participant: LocalParticipant | RemoteParticipant;
  isLocalParticipant: boolean;
  NoVideo: ReactNode;
};

export default function Participant({
  participant,
  NoVideo,
  isLocalParticipant,
}: ParticipantProps) {
  const [videoTracks, setVideoTracks] = useState<
    (LocalTrack | RemoteTrack | null)[]
  >([]);
  const [audioTracks, setAudioTracks] = useState<
    (LocalTrack | RemoteTrack | null)[]
  >([]);

  const videoRef: RefObject<HTMLVideoElement> = useRef(null);
  const audioRef = useRef<HTMLAudioElement>();

  const trackpubsToTracks = (
    trackMap:
      | Map<Track.SID, VideoTrackPublication>
      | Map<Track.SID, AudioTrackPublication>,
  ) =>
    Array.from([...(trackMap.values() as any)])
      .map((publication) => publication.track)
      .filter((track) => track !== null);

  const trackSubscribed = useCallback(
    (track: LocalTrack | RemoteTrack) => {
      if (track.kind === 'video') {
        setVideoTracks((videoTracks) => [...videoTracks, track]);
      } else if (track.kind === 'audio') {
        setAudioTracks((audioTracks) => [...audioTracks, track]);
      }
    },
    [setVideoTracks, setAudioTracks],
  );

  const trackUnsubscribed = useCallback(
    (track: LocalTrack | RemoteTrack) => {
      if (track.kind === 'video') {
        setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
      } else if (track.kind === 'audio') {
        setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
      }
    },
    [setVideoTracks, setAudioTracks],
  );

  useEffect(() => {
    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));

    participant.on('trackSubscribed', trackSubscribed);
    participant.on('trackUnsubscribed', trackUnsubscribed);

    participant.on('trackPublished', (track) => {
      if (track?.track?.kind === 'video') {
        trackSubscribed(track.track);
      }
    });
    participant.on('trackStopped', (track) => {
      if (track?.kind === 'video') {
        trackUnsubscribed(track);
      }
    });

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);

      participant.removeAllListeners('trackSubscribed');
      participant.removeAllListeners('trackUnsubscribed');
      participant.removeAllListeners('trackStopped');
      participant.removeAllListeners('trackPublished');
    };
  }, [participant, trackSubscribed, trackUnsubscribed]);

  useEffect(() => {
    const videoTrack = videoTracks[0] as LocalVideoTrack | RemoteVideoTrack;

    if (videoTrack && videoRef.current) {
      videoTrack.attach(videoRef.current);

      return () => {
        videoTrack.detach();
      };
    }
  }, [videoTracks]);

  useEffect(() => {
    const audioTrack = audioTracks[0] as LocalAudioTrack | RemoteAudioTrack;

    if (audioTrack) {
      audioRef.current = audioTrack.attach();
      document.body.appendChild(audioRef.current);
      return () =>
        audioTrack.detach().forEach((el) => {
          el.remove();

          // This addresses a Chrome issue where the number of WebMediaPlayers is limited.
          // See: https://github.com/twilio/twilio-video.js/issues/1528
          el.srcObject = null;
        });
    }
  }, [audioTracks]);

  return videoTracks.length > 0 ? (
    <video
      ref={videoRef}
      controls={false}
      muted={true}
      style={{
        height: '100%',
        width: '100%',
        objectFit: 'contain',
        transform: isLocalParticipant ? 'scaleX(-1)' : '',
      }}
    />
  ) : (
    <>{NoVideo}</>
  );
}
