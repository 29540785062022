import { Skeleton, Stack, useTheme } from '@mui/material';

// ---------------------------------------------------------------------

export const FileRow = (
  <Stack sx={{ flexDirection: 'row', gap: 1.5, alignItems: 'center' }}>
    <Skeleton
      variant="rounded"
      height="40px"
      width="40px"
      sx={{ borderRadius: 1 }}
    />
    <Skeleton variant="rounded" height="18px" width="250px" />
  </Stack>
);

export default function VisitDetailsSkeleton() {
  const theme = useTheme();

  return (
    <Stack
      sx={{
        gap: 1,
        backgroundColor: theme.palette.background.default,
        borderRadius: 2,
        boxShadow: theme.customShadows.z8,
      }}
    >
      <Stack
        sx={{
          borderTopRightRadius: theme.spacing(2),
          borderTopLeftRadius: theme.spacing(2),
          gap: 0.5,
          paddingX: 3,
          paddingY: 2,
          backgroundColor: theme.palette.grey[300],
        }}
      >
        <Skeleton variant="rounded" width={165} height={28} />
        <Skeleton variant="rounded" width={350} height={20} />
      </Stack>
      <Stack
        sx={{
          paddingX: 3,
          paddingY: 2,
          paddingTop: 1,
          gap: 2,
        }}
      >
        <Stack sx={{ gap: 1 }}>
          <Stack sx={{ gap: 1 }}>
            <Skeleton
              variant="rounded"
              height="20px"
              width="120px"
              sx={{ mb: 1 }}
            />
            <Skeleton variant="rounded" height="18px" width="100%" />
            <Skeleton variant="rounded" height="18px" width="100%" />
            <Skeleton variant="rounded" height="18px" width="100%" />
            <Skeleton variant="rounded" height="18px" width="80%" />
          </Stack>
        </Stack>
        <Stack sx={{ gap: 1 }}>
          <Skeleton
            variant="rounded"
            height="20px"
            width="120px"
            sx={{ mb: 1 }}
          />
          <Stack sx={{ gap: 1 }}>
            <Skeleton
              variant="rounded"
              height="24px"
              width="120px"
              sx={{ mb: 1 }}
            />
            {FileRow}
            {FileRow}
            {FileRow}
            {FileRow}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
