import { createContext, useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';
import { profileApi } from 'src/api/profile';
import { GetProfileDto } from 'woofwoof-api';

// ----------------------------------------------------------------------

export type ProfilesContextType = {
  data?: GetProfileDto[];
  refetch: () => void;
  isLoading: boolean;
  isError: boolean;
};

export const ProfilesContext = createContext<ProfilesContextType | null>(null);

type ProfilesProviderProps = {
  children: React.ReactNode;
};

export function ProfilesProvider({ children }: ProfilesProviderProps) {
  const { refetch, data, isLoading, isError } = useQuery(
    ['profiles'],
    () => profileApi.profileControllerGetAllUserProfiles(),
    {
      retry: 1,
    },
  );

  const memoizedValue = useMemo(
    () => ({
      data: data?.data,
      isLoading,
      isError,
      refetch,
    }),
    [data, isLoading, isError, refetch],
  );

  return (
    <ProfilesContext.Provider value={memoizedValue}>
      {children}
    </ProfilesContext.Provider>
  );
}
