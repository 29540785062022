import { Box, Stack, useTheme } from '@mui/material';
import Iconify from 'src/components/iconify';

export default function ConversationHeader() {
  const theme = useTheme();

  return (
    <Stack sx={{ p: 2 }}>
      <Box
        className="drag-handle"
        sx={{
          width: '40px',
          height: '40px',
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: theme.palette.grey[300],
          cursor: 'move',
          zIndex: '2',
        }}
      >
        <Iconify icon="ic:baseline-drag-indicator" />
      </Box>
    </Stack>
  );
}
