import { Box, useTheme } from '@mui/material';
import Iconify from 'src/components/iconify';
import useParticipants from '../hooks/useParticipants';
import useVideoContext from '../hooks/useVideoContext';
import Participant from './Participant';

export default function ParticipantList() {
  const theme = useTheme();
  const { room } = useVideoContext();
  const participants = useParticipants();
  const localParticipant = room!.localParticipant;

  return !!participants.length ? (
    <Box
      sx={{
        maxWidth: '199px',
        width: '35%',
        position: 'absolute',
        right: '8px',
        top: '8px',
        borderRadius: '16px',
        backgroundColor: theme.palette.grey[800],
        maxHeight: '112px',
        height: '27%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
      }}
    >
      <Participant
        participant={localParticipant}
        isLocalParticipant={true}
        NoVideo={
          <Iconify icon="eva:video-off-fill" color="#F4ECE5" width="24px" />
        }
      />
    </Box>
  ) : null;
}
