// @mui
import { Button } from '@mui/material';
import Iconify from 'src/components/iconify';
import { GetVisitById, PaymentStatusEnum } from 'woofwoof-api';

// ---------------------------------------------------------------------

type UserUpcomingVisitCardUploadButtonProps = {
  visit: GetVisitById;
  onUploadFiles: (visitId: string) => void;
};

export default function UserUpcomingVisitCardUploadButton({
  visit,
  onUploadFiles,
}: UserUpcomingVisitCardUploadButtonProps) {
  const isPaymentSuccess =
    visit?.payment?.status === PaymentStatusEnum.Verified;

  const handleOnUploadFilesClick = () => onUploadFiles(visit.id);

  return (
    <Button
      fullWidth
      variant="soft"
      color="inherit"
      size="large"
      startIcon={<Iconify icon="eva:cloud-upload-fill" />}
      onClick={handleOnUploadFilesClick}
      disabled={!isPaymentSuccess}
    >
      Dodaj załączniki
    </Button>
  );
}
