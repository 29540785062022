import { Stack } from '@mui/material';
import VisitAIAssistant from 'src/sections/@dashboard/visit/components/VisitAIAssistant';
import VisitAIAssistantSkeleton from 'src/sections/@dashboard/visit/components/VisitAIAssistantSkeleton';
import VisitNotes from 'src/sections/@dashboard/visit/components/VisitNotes';
import VisitNotesSkeleton from 'src/sections/@dashboard/visit/components/VisitNotesSkeleton';
import { useCurrentVisitContext } from 'src/sections/@dashboard/visit/hooks/useCurrentVisitContext';
import ScreenSizeGuard from 'src/auth/ScreenSizeGuard';
import { Roles } from 'src/auth/ScreenSizeGuard';

export default function ProfileTabsNotesPage() {
  const { isLoading } = useCurrentVisitContext();

  return (
    <ScreenSizeGuard roles={[Roles.professional]} smallerThan="md">
      <Stack sx={{ height: 1, gap: 3 }}>
        {!isLoading ? <VisitNotes /> : <VisitNotesSkeleton />}
        {!isLoading ? <VisitAIAssistant /> : <VisitAIAssistantSkeleton />}
      </Stack>
    </ScreenSizeGuard>
  );
}
