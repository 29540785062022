import { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';
// hooks
import { useResponsiveBreakpoints } from 'src/hooks/useResponsiveBreakpoints';
import useResponsive from '../../hooks/useResponsive';
// components
import { useSettingsContext } from '../../components/settings';
//
import { useUserContext } from 'src/auth/useUserContext';
import { PATH_DASHBOARD } from '../../routes/paths';
import Main from './Main';
import Header from './header';
import NavHorizontal from './nav/NavHorizontal';
import NavVertical from './nav/NavVertical';

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const { themeLayout } = useSettingsContext();
  const { pathname } = useLocation();
  const { isUserRole } = useUserContext();
  const isUserRoleDefined = isUserRole !== undefined;
  const { isMd } = useResponsiveBreakpoints();
  const isDesktop = useResponsive('up', 'lg');

  const [open, setOpen] = useState(false);

  const isNavHorizontal = themeLayout === 'horizontal';

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderNavVertical = (
    <NavVertical openNav={open} onCloseNav={handleClose} />
  );

  if (isNavHorizontal && isMd) {
    return (
      <>
        {isUserRoleDefined && <Header onOpenNav={handleOpen} />}
        {pathname !== PATH_DASHBOARD.general.updateAccount ? (
          isUserRoleDefined && !isUserRole ? (
            isDesktop ? (
              <NavHorizontal />
            ) : (
              renderNavVertical
            )
          ) : null
        ) : null}
        <Main>
          <Outlet />
        </Main>
      </>
    );
  }
  return (
    <>
      {isUserRoleDefined && <Header onOpenNav={handleOpen} />}

      {pathname !== PATH_DASHBOARD.general.updateAccount ? (
        isUserRoleDefined && !isUserRole ? (
          <Box sx={{ paddingBottom: { md: 9, lg: 0, xl: 0 } }}>
            <NavHorizontal />
          </Box>
        ) : null
      ) : null}

      <Main>
        <Outlet />
      </Main>
    </>
  );
}
