// @mui
import { Link, Stack, Typography } from '@mui/material';
// layouts
import LoginLayout from '../../layouts/login';
//
import { useTheme } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { PATH_AUTH } from '../../routes/paths';
import AuthLoginForm from './AuthLoginForm';
import AuthWithSocial from './AuthWithSocial';

// ----------------------------------------------------------------------

export default function Login() {
  const theme = useTheme();

  return (
    <LoginLayout
      title={
        <Typography
          variant="h2"
          sx={{ maxWidth: 600, textAlign: 'center', marginTop: 4 }}
        >
          Umów{' '}
          <span style={{ color: theme.palette.primary.main }}>
            swojego zwierzaka
          </span>{' '}
          na e-spotkanie online!
        </Typography>
      }
    >
      <Stack spacing={2} sx={{ mb: 5, position: 'relative' }}>
        <Typography variant="h4">Zaloguj się do Vetsi</Typography>
        <Typography variant="body2">
          Nie masz konta?{' '}
          <Link component={RouterLink} to={PATH_AUTH.register} color="primary">
            Zarejestruj się
          </Link>
        </Typography>
      </Stack>
      <AuthWithSocial />
      <AuthLoginForm />
    </LoginLayout>
  );
}
