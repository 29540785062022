import { useState } from 'react';
import AddFirstProfile from 'src/sections/@dashboard/profile/components/AddFirstProfile';
import ProfilesCards from 'src/sections/@dashboard/profile/components/ProfilesCards';
import RedirectModal from 'src/sections/@dashboard/profile/components/RedirectModal';
import { useProfilesContext } from 'src/sections/@dashboard/profile/hooks/useProfilesContext';

export default function ProfilesTabsListPage() {
  const { data, isLoading } = useProfilesContext();
  const [isRedirectModalOpen, setIsRedirectModalOpen] = useState(false);
  const newProfileId = data && data[data.length - 1]?.id;

  const openRedirectModal = () => {
    setIsRedirectModalOpen(true);
  };

  const closeRedirectModal = () => {
    setIsRedirectModalOpen(false);
  };

  if (!data?.length && !isLoading) {
    return <AddFirstProfile openRedirectModal={openRedirectModal} />;
  }

  return (
    <>
      <ProfilesCards />
      <RedirectModal
        isOpen={isRedirectModalOpen && Boolean(newProfileId)}
        onClose={closeRedirectModal}
        profileId={newProfileId || ''}
      />
    </>
  );
}
