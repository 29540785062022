import { Paper, Stack, Typography, useTheme } from '@mui/material';
import { useVisitsContext } from '../hooks/useVisitsContext';
import UpcomingVisitsTable from './UpcomingVisitsTable';
import WaitingRoomTableSkeleton from './WaitingRoomTableSkeleton';

export default function UpcomingVisitsContent() {
  const theme = useTheme();
  const { isLoading } = useVisitsContext();

  return (
    <Stack
      spacing={4}
      sx={{
        flex: 1,
        height: 1,
        width: '100% !important',
        maxWidth: '1080px !important',
        px: { xs: 2, sm: 2, md: 2, lg: 3, xl: 0 },
        py: { xs: 1, sm: 1, md: 1, lg: 0, xl: 0 },
      }}
    >
      <Paper
        sx={{
          borderRadius: 2,
          boxShadow: theme.customShadows.z8,
          height: 1,
        }}
      >
        {!isLoading ? (
          <Stack sx={{ minHeight: '560px', height: 1 }}>
            <Stack p={3}>
              <Typography variant="h6">Nadchodzące e-spotkania</Typography>
            </Stack>
            <UpcomingVisitsTable />
          </Stack>
        ) : (
          <WaitingRoomTableSkeleton />
        )}
      </Paper>
    </Stack>
  );
}
