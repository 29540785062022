// @mui
import {
  Box,
  Grid,
  Stack,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import Markdown from 'src/components/markdown';
import { GetVisitById, GetVisitByIdStatusEnum } from 'woofwoof-api';
import { getFileIcon } from '../../profile/components/ProfileFilesTable';

type VisitModalDetailsTabsProps = {
  visit: GetVisitById;
};

export default function VisitModalDetailsTabs({
  visit,
}: VisitModalDetailsTabsProps) {
  const theme = useTheme();
  const [value, setValue] = useState(1);
  const isVisitCompleted = visit.status === GetVisitByIdStatusEnum.Completed;

  const PROFESSIONAL_TABS = !isVisitCompleted
    ? [
        {
          value: 2,
          label: 'Analiza AI',
          disabled: false,
        },
      ]
    : [];

  const USER_TABS = [
    {
      value: 1,
      label: 'Dolegliwości',
      disabled: false,
    },
    ...PROFESSIONAL_TABS,
  ];

  return (
    <Stack sx={{ gap: 2 }}>
      <Grid item xs={12}>
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            borderWidth: '1px',
            borderColor: theme.palette.divider,
            borderBottomStyle: 'solid',
            gap: 2,
            mx: { xs: 0, sm: 0, md: 2, lg: 0 },
          }}
        >
          <Tabs value={value} onChange={(_, newValue) => setValue(newValue)}>
            {USER_TABS.map((tab) => (
              <Tab
                key={tab.value}
                label={tab.label}
                value={tab.value}
                disabled={tab.disabled}
                sx={{ fontSize: 16 }}
              />
            ))}
          </Tabs>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        {value === 1 && (
          <Stack
            sx={{
              gap: 2,
              height: 1,
            }}
          >
            <Stack sx={{ gap: 1 }}>
              <Typography color="text.secondary">
                {visit.description}
              </Typography>
            </Stack>
            {visit?.documents && visit?.documents?.length > 0 && (
              <Stack sx={{ gap: 1 }}>
                <Typography>Załączniki</Typography>
                <Stack sx={{ gap: 2 }}>
                  {(visit.documents as any[])?.map((file, index) => (
                    <Stack
                      key={index}
                      sx={{
                        gap: 1.5,
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <Box
                        sx={{
                          padding: 1,
                          borderRadius: 1,
                          backgroundColor: theme.palette.background.neutral,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {getFileIcon(file.filename)}
                      </Box>
                      <Typography
                        sx={{
                          '&:hover': {
                            cursor: 'pointer',
                            textDecoration: 'underline',
                          },
                        }}
                        noWrap
                        onClick={() => window.open(file.url, '_blank')}
                      >
                        {file.filename.split('_')[1]}
                      </Typography>
                    </Stack>
                  ))}
                </Stack>
              </Stack>
            )}
          </Stack>
        )}
        {value === 2 && (
          <Stack
            sx={{
              height: 1,
            }}
          >
            <Typography color="text.secondary">
              <Markdown>
                {visit.aiNote
                  ? visit.aiNote
                  : 'Brak informacji od asystenta AI'}
              </Markdown>
            </Typography>
          </Stack>
        )}
      </Grid>
    </Stack>
  );
}
