import { Box } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { HEADER } from 'src/config-global';
import { SPACING } from 'src/layouts/dashboard/Main';
import LiveVisitContent from 'src/sections/@dashboard/visit/components/LiveVisitContent';
import ScreenSizeGuard from 'src/auth/ScreenSizeGuard';
import { Roles } from 'src/auth/ScreenSizeGuard';
import { LiveVisitProvider } from 'src/sections/@dashboard/visit/context/LiveVisitContext';
// components
// ----------------------------------------------------------------------

export default function LiveVisitPage() {
  return (
    <>
      <ScreenSizeGuard roles={[Roles.professional]} smallerThan="md">
        <Helmet>
          <title> E-spotkanie | Vetsi</title>
        </Helmet>

        <Box
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            pt: `${HEADER.H_MOBILE + SPACING}px`,
          }}
        >
          <LiveVisitProvider>
            <LiveVisitContent />
          </LiveVisitProvider>
        </Box>
      </ScreenSizeGuard>
    </>
  );
}
