import { Stack, Typography, useTheme, Tabs, Tab, Box } from '@mui/material';
import { useState } from 'react';
import Editor from 'src/components/editor';
import Label from 'src/components/label';
import { useCurrentVisitContext } from '../hooks/useCurrentVisitContext';
import { LIGHT_TEXT } from 'src/theme/palette';

// ---------------------------------------------------------------------
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
export default function VisitNotes() {
  const theme = useTheme();
  const { setVisitFlow, visitFlow } = useCurrentVisitContext();
  const textWithoutTags = visitFlow.notes.replace(/<(.|\n)*?>/g, '').trim();
  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Stack
      sx={{
        gap: 1,
        backgroundColor: theme.palette.background.default,
        borderRadius: 2,
        boxShadow: theme.customShadows.z8,
      }}
    >
      <Stack
        sx={{
          borderTopRightRadius: theme.spacing(2),
          borderTopLeftRadius: theme.spacing(2),
          gap: 0.5,
          paddingX: 3,
          paddingY: 2,
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <Stack
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h6">Notatki</Typography>
          {!textWithoutTags.length ? (
            <Label color="error">Obowiązkowe</Label>
          ) : (
            <Label color="success">Gotowe</Label>
          )}
        </Stack>
        <Typography variant="body2" color="text.secondary">
          Miejsce do sporządzania notatek dotyczących e-spotkania.
        </Typography>
      </Stack>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          sx={{ px: 2 }}
          value={value}
          onChange={handleChange}
          aria-label="notes tabs"
        >
          <Tab label="Dla pacjenta" {...a11yProps(0)} />
          <Tab label="Wewnętrzne" {...a11yProps(1)} />
        </Tabs>
      </Box>

      <CustomTabPanel value={value} index={0}>
        <Stack
          sx={{
            paddingX: 3,
            paddingY: 2,
            paddingTop: 1,
            gap: 2,
          }}
        >
          <Editor
            simple
            id="simple-editor"
            value={visitFlow.notes}
            onChange={(_value) => {
              if (_value.replace(/<(.|\n)*?>/g, '').trim()) {
                setVisitFlow((prev) => ({ ...prev, notes: _value }));
              } else {
                setVisitFlow((prev) => ({ ...prev, notes: '' }));
              }
            }}
          />
          <Typography sx={{ color: LIGHT_TEXT.secondary }} variant="caption">
            Notatki będą widoczne dla pacjenta
          </Typography>
        </Stack>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Stack
          sx={{
            paddingX: 3,
            paddingY: 2,
            paddingTop: 1,
            gap: 2,
          }}
        >
          <Editor
            simple
            id="simple-editor"
            sx={{ backgroundColor: 'warning.lightest' }}
            value={visitFlow.internalNotes}
            onChange={(_value) => {
              if (_value.replace(/<(.|\n)*?>/g, '').trim()) {
                setVisitFlow((prev) => ({ ...prev, internalNotes: _value }));
              } else {
                setVisitFlow((prev) => ({ ...prev, internalNotes: '' }));
              }
            }}
          />
        </Stack>
      </CustomTabPanel>
    </Stack>
  );
}
