import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { format } from 'date-fns';
import { ReactNode } from 'react';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { TablePaginationCustom, useTable } from 'src/components/table';
import Image from '../../../../components/image';

interface Row {
  id: string;
  name: string;
  url: string;
  date: string;
}

interface Column {
  id: keyof Row;
  label: string;
  minWidth?: number;
  align?: 'left';
  render?: (row: Row) => ReactNode;
}

//TODO: Fix type
export type ProfileFilesTableProps = { data: any[] };

export const getFileIcon = (name: string) => {
  const ext = name.split('.').slice(-1)[0];

  if (ext === 'pdf') {
    return <Iconify icon="bxs:file-pdf" width="24px" color="error.dark" />;
  }

  if (ext === 'jpg' || ext === 'png' || ext === 'jpeg') {
    return <Iconify icon="bxs:file-image" width="24px" color="primary.main" />;
  }

  return <Iconify icon="bxs:file-blank" width="24px" />;
};

export default function ProfileFilesTable({ data }: ProfileFilesTableProps) {
  const theme = useTheme();

  const handleOnRowClick = (url: string) => {
    window.open(url, '_blank');
  };

  const tableData: Row[] = data.map((document) => ({
    id: document.id,
    name: document.filename,
    url: document.url,
    date: document.createdAt,
  }));

  const COLUMNS: Column[] = [
    {
      id: 'name',
      label: 'Nazwa pliku',
      render: (value: Row) => (
        <Box
          sx={{
            gap: theme.spacing(2),
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {getFileIcon(value.name)}
          <Typography variant="body2">{value.name.split('_')[1]}</Typography>
        </Box>
      ),
    },
    {
      id: 'date',
      label: 'Data',
      render: (value: Row) => format(new Date(value.date), 'dd/MM/yyyy'),
    },
  ];

  const { page, rowsPerPage, onChangePage, onChangeRowsPerPage } = useTable({
    defaultRowsPerPage: 25,
  });

  const TableHeader = (
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          {COLUMNS.map((column) => (
            <TableCell
              key={column.id}
              align={column.align}
              style={{ minWidth: column.minWidth }}
            >
              {column.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </Table>
  );

  const FullTable = (
    <Box sx={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}>
      <Scrollbar>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {COLUMNS.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, rowIndex) => (
                <TableRow
                  hover
                  key={rowIndex}
                  onClick={() => handleOnRowClick(row.url)}
                >
                  {COLUMNS.map((column, index) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.render ? column.render(row) : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Scrollbar>
    </Box>
  );
  return (
    <>
      <TableContainer
        sx={{ height: 1, display: 'flex', flexDirection: 'column' }}
      >
        {tableData.length > 0 ? (
          FullTable
        ) : (
          <>
            {TableHeader}
            <Stack
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              flex="1"
              gap={2}
            >
              <Image
                sx={{ width: '200px' }}
                alt="empty waiting room"
                src="/assets/images/empty_table.svg"
              />
              <Typography variant="h6">Brak plików</Typography>
            </Stack>
          </>
        )}
      </TableContainer>
      <TablePaginationCustom
        count={tableData.length}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
      />
    </>
  );
}
