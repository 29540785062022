import { createContext, useEffect, useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';
import { PATH_AUTH } from 'src/routes/paths';
import { userApi } from '../api/user';
import { UserContextType } from './types';
import { useAuthContext } from './useAuthContext';

// ----------------------------------------------------------------------

export const UserContext = createContext<UserContextType | null>(null);

type UserProviderProps = {
  children: React.ReactNode;
};

export function UserProvider({ children }: UserProviderProps) {
  const { logout } = useAuthContext();
  const { isAuthenticated } = useAuthContext();
  const { refetch, data, isFetching, isError } = useQuery(
    ['user'],
    () => userApi.userControllerGetCurrentUser(),
    {
      enabled: false,
      retry: 3,
      retryDelay: 2000,
    },
  );

  useEffect(() => {
    if (isAuthenticated && isError) {
      logout();
      window.location.replace(PATH_AUTH.login);
    }
  }, [isError, isAuthenticated, logout]);

  useEffect(() => {
    if (isAuthenticated) {
      refetch();
    }
  }, [isAuthenticated, refetch]);

  const memoizedValue = useMemo(() => {
    const hasUserRole = data?.data?.roles?.includes('USER');
    const hasProfessionalRole = data?.data?.roles?.includes('PROFESSIONAL');

    return {
      data: data?.data,
      isLoading: isFetching,
      isUserRole: hasUserRole && !hasProfessionalRole,
      isProfessionalRole: hasProfessionalRole,
      isError,
      refetch,
    };
  }, [data, isFetching, isError, refetch]);

  return (
    <UserContext.Provider value={memoizedValue}>
      {children}
    </UserContext.Provider>
  );
}
