import { useContext } from 'react';
import { ConversationModalContext } from '../context/ConversationModalContext';
//
// ----------------------------------------------------------------------

export const useConversationModalContext = () => {
  const context = useContext(ConversationModalContext);

  if (!context)
    throw new Error(
      'useConversationModalContext context must be used inside ConversationModalProvider',
    );

  return context;
};
