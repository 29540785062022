// @mui
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { GetProfileDto } from 'woofwoof-api';

// ----------------------------------------------------------------------

type RedirectModalProps = {
  isOpen: boolean;
  onClose: () => void;
  profileId: GetProfileDto['id'];
};

export default function RedirectModal({
  isOpen,
  onClose,
  profileId,
}: RedirectModalProps) {
  const navigate = useNavigate();

  const navigateToBooking = () =>
    navigate(PATH_DASHBOARD.general.booking.schedule(profileId));

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Profil został utworzony</DialogTitle>

      <DialogContent sx={{ maxWidth: '400px' }}>
        <DialogContentText id="alert-dialog-description">
          Czy chcesz aby przkierować Cię na umówienie e-spotkania dla dodanego
          zwierzaka?
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button variant="soft" color="inherit" onClick={onClose}>
          Pomiń
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          onClick={navigateToBooking}
        >
          <span>Umów wizytę</span>
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
