import { Helmet } from 'react-helmet-async';
// sections
import Verification from 'src/sections/auth/Verification';

// ----------------------------------------------------------------------

export default function VerificationPage() {
  return (
    <>
      <Helmet>
        <title> Weryfikacja | Vetsi</title>
      </Helmet>

      <Verification />
    </>
  );
}
