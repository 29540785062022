// @mui
import { IconButton, Menu, MenuItem, useTheme } from '@mui/material';
import { useState } from 'react';
import Iconify from 'src/components/iconify';
import { GetProfileDto } from 'woofwoof-api';
import { useProfilesContext } from '../hooks/useProfilesContext';
import DeleteProfileModal from './DeleteProfileModal';
import ProfileModal from './ProfileModal';

// ----------------------------------------------------------------------

type ProfilesCardMenuProps = {
  buttonBackground?: boolean;
  profile?: GetProfileDto;
};

export default function ProfilesCardMenu({
  profile,
  buttonBackground,
}: ProfilesCardMenuProps) {
  const theme = useTheme();
  const { refetch } = useProfilesContext();
  const [isOpen, setOpen] = useState<null | HTMLElement>(null);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const openDeleteDialog = () => {
    setDeleteModalOpen(true);
    handleClose();
  };

  const closeDeleteDialog = () => {
    setDeleteModalOpen(false);
  };

  const openEditModal = () => {
    setIsEditModalOpen(true);
    handleClose();
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
  };

  const menuOptions = [
    {
      label: 'Edytuj',
      onClick: openEditModal,
    },
    {
      label: 'Usuń',
      onClick: openDeleteDialog,
    },
  ];

  if (!profile) {
    return null;
  }

  return (
    <>
      <IconButton
        size="large"
        onClick={handleOpen}
        sx={
          buttonBackground
            ? {
                background: theme.palette.common.black,
                color: theme.palette.common.white,
                '&:hover': {
                  background: theme.palette.primary.dark,
                },
              }
            : undefined
        }
      >
        <Iconify icon="ic:round-more-vert"></Iconify>
      </IconButton>
      <Menu
        keepMounted
        id="simple-menu"
        anchorEl={isOpen}
        onClose={handleClose}
        open={Boolean(isOpen)}
        anchorOrigin={{ vertical: 'bottom', horizontal: -90 }}
      >
        {menuOptions.map((option) => (
          <MenuItem
            key={option.label}
            onClick={option.onClick}
            sx={{ minWidth: '140px' }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
      <ProfileModal
        isOpen={isEditModalOpen}
        onClose={closeEditModal}
        profile={profile}
        onSubmited={refetch}
      />
      <DeleteProfileModal
        profile={profile}
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteDialog}
        refetch={refetch}
      />
    </>
  );
}
