// @mui
import { enUS, plPL, ukUA } from '@mui/material/locale';

// PLEASE REMOVE `LOCAL STORAGE` WHEN YOU CHANGE SETTINGS.
// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: 'Polski',
    value: 'pl',
    systemValue: plPL,
    icon: '/assets/icons/flags/ic_flag_pl.svg',
  },
  {
    label: 'Українська',
    value: 'uk',
    systemValue: ukUA,
    icon: '/assets/icons/flags/ic_flag_uk.svg',
  },
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: '/assets/icons/flags/ic_flag_en.svg',
  },
];

export const defaultLang = allLangs[0]; // English
