import { Stack } from '@mui/system';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { TwilioError } from 'twilio-video';
import LiveVisitFooter from '../../conversation/components/LiveVisitFooter';
import LiveVisitHeader from '../../conversation/components/LiveVisitHeader';
import LiveVisitVideo from '../../conversation/components/LiveVisitVideo';
import { ParticipantProvider } from '../../conversation/context/ParticipantProvider';
import { VideoProvider } from '../../conversation/context/VideoProvider';
import { useMicrophoneAndCameraPermissionsModalContext } from '../../conversation/hooks/useMicrophoneAndCameraPermissionsModalContext';
import { useLiveVisitContext } from '../hooks/useLiveVisitContext';

export default function LiveVisitContent() {
  const { isError } = useLiveVisitContext();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { openModal } = useMicrophoneAndCameraPermissionsModalContext();

  useEffect(() => {
    if (isError) {
      enqueueSnackbar('Nie mogliśmy pobrać szczegółów rozmowy', {
        variant: 'error',
      });
      navigate(PATH_DASHBOARD.general.profiles.list);
    }
  }, [isError, enqueueSnackbar, navigate]);

  const handleOnError = useCallback(
    (err: TwilioError | Error) => {
      if (err) {
        openModal();
      }
      navigate(PATH_DASHBOARD.root);

      throw new Error(
        JSON.stringify({
          message: err.message,
          code: (err as TwilioError).code,
        }),
      );
    },
    [navigate, openModal],
  );

  return (
    <VideoProvider onError={handleOnError}>
      <ParticipantProvider>
        <Stack sx={{ flex: 1, height: '100%' }}>
          <LiveVisitHeader />
          <LiveVisitVideo />
          <LiveVisitFooter />
        </Stack>
      </ParticipantProvider>
    </VideoProvider>
  );
}
