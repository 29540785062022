import { IconButton, useTheme } from '@mui/material';
import { useCallback, useState } from 'react';
import Iconify from 'src/components/iconify';
import useDevices from '../hooks/useDevices';
import useVideoContext from '../hooks/useVideoContext';

export default function ToggleAudioButton(props: {
  disabled?: boolean;
  className?: string;
}) {
  const theme = useTheme();
  const { room } = useVideoContext();
  const localParticipant = room?.localParticipant;
  const audioTrack = localParticipant
    ? Array.from(localParticipant?.audioTracks.values())?.find(
        (track) => track.kind === 'audio',
      )
    : undefined;
  const { hasAudioInputDevices } = useDevices();
  const [audioState, setAudioState] = useState({
    audio: audioTrack?.isTrackEnabled ?? true,
    enabled: true,
  });

  const turnOnMic = useCallback(() => {
    if (!localParticipant || audioState.audio) {
      return;
    }

    localParticipant.audioTracks.forEach((publication) => {
      publication.track.enable();
    });

    setAudioState(() => ({ enabled: false, audio: true }));
    setTimeout(
      () => setAudioState((state) => ({ ...state, enabled: true })),
      500,
    );
  }, [localParticipant, audioState.audio]);

  const turnOffMic = useCallback(() => {
    if (!localParticipant || !audioState.audio) {
      return;
    }

    localParticipant.audioTracks.forEach((publication) => {
      publication.track.disable();
    });

    setAudioState(() => ({ enabled: false, audio: false }));
    setTimeout(
      () => setAudioState((state) => ({ ...state, enabled: true })),
      500,
    );
  }, [localParticipant, audioState.audio]);

  return (
    <IconButton
      size="small"
      onClick={audioState.audio ? turnOffMic : turnOnMic}
      disabled={!hasAudioInputDevices || props.disabled || !audioState.enabled}
      sx={{
        backgroundColor: audioState.audio
          ? theme.palette.text.primary
          : theme.palette.error.main,
        ':hover': {
          backgroundColor: audioState.audio
            ? theme.palette.text.secondary
            : theme.palette.error.light,
        },
        '&:disabled': {
          backgroundColor: theme.palette.action.disabledBackground,
        },
      }}
    >
      {audioState.audio ? (
        <Iconify icon="eva:mic-fill" color="#F4ECE5" />
      ) : (
        <Iconify icon="eva:mic-off-fill" color={theme.palette.text.primary} />
      )}
    </IconButton>
  );
}
