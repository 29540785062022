import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
export function useResponsiveBreakpoints() {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isMd = useMediaQuery(theme.breakpoints.down('md'));
  const isLg = useMediaQuery(theme.breakpoints.down('lg'));
  const isXl = useMediaQuery(theme.breakpoints.down('xl'));
  const isTabletSm = useMediaQuery(theme.breakpoints.down('tabletSm'));
  const isTabletMd = useMediaQuery(theme.breakpoints.down('tabletMd'));
  const isTabletLg = useMediaQuery(theme.breakpoints.down('tabletLg'));
  return { isXs, isSm, isMd, isLg, isXl, isTabletLg, isTabletMd, isTabletSm };
}
