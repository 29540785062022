import { Box, IconButton, Stack, useTheme } from '@mui/material';
import Iconify from 'src/components/iconify';
import { useConversationModalContext } from '../hooks/useConversationModalContext';
import useRoomState from '../hooks/useRoomState';
import ToggleAudioButton from './ToggleAudioButton';
import ToggleVideoButton from './ToggleVideoButton';

export default function ConversationModalFooter() {
  const {
    state: { maximize },
    onMaximize,
    onMinimize,
    closeModal,
  } = useConversationModalContext();
  const roomState = useRoomState();
  const isReconnecting = roomState === 'reconnecting';
  const theme = useTheme();

  const endConversation = () => {
    closeModal();
  };

  return (
    <Stack sx={{ p: 2, flexDirection: 'row', justifyContent: 'space-between' }}>
      <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-start' }}>
        <IconButton
          onClick={() => (maximize ? onMinimize() : onMaximize())}
          size="small"
          sx={{ backgroundColor: theme.palette.grey[300] }}
        >
          <Iconify
            icon={
              maximize
                ? 'fluent:full-screen-minimize-24-filled'
                : 'fluent:full-screen-maximize-24-filled'
            }
          />
        </IconButton>
      </Box>
      <Stack
        direction="row"
        gap={2}
        sx={{ display: 'flex', flex: 1, justifyContent: 'center' }}
      >
        <ToggleAudioButton disabled={isReconnecting} />
        <IconButton
          size="small"
          sx={{
            backgroundColor: theme.palette.error.main,
            ':hover': {
              backgroundColor: theme.palette.error.light,
            },
          }}
          onClick={endConversation}
        >
          <Iconify
            icon="fluent:call-end-24-filled"
            color={theme.palette.text.primary}
          />
        </IconButton>
        <ToggleVideoButton disabled={isReconnecting} />
      </Stack>
      <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
        {/* <IconButton
          size="small"
          sx={{ backgroundColor: theme.palette.grey[300] }}
        >
          <Iconify icon="fluent:chat-24-filled" />
        </IconButton> */}
      </Box>
    </Stack>
  );
}
