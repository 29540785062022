// components
import Image from '../../components/image';
//
import { useMediaQuery, useTheme, Toolbar } from '@mui/material';
import LoginHeader from './LoginHeader';
import {
  StyledContainer,
  StyledContent,
  StyledContentInner,
  StyledBox,
  StyledRoot,
  StyledSection,
} from './styles';

// ----------------------------------------------------------------------

type Props = {
  title?: React.ReactNode;
  illustration?: string;
  children: React.ReactNode;
};

export default function LoginLayout({ children, illustration, title }: Props) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <StyledRoot>
      <LoginHeader />
      <StyledBox>
        {isDesktop && <Toolbar sx={{ minHeight: '88px !important' }} />}
        <StyledContainer>
          {isDesktop && (
            <StyledSection>
              {title && title}
              <Image
                disabledEffect
                visibleByDefault
                alt="auth"
                src={illustration || '/assets/illustrations/comfort.png'}
                sx={{ maxWidth: 540 }}
              />
            </StyledSection>
          )}

          <StyledContent>
            <StyledContentInner>{children}</StyledContentInner>
          </StyledContent>
        </StyledContainer>
      </StyledBox>
    </StyledRoot>
  );
}
