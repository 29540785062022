import { Box, Stack, Typography, useTheme } from '@mui/material';
import { getFileIcon } from '../../profile/components/ProfileFilesTable';
import { useCurrentVisitContext } from '../hooks/useCurrentVisitContext';

// ---------------------------------------------------------------------

export default function VisitDetails() {
  const theme = useTheme();
  const { data } = useCurrentVisitContext();

  return (
    <Stack
      sx={{
        gap: 1,
        backgroundColor: theme.palette.background.default,
        borderRadius: 2,
        boxShadow: theme.customShadows.z8,
      }}
    >
      <Stack
        sx={{
          borderTopRightRadius: theme.spacing(2),
          borderTopLeftRadius: theme.spacing(2),
          gap: 0.5,
          paddingX: 3,
          paddingY: 2,
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <Typography variant="h6">Szczegóły e-spotkania</Typography>
        <Typography variant="body2" color="text.secondary">
          Opis został przekazany przez właściciela przed e-spotkaniem.
        </Typography>
      </Stack>
      <Stack
        sx={{
          paddingX: 3,
          paddingY: 2,
          paddingTop: 1,
          gap: 2,
        }}
      >
        <Stack sx={{ gap: 1 }}>
          <Typography>Dolegliwości</Typography>
          <Typography color="text.secondary">{data?.description}</Typography>
        </Stack>
        {data?.documents && data?.documents?.length > 0 && (
          <Stack sx={{ gap: 1 }}>
            <Typography>Załączniki</Typography>
            <Stack sx={{ gap: 2 }}>
              {(data?.documents as any[]).map((file, index) => (
                <Stack
                  key={index}
                  sx={{
                    gap: 1.5,
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{
                      padding: 1,
                      borderRadius: 1,
                      backgroundColor: theme.palette.background.neutral,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {getFileIcon(file.filename)}
                  </Box>
                  <Typography
                    sx={{
                      '&:hover': {
                        cursor: 'pointer',
                        textDecoration: 'underline',
                      },
                    }}
                    onClick={() => window.open(file.url, '_blank')}
                  >
                    {file.filename.split('_')[1]}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}
