// components
import LoadingScreen from '../components/loading-screen';
//
import { useEffect } from 'react';
import { useUserContext } from './useUserContext';

// ----------------------------------------------------------------------

type RolesGuardProps = {
  children: React.ReactNode;
  roles: ['PROFESSIONAL' | 'USER'];
  onPermissionDenied?: () => void;
};

export default function RolesGuard({
  children,
  roles,
  onPermissionDenied,
}: RolesGuardProps) {
  const { isLoading, isProfessionalRole, isUserRole, data } = useUserContext();

  useEffect(() => {
    //TODO - check if this is correct
    if (
      data &&
      !isLoading &&
      !(isProfessionalRole && roles.includes('PROFESSIONAL')) &&
      !(isUserRole && roles.includes('USER')) &&
      onPermissionDenied
    ) {
      onPermissionDenied();
    }
  }, [
    data,
    isLoading,
    roles,
    isProfessionalRole,
    isUserRole,
    onPermissionDenied,
  ]);

  if (!data || isLoading) {
    return <LoadingScreen />;
  }

  if (isProfessionalRole && roles.includes('PROFESSIONAL')) {
    return <> {children} </>;
  }

  if (isUserRole && roles.includes('USER')) {
    return <> {children} </>;
  }

  return null;
}
