// @mui
import { Box, Grid, Skeleton, Stack, useTheme } from '@mui/material';

// ----------------------------------------------------------------------
type ProfileCardSkeletonProps = {
  isSmall?: boolean;
};
export default function ProfileCardSkeleton({
  isSmall,
}: ProfileCardSkeletonProps) {
  const theme = useTheme();

  return isSmall ? (
    <Grid sx={{ p: 2 }} item xs={12}>
      <Box
        sx={{
          borderRadius: 2,
          background: theme.palette.background.default,
          boxShadow: theme.customShadows.z8,
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            borderTopRightRadius: theme.spacing(2),
            borderTopLeftRadius: theme.spacing(2),
            height: '160px',
            position: 'relative',
            background: theme.palette.grey[300],
          }}
        >
          <Box sx={{ p: 1, width: 1 }}>
            {' '}
            <Skeleton
              sx={{ float: 'right' }}
              variant="circular"
              height="48px"
              width="48px"
            />
          </Box>
        </Box>
        <Box
          sx={{
            position: 'relative',
            height: '64px',
            mb: 2,
            padding: '0 24px',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: '-64px',
              left: theme.spacing(3),
              right: theme.spacing(3),
              height: '128px',
            }}
          >
            <Grid container item justifyContent="center">
              <Grid item justifyContent="center">
                <Box
                  sx={{
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'normal',
                    width: 1,
                  }}
                >
                  <Skeleton
                    variant="circular"
                    height="128px"
                    width="128px"
                    sx={{ borderRadius: '50%' }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box
          sx={{
            p: 2,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gap: 1,
            width: 1,
          }}
        >
          <Skeleton variant="rounded" height="28px" width="82px" />
          <Skeleton variant="rounded" height="24px" width="71px" />
        </Box>
        <Box sx={{ p: 3, paddingTop: 0 }}>
          <Grid container item spacing={2}>
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 2,
              }}
            >
              <Skeleton variant="circular" height="20px" width="20px" />
              <Skeleton variant="rounded" height="22px" width="100%" />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="rounded" height="48px" width="100%" />
            </Grid>
            <Grid item xs={12}>
              <Stack sx={{ gap: 2 }}>
                <Stack
                  sx={{
                    gap: 2,
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: 1,
                  }}
                >
                  <Skeleton variant="circular" height="20px" width="20px" />
                  <Skeleton variant="rounded" height="22px" width="100%" />
                </Stack>
                <Stack
                  sx={{
                    gap: 2,
                    flexDirection: 'row',
                    alignItems: 'center',
                    flex: 1,
                  }}
                >
                  <Skeleton variant="circular" height="20px" width="20px" />
                  <Skeleton variant="rounded" height="22px" width="100%" />
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack sx={{ gap: 2 }}>
                <Stack
                  sx={{
                    gap: 2,
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Skeleton variant="circular" height="20px" width="20px" />
                  <Skeleton variant="rounded" height="22px" width="100%" />
                </Stack>
                <Stack
                  sx={{
                    gap: 2,
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Skeleton variant="circular" height="20px" width="20px" />
                  <Skeleton variant="rounded" height="22px" width="100%" />
                </Stack>
              </Stack>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                marginTop: 0,
              }}
            >
              <Stack
                sx={{
                  justifyContent: 'flex-end',
                  gap: 2,
                  height: 1,
                  marginLeft: 0,
                  marginRight: 0,
                  paddingLeft: 0,
                  paddingRight: 0,
                  paddingTop: 0,
                  borderTop: 'none',
                }}
              >
                <Skeleton variant="rounded" height="48px" width="100%" />
                <Skeleton variant="rounded" height="40px" width="100%" />
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Grid>
  ) : (
    <Grid item xs={12}>
      <Box
        sx={{
          borderRadius: 2,
          background: theme.palette.background.default,
          boxShadow: theme.customShadows.z8,
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            borderTopRightRadius: theme.spacing(2),
            borderTopLeftRadius: theme.spacing(2),
            height: '160px',
            position: 'relative',
            background: theme.palette.grey[300],
          }}
        ></Box>
        <Box
          sx={{
            position: 'relative',
            height: '64px',
            mb: 2,
            padding: '0 24px',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: '-64px',
              left: theme.spacing(3),
              right: theme.spacing(3),
              height: '128px',
            }}
          >
            <Grid container item columnSpacing={3} rowSpacing={0}>
              <Grid item xs={'auto'}>
                <Box
                  sx={{
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'normal',
                  }}
                >
                  <Skeleton
                    variant="circular"
                    height="128px"
                    width="128px"
                    sx={{ borderRadius: '50%' }}
                  />
                </Box>
              </Grid>
              <>
                <Grid item xs={true}>
                  <Stack
                    sx={{
                      justifyContent: 'flex-end',
                      height: '100%',
                      gap: theme.spacing(0.5),
                    }}
                  >
                    <Skeleton variant="rounded" height="32px" width="170px" />
                    <Skeleton variant="rounded" height="24px" width="90px" />
                  </Stack>
                </Grid>
                <Grid item xs={'auto'}>
                  <Stack
                    sx={{
                      flexDirection: 'row',
                      alignItems: 'flex-end',
                      height: '100%',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                      }}
                    >
                      <Skeleton variant="circular" height="20px" width="20px" />
                      <Skeleton variant="rounded" height="22px" width="250px" />
                    </Box>
                  </Stack>
                </Grid>
              </>
            </Grid>
          </Box>
        </Box>
        <Box sx={{ p: 3, paddingTop: 0 }}>
          <Grid container item spacing={2}>
            <Grid item xs={12}>
              <Skeleton variant="rounded" height="24px" width="100%" />
            </Grid>
            <Grid item xs={3}>
              <Stack sx={{ gap: 2 }}>
                <Stack
                  sx={{
                    gap: 2,
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Skeleton variant="circular" height="20px" width="20px" />
                  <Skeleton variant="rounded" height="22px" width="200px" />
                </Stack>
                <Stack
                  sx={{
                    gap: 2,
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Skeleton variant="circular" height="20px" width="20px" />
                  <Skeleton variant="rounded" height="22px" width="200px" />
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={3}>
              <Stack sx={{ gap: 2 }}>
                <Stack
                  sx={{
                    gap: 2,
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Skeleton variant="circular" height="20px" width="20px" />
                  <Skeleton variant="rounded" height="22px" width="200px" />
                </Stack>
                <Stack
                  sx={{
                    gap: 2,
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Skeleton variant="circular" height="20px" width="20px" />
                  <Skeleton variant="rounded" height="22px" width="200px" />
                </Stack>
              </Stack>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                marginTop: 0,
              }}
            >
              <Stack
                sx={{
                  gap: 2,
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-end',
                  height: 1,
                  marginLeft: 0,
                  marginRight: 0,
                  paddingLeft: 0,
                  paddingRight: 0,
                  paddingTop: 0,
                  borderTop: 'none',
                }}
              >
                <Skeleton variant="rounded" height="48px" width="160px" />
                <Skeleton variant="rounded" height="48px" width="160px" />
                <Skeleton variant="circular" height="48px" width="48px" />
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Grid>
  );
}
