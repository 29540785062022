import { CircularProgress, Stack, useTheme } from '@mui/material';
import { useEffect } from 'react';
import { useConversationModalContext } from '../hooks/useConversationModalContext';
import useVideoContext from '../hooks/useVideoContext';
import Room from './Room';

export default function ConversationVideo() {
  const theme = useTheme();
  const {
    state: { token, roomName, open },
  } = useConversationModalContext();
  const {
    room,
    connect: connectVideo,
    isConnecting,
    error,
  } = useVideoContext();

  useEffect(() => {
    if (token && roomName && open && !isConnecting && !room && !error) {
      connectVideo(token, roomName);
    }
  }, [token, roomName, open, connectVideo, isConnecting, room, error]);

  return (
    <Stack
      sx={{
        flex: 1,
        backgroundColor: theme.palette.text.primary,
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      {isConnecting || !room ? (
        <CircularProgress sx={{ color: '#F4ECE5' }} />
      ) : (
        <Room />
      )}
    </Stack>
  );
}
