// @mui

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import * as Yup from 'yup';

import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useUploadDocuments } from 'src/hooks/useUploadDocuments';
import { getFileIcon } from 'src/sections/@dashboard/profile/components/ProfileFilesTable';
import FormProvider, { RHFUploadBox } from '../hook-form';
import Iconify from '../iconify';

// ---------------------------------------------------------------------

export type FilesUploadModalFormValuesProps = {
  files: { filename: string; url: string }[] | null;
};

const defaultValues: FilesUploadModalFormValuesProps = {
  files: null,
};

type FilesUploadModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onUpload: (files: { filename: string; url: string }[]) => void;
  isLoading: boolean;
};

export default function FilesUploadModal({
  isOpen,
  onClose,
  onUpload,
  isLoading,
}: FilesUploadModalProps) {
  const theme = useTheme();
  const [uploading, setUploading] = useState(false);
  const { upload, downloadURLs, error, reset, remove } = useUploadDocuments();
  const { enqueueSnackbar } = useSnackbar();
  const Schema: Yup.Schema<FilesUploadModalFormValuesProps> =
    Yup.object().shape({
      files: Yup.array()
        .of(
          Yup.object().shape({
            filename: Yup.string().required(),
            url: Yup.string().required(),
          }),
        )
        .required(),
    });

  const methods = useForm<FilesUploadModalFormValuesProps>({
    resolver: yupResolver(Schema),
    defaultValues,
  });

  const { setValue, handleSubmit } = methods;

  const handleDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const fileNamesToUpload = acceptedFiles.map((file) => file.name);
      const isFileAlreadyUploaded = fileNamesToUpload.some((item) =>
        downloadURLs.map((item) => item.name.split('_')[1]).includes(item),
      );

      // TODO: check if file name and extension of the file is the same, not just the name. We could display name of already uploaded file
      if (isFileAlreadyUploaded) {
        enqueueSnackbar('Załącznik został już dodany', {
          variant: 'error',
        });
        return;
      }
      setUploading(true);
      upload(acceptedFiles);
    },
    [upload, downloadURLs, enqueueSnackbar],
  );

  useEffect(() => {
    if (downloadURLs.length > 0) {
      setValue(
        'files',
        downloadURLs.map((file) => ({ url: file.url, filename: file.name })),
      );
      setUploading(false);
    }
  }, [downloadURLs, setValue]);

  useEffect(() => {
    if (error) {
      setUploading(false);
    }
  }, [error]);

  const handleRemove = (fileName: string) => {
    remove(fileName);
  };

  const handleUpload = (values: FilesUploadModalFormValuesProps) => {
    if (values.files) {
      onUpload(values.files);
    }
  };

  const handleOnClose = () => {
    onClose();
  };

  useEffect(() => {
    if (!isOpen && downloadURLs?.length > 0) {
      setTimeout(() => reset(), 200);
    }
  }, [downloadURLs, reset, isOpen]);

  return (
    <Dialog
      open={isOpen}
      onClose={handleOnClose}
      PaperProps={{
        sx: { maxWidth: '600px', width: 0.8 },
      }}
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(handleUpload)}>
        <DialogTitle
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          Dodaj pliki dotyczące spotkania
        </DialogTitle>
        <DialogContent sx={{ px: 3, overflow: 'hidden' }}>
          {downloadURLs?.length > 0 && (
            <Stack sx={{ gap: 2 }}>
              {downloadURLs?.map(({ name, progress }, index) => (
                <Stack
                  key={index}
                  sx={{
                    gap: 1.5,
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      gap: 2,
                      flex: 1,
                    }}
                  >
                    <Box
                      sx={{
                        padding: 1,
                        borderRadius: 1,
                        backgroundColor: theme.palette.background.neutral,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {getFileIcon(name)}
                    </Box>
                    <Box sx={{ display: 'flex', flex: 1 }}>
                      {progress === 100 ? (
                        <Typography>{name.split('_')[1]}</Typography>
                      ) : (
                        <LinearProgress
                          variant="determinate"
                          value={progress}
                          sx={{ width: 1, height: 5 }}
                        />
                      )}
                    </Box>
                  </Box>
                  <IconButton
                    onClick={() => handleRemove(name)}
                    color="error"
                    disabled={uploading}
                  >
                    <Iconify icon="eva:trash-2-outline" />
                  </IconButton>
                </Stack>
              ))}
            </Stack>
          )}
          <RHFUploadBox
            sx={{
              flex: 1,
              height: 'inherit',
              borderRadius: 1.5,
              width: 1,
              minHeight: 150,
              mt: 2,
            }}
            name="background"
            maxSize={3145728}
            onDrop={(files) => handleDrop(files)}
            placeholder={
              <Stack sx={{ gap: 0.5, p: 3, flex: 1 }} alignItems="center">
                {!uploading ? (
                  <>
                    <Iconify icon="eva:cloud-upload-fill" width={40} />
                    <Typography variant="body2">Załącz pliki</Typography>
                  </>
                ) : null}
              </Stack>
            }
            disabled={isLoading || uploading}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="inherit" onClick={handleOnClose}>
            Anuluj
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            size="medium"
            loading={isLoading}
            disabled={isLoading || downloadURLs?.length === 0}
          >
            <span>Zapisz</span>
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
