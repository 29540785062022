// @mui
import { Button, Stack, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import Iconify from '../../../../components/iconify';
import Image from '../../../../components/image';
import ProfileModal from './ProfileModal';

import { useProfilesContext } from '../hooks/useProfilesContext';

// ----------------------------------------------------------------------

type AddFirstProfileProps = {
  openRedirectModal: () => void;
};

export default function AddFirstProfile({
  openRedirectModal,
}: AddFirstProfileProps) {
  const theme = useTheme();
  const { refetch } = useProfilesContext();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Stack
      sx={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        gap: 2,
        height: '100%',
        paddingX: 2,
        paddingY: 4,
      }}
    >
      <Image
        disabledEffect
        visibleByDefault
        alt="empty profiles page image"
        src={'/assets/illustrations/rabbit.svg'}
        sx={{ maxWidth: 150, width: { xs: '35%', sm: '35%' } }}
      />
      <Typography variant="h4" sx={{ textAlign: 'center' }}>
        Dodaj swojego pierwszego zwierzaka!
      </Typography>
      <Button
        variant="contained"
        size="large"
        sx={{
          background: theme.palette.common.black,
          color: theme.palette.common.white,
        }}
        startIcon={<Iconify width="24px" height="24px" icon="ic:round-plus" />}
        onClick={openModal}
      >
        Dodaj zwierzaka
      </Button>
      <ProfileModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onSubmited={(isEdit: boolean) => {
          refetch();
          !isEdit && openRedirectModal();
        }}
      />
    </Stack>
  );
}
