import * as Yup from 'yup';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Button, Card, Grid, Stack } from '@mui/material';
// auth
import { useMutation } from '@tanstack/react-query';
import { userApi } from 'src/api/user';
import { useUserContext } from 'src/auth/useUserContext';
import { GetUserDto, UpdateUserDto } from 'woofwoof-api';
import FormProvider, { RHFTextField } from '../../../../components/hook-form';
import { useSnackbar } from '../../../../components/snackbar';

// ----------------------------------------------------------------------

export type AccountBusinessFormValuesProps = {
  companyName: string;
  vatNumber: string;
  pwzNumber: string;
  companyAddress: string;
  companyCity: string;
  companyZip: string;
};

type AccountBusinessProps = {
  onSubmit?: (values: AccountBusinessFormValuesProps) => void;
  onBack?: (values: AccountBusinessFormValuesProps) => void;
  stepValues?: AccountBusinessFormValuesProps | GetUserDto | undefined;
  isLoading?: boolean;
};

export default function AccountBusiness({
  onSubmit,
  stepValues,
  onBack,
  isLoading,
}: AccountBusinessProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { data, refetch } = useUserContext();
  const { mutateAsync, isLoading: isUpdateUserLoading } = useMutation({
    mutationFn: (updateUserDto: UpdateUserDto) =>
      userApi.userControllerUpdateUser({ updateUserDto }),
  });

  const UpdateUserSchema: Yup.Schema<AccountBusinessFormValuesProps> =
    Yup.object().shape({
      companyName: Yup.string().required('Nazwa firmy jest wymagana'),
      vatNumber: Yup.string()
        .required('NIP jest wymagany')
        .matches(/^\d{10}$/, 'NIP musi zawierać 10 cyfr'),
      pwzNumber: Yup.string().required('PWZ jest wymagane'),
      companyAddress: Yup.string().required('Adres jest wymagany'),
      companyCity: Yup.string().required('Miasto jest wymagane'),
      companyZip: Yup.string()
        .required('Kod pocztowy jest wymagany')
        .matches(/^\d{2}-\d{3}$/, 'Wymagany format to XX-XXX'),
    });

  const defaultValues = {
    companyName: (stepValues || data)?.companyName || '',
    vatNumber: (stepValues || data)?.vatNumber || '',
    pwzNumber: (stepValues || data)?.pwzNumber || '',
    companyCity: (stepValues || data)?.companyCity || '',
    companyAddress: (stepValues || data)?.companyAddress || '',
    companyZip: (stepValues || data)?.companyZip || '',
  };

  const methods = useForm<AccountBusinessFormValuesProps>({
    resolver: yupResolver(UpdateUserSchema),
    defaultValues,
  });

  const { getValues, handleSubmit } = methods;

  const handleOnSubmit = async ({
    companyName,
    vatNumber,
    pwzNumber,
    companyCity,
    companyAddress,
    companyZip,
  }: AccountBusinessFormValuesProps) => {
    try {
      await mutateAsync({
        companyName,
        vatNumber,
        pwzNumber,
        companyCity,
        companyAddress,
        companyZip,
      });
      await refetch();
    } catch (error) {
      enqueueSnackbar(error.message || error, {
        variant: 'error',
      });
    }
  };

  return (
    <FormProvider
      methods={methods}
      onSubmit={handleSubmit(onSubmit || handleOnSubmit)}
    >
      <Grid
        sx={{ px: { xs: 2, sm: 2, md: 2, lg: 0, xl: 0 } }}
        container
        spacing={3}
      >
        <Grid item xs={12} md={12}>
          <Card sx={{ p: 3 }}>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(1, 1fr)',
                md: 'repeat(1, 1fr)',
                lg: 'repeat(2, 1fr)',
              }}
            >
              <RHFTextField
                name="companyName"
                label="Nazwa firmy"
                disabled={isLoading || isUpdateUserLoading}
                required
              />

              <RHFTextField
                name="vatNumber"
                label="NIP"
                disabled={isLoading || isUpdateUserLoading}
                required
              />

              <RHFTextField
                name="pwzNumber"
                label="PWZ"
                disabled={isLoading || isUpdateUserLoading}
                required
              />

              <RHFTextField
                name="companyAddress"
                label="Adres"
                disabled={isLoading || isUpdateUserLoading}
                required
              />

              <RHFTextField
                name="companyCity"
                label="Miasto"
                disabled={isLoading || isUpdateUserLoading}
                required
              />

              <RHFTextField
                name="companyZip"
                label="Kod pocztowy"
                disabled={isLoading || isUpdateUserLoading}
                required
              />
            </Box>

            <Stack
              flexDirection="row"
              justifyContent={onBack ? 'space-between' : 'flex-end'}
              sx={{ mt: 3 }}
            >
              {onBack && (
                <Button
                  onClick={() => onBack(getValues())}
                  color="inherit"
                  variant="outlined"
                  disabled={isLoading || isUpdateUserLoading}
                >
                  Wróć
                </Button>
              )}
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isLoading || isUpdateUserLoading}
              >
                <span>Zapisz</span>
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
