// routes
// components
import { useNavigate } from 'react-router';
import LoadingScreen from '../components/loading-screen';
//
import { useEffect } from 'react';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useAuthContext } from './useAuthContext';

// ----------------------------------------------------------------------

type GuestGuardProps = {
  children: React.ReactNode;
};

export default function GuestGuard({ children }: GuestGuardProps) {
  const navigate = useNavigate();
  const { isInitialized, isAuthenticated } = useAuthContext();

  useEffect(() => {
    if (isInitialized && isAuthenticated) {
      navigate(PATH_DASHBOARD.root, { replace: true });
    }
  }, [isInitialized, isAuthenticated, navigate]);

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  return <> {children} </>;
}
