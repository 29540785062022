// @mui
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  Typography,
  useTheme,
} from '@mui/material';
import { Box } from '@mui/system';

// ----------------------------------------------------------------------

type MicrophoneAndCameraPermissionsModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export default function MicrophoneAndCameraPermissionsModal({
  isOpen,
  onClose,
}: MicrophoneAndCameraPermissionsModalProps) {
  const theme = useTheme();

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Dostęp do kamery i mikrofonu</DialogTitle>

      <DialogContent sx={{ maxWidth: '600px' }}>
        <DialogContentText id="alert-dialog-description">
          Abyśmy mogli zapewnić Ci najwyższą jakość obsługi podczas e-spotkania
          z naszym specjalistą, prosimy o włączenie kamery oraz mikrofonu. Te
          elementy są obowiązkowe, aby nasze spotkanie mogło się odbyć i
          przebiegać sprawnie. Twoja widoczność i możliwość komunikacji głosowej
          są kluczowe dla pełnego zrozumienia Twoich potrzeb i udzielenia Ci
          najlepszego wsparcia. Dziękujemy za zrozumienie. Owocnego szczekania i
          mruczenia podczas naszego e-spotkania!
          <br />
          <br />
          Poniżej znajdziesz linki do instrukcji, które pomogą Ci w udzieleniu
          dostępu w różnych przeglądarkach:
        </DialogContentText>
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 1 }}>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Typography color="text.secondary">Google Chrome:</Typography>
            <Link
              component="a"
              target="_blank"
              href="https://support.google.com/chrome/answer/2693767?sjid=7712301754244710999-EU"
            >
              Komputer
            </Link>
            <Link
              component="a"
              target="_blank"
              href="https://support.google.com/chrome/answer/2693767?sjid=7712301754244710999-EU&co=GENIE.Platform%3DiOS&oco=1"
            >
              iOS
            </Link>
            <Link
              component="a"
              target="_blank"
              href="https://support.google.com/chrome/answer/2693767?sjid=7712301754244710999-EU&co=GENIE.Platform%3DAndroid&oco=1"
            >
              Android
            </Link>
          </Box>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Typography color="text.secondary">Safari:</Typography>
            <Link
              component="a"
              target="_blank"
              href="https://support.apple.com/pl-pl/guide/safari/ibrw7f78f7fe/mac"
            >
              Komputer
            </Link>
            <Link
              component="a"
              target="_blank"
              href="https://support.apple.com/pl-pl/guide/iphone/iph168c4bbd5/ios"
            >
              iOS
            </Link>
          </Box>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Typography color="text.secondary">Mozilla Firefox:</Typography>
            <Link
              component="a"
              target="_blank"
              href="https://support.mozilla.org/pl/kb/zarzadzanie-uprawnieniami-kamery-i-mikrofonu-firefox"
            >
              Komputer
            </Link>
            <Link
              component="a"
              target="_blank"
              href="https://support.mozilla.org/pl/kb/zarzadzanie-uprawnieniami-kamery-i-mikrofonu-firefox#w_uzycie-menu-ustawien-urzadzenia-z-systemem-ios-w-celu-zmiany-uprawnien-kamery-i-mikrofonu"
            >
              iOS
            </Link>
            <Link
              component="a"
              target="_blank"
              href="https://support.mozilla.org/pl/kb/przyznanie-dostepu-do-kamery-firefox-android"
            >
              Android
            </Link>
          </Box>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Typography color="text.secondary">Microsoft Edge:</Typography>
            <Link
              component="a"
              target="_blank"
              href="https://support.microsoft.com/pl-pl/windows/windows-mikrofon-i-prywatno%C5%9B%C4%87-a83257bc-e990-d54a-d212-b5e41beba857"
            >
              Komputer
            </Link>
          </Box>
          <br />
          <Typography color="text.secondary">
            Gdy nasza aplikacja otrzyma wymagany dostęp, przycisk "Rozpocznij
            e-spotkanie" połączy Cię z umówionym specjalistą, a powyższy
            komunikat nie zostanie ponownie wyświetlony.
          </Typography>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          sx={{
            background: theme.palette.common.black,
            color: theme.palette.common.white,
          }}
          onClick={onClose}
        >
          Zamknij
        </Button>
      </DialogActions>
    </Dialog>
  );
}
