// @mui

import { Avatar, Box, Grid, Stack, Typography, useTheme } from '@mui/material';
import { useUserContext } from 'src/auth/useUserContext';
import { GetVisitById } from 'woofwoof-api';
import { getFileIcon } from '../../profile/components/ProfileFilesTable';
import VisitModalDetailsTabs from './VisitModalDetailsTabs';

// ---------------------------------------------------------------------

type VisitModalDetailsProps = {
  visit?: GetVisitById;
};

export default function VisitModalDetails({ visit }: VisitModalDetailsProps) {
  const theme = useTheme();
  const { isProfessionalRole } = useUserContext();

  if (!visit) {
    return null;
  }

  return (
    <Stack sx={{ gap: 3 }}>
      <Grid
        container
        sx={{
          gap: { xs: 1.5, sm: 1.5, md: 3, lg: 0, xl: 0 },
          alignItems: 'center',
        }}
      >
        <Grid
          sx={{
            gap: 2,
            p: 2,
            bgcolor: theme.palette.primary.lighter,
            borderRadius: 2,
            flexDirection: 'row',
          }}
          item
          xs={12}
          sm={12}
          md={12}
          lg={5.5}
        >
          <Stack
            sx={{
              gap: 2,
              flexDirection: 'row',
              alignItems: 'center',
              flex: 1,
            }}
          >
            <Avatar
              src={visit?.profile?.avatar || '/assets/avatars/avatar4.png'}
              sx={{ width: theme.spacing(5), height: theme.spacing(5) }}
            />
            <Stack
              sx={{
                gap: theme.spacing(0.5),
              }}
            >
              <Typography>{visit?.profile?.name}</Typography>
              <Typography
                variant="body2"
                sx={{ color: theme.palette.text.secondary }}
              >
                Zwierzak
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid
          item
          alignItems="center"
          justifyContent="center"
          xs={12}
          sm={12}
          md={12}
          lg
          xl
        >
          <Typography
            sx={{
              fontSize: { xs: '20px', sm: '20px', md: '24px' },
              textAlign: 'center',
            }}
          >
            z
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            gap: 2,
            p: 2,
            bgcolor: theme.palette.primary.lighter,
            borderRadius: 2,
            flexDirection: 'row',
          }}
          xs={12}
          sm={12}
          md={12}
          lg={5.5}
        >
          <Stack
            sx={{
              gap: 2,
              flexDirection: 'row',
              alignItems: 'center',
              flex: 1,
            }}
          >
            <Avatar
              src={
                isProfessionalRole
                  ? visit?.user?.avatar
                  : visit?.professional?.avatar
              }
              sx={{ width: theme.spacing(5), height: theme.spacing(5) }}
            />

            <Stack
              sx={{
                gap: theme.spacing(0.5),
              }}
            >
              <Typography>
                {visit?.professional?.firstName
                  ? `${visit?.professional?.firstName}`
                  : `${visit?.user?.firstName} ${visit?.user?.lastName}`}
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: theme.palette.text.secondary }}
              >
                {visit?.professional?.firstName ? 'Specjalista' : 'Właściciel'}
              </Typography>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
      {isProfessionalRole ? (
        <VisitModalDetailsTabs visit={visit} />
      ) : (
        <>
          <Stack sx={{ gap: 1 }}>
            <Typography>Dolegliwości</Typography>
            <Typography color="text.secondary">{visit.description}</Typography>
          </Stack>
          {visit?.documents && visit?.documents?.length > 0 && (
            <Stack sx={{ gap: 1 }}>
              <Typography>Załączniki</Typography>
              <Stack sx={{ gap: 2 }}>
                {(visit.documents as any[])?.map((file, index) => (
                  <Stack
                    key={index}
                    sx={{
                      gap: 1.5,
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <Box
                      sx={{
                        padding: 1,
                        borderRadius: 1,
                        backgroundColor: theme.palette.background.neutral,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {getFileIcon(file.filename)}
                    </Box>
                    <Typography
                      sx={{
                        '&:hover': {
                          cursor: 'pointer',
                          textDecoration: 'underline',
                        },
                      }}
                      noWrap
                      onClick={() => window.open(file.url, '_blank')}
                    >
                      {file.filename.split('_')[1]}
                    </Typography>
                  </Stack>
                ))}
              </Stack>
            </Stack>
          )}
        </>
      )}
    </Stack>
  );
}
