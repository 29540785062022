import {
  Box,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material';

export default function ProfileVisitsHistoryTableSkeleton() {
  const theme = useTheme();

  const BodyRow = (
    <TableRow>
      <TableCell align="left">
        <Box
          sx={{
            gap: theme.spacing(2),
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Skeleton variant="circular" height="40px" width="40px" />
          <Skeleton variant="rounded" height="22px" width="140px" />
        </Box>
      </TableCell>
      <TableCell align="right">
        <Skeleton variant="rounded" height="22px" width="140px" />
      </TableCell>
    </TableRow>
  );

  return (
    <>
      <TableContainer
        sx={{ height: 1, display: 'flex', flexDirection: 'column' }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                align="left"
                sx={{ backgroundColor: theme.palette.grey[300] }}
              >
                <Skeleton variant="rounded" height="24px" width="134px" />
              </TableCell>
              <TableCell
                align="right"
                sx={{ backgroundColor: theme.palette.grey[300] }}
              >
                <Skeleton variant="rounded" height="24px" width="134px" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {BodyRow}
            {BodyRow}
            {BodyRow}
            {BodyRow}
            {BodyRow}
            {BodyRow}
          </TableBody>
        </Table>
      </TableContainer>
      <Skeleton variant="rectangular" height="1px" width="100%" />
      <Stack
        sx={{
          minHeight: 52,
          pr: 1,
          flexDirection: 'row',
          gap: 3,
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <Skeleton variant="rounded" height="22px" width="170px" />
        <Skeleton variant="rounded" height="22px" width="50px" />
        <Stack sx={{ gap: 1, flexDirection: 'row' }}>
          <Skeleton variant="circular" height="40px" width="40px" />
          <Skeleton variant="circular" height="40px" width="40px" />
        </Stack>
      </Stack>
    </>
  );
}
