import { LoadingButton } from '@mui/lab';
import { Skeleton, Stack, Typography } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { profileApi } from 'src/api/profile';
import { visitApi } from 'src/api/visit';
import { useUserContext } from 'src/auth/useUserContext';
import FilesUploadModal, {
  FilesUploadModalFormValuesProps,
} from 'src/components/files-upload-modal';
import Iconify from 'src/components/iconify';
import { VisitApiVisitControllerAddDocumentsRequest } from 'woofwoof-api';
import { useCurrentVisitContext } from '../../visit/hooks/useCurrentVisitContext';
import ProfileFilesTable from './ProfileFilesTable';
import ProfileFilesTableSkeleton from './ProfileFilesTableSkeleton';

export const ProfileFilesContent = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isUserRole, isProfessionalRole } = useUserContext();
  const { enqueueSnackbar } = useSnackbar();
  const { data: currentVisitData } = useCurrentVisitContext();
  const { profileId } = useParams<{ profileId: string }>();
  const { data, isLoading, isError, remove } = useQuery(
    ['profileDocuments'],
    () =>
      profileApi.profileControllerGetProfileDocuments({
        profileId: profileId as string,
      }),
    {
      retry: 1,
      enabled: Boolean(isUserRole),
    },
  );
  const {
    data: previewData,
    isLoading: isPreviewLoading,
    isError: isPreviewError,
    remove: removePreview,
    refetch: refetchPreview,
  } = useQuery(
    ['profileDocumentsPreview'],
    () =>
      profileApi.profileControllerGetProfileDocumentsPreview({
        profileId: profileId as string,
      }),
    {
      retry: 1,
      enabled: !Boolean(isUserRole) && Boolean(currentVisitData),
    },
  );
  const { mutateAsync, isLoading: isUploadLoading } = useMutation({
    mutationFn: (
      visitAddDocumentsDto: VisitApiVisitControllerAddDocumentsRequest,
    ) => visitApi.visitControllerAddDocuments(visitAddDocumentsDto),
  });

  const openModal = () => setIsModalOpen(true);

  const closeModal = () => setIsModalOpen(false);

  const handleOnUpload = async (
    files: FilesUploadModalFormValuesProps['files'],
  ) => {
    if (files) {
      try {
        await mutateAsync({
          visitId: currentVisitData?.id as string,
          documentFileDto: files,
        });
        closeModal();
        refetchPreview();
      } catch (error) {
        enqueueSnackbar(error.message || error, {
          variant: 'error',
        });
      }
    }
  };

  useEffect(() => {
    if (
      (!data && !isLoading && isError) ||
      (!previewData && !isPreviewLoading && isPreviewError)
    ) {
      enqueueSnackbar('Nie mogliśmy pobrać plików dla wskazanego profilu', {
        variant: 'error',
      });
    }
  }, [
    data,
    isLoading,
    isError,
    enqueueSnackbar,
    previewData,
    isPreviewLoading,
    isPreviewError,
  ]);

  useEffect(() => {
    return () => {
      isUserRole ? remove() : removePreview();
    };
  }, [remove, isUserRole, removePreview]);

  return !isLoading || !isPreviewLoading ? (
    <>
      <Stack
        p={3}
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h6">Pliki</Typography>
        {isProfessionalRole && (
          <LoadingButton
            variant="contained"
            color="primary"
            size="medium"
            startIcon={<Iconify icon="eva:cloud-upload-fill" />}
            onClick={openModal}
          >
            <span>Dodaj pliki</span>
          </LoadingButton>
        )}
      </Stack>
      <ProfileFilesTable data={data?.data || previewData?.data || []} />
      {isProfessionalRole && (
        <FilesUploadModal
          isOpen={isModalOpen}
          onClose={closeModal}
          onUpload={handleOnUpload}
          isLoading={isUploadLoading}
        />
      )}
    </>
  ) : (
    <>
      <Stack
        p={3}
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Skeleton variant="rounded" height="28px" width="260px" />
        {isProfessionalRole && (
          <Skeleton variant="rounded" height="36px" width="123px" />
        )}
      </Stack>
      <ProfileFilesTableSkeleton />
    </>
  );
};
