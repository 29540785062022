import {
  Avatar,
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { format } from 'date-fns';
import { ReactNode, useState } from 'react';
import Scrollbar from 'src/components/scrollbar';
import { GetUserDtoCategoriesEnum, GetVisitById } from 'woofwoof-api';
import Image from '../../../../components/image';
import { useVisitsContext } from '../hooks/useVisitsContext';
import { VISIT_TYPE_MATRIX } from './BookingForm';
import { paymentStatus } from './UpcomingVisitCard';
import UpcomingVisitsDetailsModalActions from './UpcomingVisitsDetailsModalActions';
import VisitModal from './VisitModal';

interface Row {
  id: string;
  profile?: string;
  profileAvatar?: string;
  date: string;
  specialist?: string;
  specialistAvatar?: string;
  category: GetUserDtoCategoriesEnum;
  payment: string;
  actions: string;
}

interface Column {
  id: keyof Row;
  label: string;
  minWidth?: number;
  align?: 'left';
  render?: (row: Row) => ReactNode;
}

export default function UpcomingVisitsTable() {
  const theme = useTheme();
  const [selectedVisitId, setSelectedVisitId] = useState('');
  const { nextVisit, upcomingVisits } = useVisitsContext();
  const data = upcomingVisits?.filter(
    (visit: GetVisitById) => visit.id !== nextVisit?.id,
  );

  const openModal = (visitId: string) => setSelectedVisitId(visitId);

  const closeModal = () => setSelectedVisitId('');

  const handleOnRowClick = (visitId: string) => {
    openModal(visitId);
  };

  const tableData: Row[] | [] =
    //TODO: Add proper type
    data?.map((visit) => ({
      id: visit.id,
      profile: visit?.profile?.name,
      profileAvatar: visit?.profile?.avatar,
      specialist: `${visit?.professional?.firstName}`,
      specialistAvatar: visit?.professional?.avatar,
      date: visit.startTime,
      category: visit.category,
      payment: visit.payment?.status
        ? paymentStatus[visit.payment?.status]
        : '',
      actions: '',
    })) || [];

  const COLUMNS: Column[] = [
    {
      id: 'specialist',
      label: 'Specjalista',
      render: (value: Row) => (
        <Box
          sx={{
            gap: theme.spacing(2),
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Avatar
            src={value.specialistAvatar}
            sx={{ width: theme.spacing(5), height: theme.spacing(5) }}
          />
          <Typography variant="body2">{value.specialist}</Typography>
        </Box>
      ),
    },
    {
      id: 'profile',
      label: 'Zwierzak',
      render: (value: Row) => (
        <Box
          sx={{
            gap: theme.spacing(2),
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Avatar
            src={value.profileAvatar || '/assets/avatars/avatar4.png'}
            sx={{ width: theme.spacing(5), height: theme.spacing(5) }}
          />
          <Typography variant="body2">{value.profile}</Typography>
        </Box>
      ),
    },
    {
      id: 'category',
      label: 'Rodzaj',
      render: (value: Row) => VISIT_TYPE_MATRIX[value.category] || '',
    },
    {
      id: 'date',
      label: 'Data',
      render: (value: Row) => format(new Date(value.date), 'dd/MM/yyyy H:mm'),
    },
    {
      id: 'payment',
      label: 'Status płatności',
    },
  ];

  const TableHeader = (
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          {COLUMNS.map((column) => (
            <TableCell
              key={column.id}
              align={column.align}
              style={{ minWidth: column.minWidth }}
            >
              {column.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </Table>
  );

  const FullTable = (
    <Box sx={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}>
      <Scrollbar>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {COLUMNS.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData?.map((row, rowIndex) => (
              <TableRow
                hover
                key={rowIndex}
                onClick={() => handleOnRowClick(row.id)}
              >
                {COLUMNS.map((column, index) => {
                  const value = row[column.id];
                  return (
                    <TableCell key={column.id} align={column.align}>
                      {column.render ? column.render(row) : value}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Scrollbar>
    </Box>
  );

  return (
    <>
      <TableContainer
        sx={{ height: 1, display: 'flex', flexDirection: 'column' }}
      >
        {tableData.length > 0 ? (
          FullTable
        ) : (
          <>
            {TableHeader}
            <Stack
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              flex="1"
              gap={2}
            >
              <Image
                sx={{ width: '200px' }}
                alt="empty waiting room"
                src="/assets/images/empty_table.svg"
              />
              <Typography variant="h6">
                {nextVisit ? 'Brak kolejnych e-spotkań' : 'Brak e-spotkań'}
              </Typography>
            </Stack>
          </>
        )}
      </TableContainer>
      <VisitModal
        isOpen={Boolean(selectedVisitId)}
        onClose={closeModal}
        visit={data?.find((visit) => visit.id === selectedVisitId)}
        visitActions={(_visit, _onClose) => (
          <UpcomingVisitsDetailsModalActions
            visit={_visit}
            onClose={_onClose}
          />
        )}
      />
    </>
  );
}
