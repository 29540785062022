import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import FormProvider, { RHFCheckbox } from 'src/components/hook-form';
import Label from 'src/components/label';
import * as Yup from 'yup';
import { useCurrentVisitContext } from '../hooks/useCurrentVisitContext';

export type FormValuesProps = {
  step1?: boolean;
  step2?: boolean;
  step3?: boolean;
  step4?: boolean;
  step5?: boolean;
};

// ---------------------------------------------------------------------
export default function VisitSteps() {
  const { setVisitFlow, visitFlow } = useCurrentVisitContext();
  const theme = useTheme();

  const defaultValues: FormValuesProps = {
    step1: visitFlow.steps.step1,
    step2: visitFlow.steps.step2,
    step3: visitFlow.steps.step3,
    step4: visitFlow.steps.step4,
    step5: visitFlow.steps.step5,
  };

  const schema: Yup.Schema<FormValuesProps> = Yup.object().shape({
    step1: Yup.boolean().isTrue().required(),
    step2: Yup.boolean().isTrue().required(),
    step3: Yup.boolean().isTrue().required(),
    step4: Yup.boolean().isTrue().required(),
    step5: Yup.boolean().isTrue().required(),
  });

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    watch,
    formState: { isValid },
  } = methods;

  useEffect(() => {
    const subscription = watch((value, { name, type }) =>
      setVisitFlow((prev) => ({ ...prev, steps: value })),
    );
    return () => subscription.unsubscribe();
  }, [watch, setVisitFlow]);

  return (
    <FormProvider methods={methods}>
      <Stack
        sx={{
          gap: 1,
          backgroundColor: theme.palette.background.default,
          borderRadius: 2,
          boxShadow: theme.customShadows.z8,
        }}
      >
        <Stack
          sx={{
            borderTopRightRadius: theme.spacing(2),
            borderTopLeftRadius: theme.spacing(2),
            gap: 0.5,
            paddingX: 3,
            paddingY: 2,
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <Stack
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="h6">Analiza specjalistyczna</Typography>
            {!isValid ? (
              <Label color="error">Obowiązkowe</Label>
            ) : (
              <Label color="success">Gotowe</Label>
            )}
          </Stack>
          <Typography variant="body2" color="text.secondary">
            Należy spełnić poniższe warunki podczas trwania e-spotkania.
          </Typography>
        </Stack>
        <Stack
          sx={{
            paddingX: 3,
            paddingY: 2,
            paddingTop: 0,
            gap: 1,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              '.MuiBox-root': { alignItems: 'center' },
            }}
          >
            <RHFCheckbox
              name="step1"
              label=""
              disabled={false}
              customLabel={<Typography>Weryfikacja pacjenta</Typography>}
              sx={{ mr: 1 }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              '.MuiBox-root': { alignItems: 'center' },
            }}
          >
            <RHFCheckbox
              name="step2"
              label=""
              disabled={false}
              customLabel={<Typography>Postawienie diagnozy</Typography>}
              sx={{ mr: 1 }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              '.MuiBox-root': { alignItems: 'center' },
            }}
          >
            <RHFCheckbox
              name="step3"
              label=""
              disabled={false}
              customLabel={
                <Typography>
                  Zalecenia leczenia (np. leki, dieta, rehabilitacja)
                </Typography>
              }
              sx={{ mr: 1 }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              '.MuiBox-root': { alignItems: 'center' },
            }}
          >
            <RHFCheckbox
              name="step4"
              label=""
              disabled={false}
              customLabel={
                <Typography>Wyjaśnienie diagnozy i planu leczenia</Typography>
              }
              sx={{ mr: 1 }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              '.MuiBox-root': { alignItems: 'center' },
            }}
          >
            <RHFCheckbox
              name="step5"
              label=""
              disabled={false}
              customLabel={
                <Typography>Odpowiedzi na pytania właściciela</Typography>
              }
              sx={{ mr: 1 }}
            />
          </Box>
        </Stack>
      </Stack>
    </FormProvider>
  );
}
