import Masonry from '@mui/lab/Masonry';
import { Fragment } from 'react';
import { useResponsiveBreakpoints } from 'src/hooks/ueResponsiveBreakpoints';
import { GetProfileDto } from 'woofwoof-api';
import { useProfilesContext } from '../hooks/useProfilesContext';
import ProfileCard from './ProfileCard';
import ProfileCardSkeleton from './ProfileCardSkeleton';
// ----------------------------------------------------------------------

export default function ProfilesCards() {
  const { isXs, isSm, isMd, isLg } = useResponsiveBreakpoints();
  const { data, isLoading } = useProfilesContext();
  const isMoreThanOneProfile = data && data?.length > 1;

  if (isLoading) {
    return <ProfileCardSkeleton isSmall={isXs || isSm || isMd || isLg} />;
  }

  return data ? (
    <Masonry
      columns={{
        xs: 1,
        sm: 1,
        md: 1,
        lg: !isMoreThanOneProfile ? 1 : 2,
        xl: !isMoreThanOneProfile ? 1 : 2,
      }}
      spacing={2}
      sx={{
        margin: 'auto',
        flex: 1,
        my: -1,
        px: { xs: 1, sm: 1, md: 1, lg: 0 },
        mx: { lg: -1, xl: -1 },
      }}
    >
      {data.map((profile: GetProfileDto) => (
        <Fragment key={profile.id}>
          <ProfileCard
            profile={profile}
            isSmall={isMoreThanOneProfile || isXs || isSm || isMd || isLg}
          />
        </Fragment>
      ))}
    </Masonry>
  ) : null;
}
