// @mui

import { Stack, useTheme } from '@mui/material';
import VisitModalNotesTabs from './VisitModalNotesTabs';

// ---------------------------------------------------------------------

type VisitModalNotesDetailsProps = {
  visit?: any;
};

export default function VisitModalNotesDetails({
  visit,
}: VisitModalNotesDetailsProps) {
  const theme = useTheme();

  if (!visit) {
    return null;
  }

  return (
    <Stack
      sx={{
        p: 3,
        gap: 1,
        backgroundColor: theme.palette.background.paper,
        height: 1,
        borderRadius: 2,
      }}
    >
      <VisitModalNotesTabs visit={visit} />
    </Stack>
  );
}
