import { Box, Stack } from '@mui/system';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import RndModal from 'src/components/rnd-modal';
import { TwilioError } from 'twilio-video';
import { ParticipantProvider } from '../context/ParticipantProvider';
import { VideoProvider } from '../context/VideoProvider';
import { useConversationModalContext } from '../hooks/useConversationModalContext';
import ConversationHeader from './ConversationHeader';
import ConversationModalFooter from './ConversationModalFooter';
import ConversationVideo from './ConversationVideo';

export default function ConversationModal() {
  const {
    state: { open, position, size },
    onResizeStop,
    onDragStop,
    closeModal,
  } = useConversationModalContext();
  const { enqueueSnackbar } = useSnackbar();

  const handleOnError = useCallback(
    (err: TwilioError | Error) => {
      closeModal();
      if (err && (err as TwilioError).code === 8) {
        enqueueSnackbar(
          'Aby przeprowadzić e-spotkanie wymagany jest dostęp do kamery i mikrofonu',
          { variant: 'error' },
        );
      } else {
        enqueueSnackbar(err.message, { variant: 'error' });
      }
      throw new Error(
        JSON.stringify({
          message: err.message,
          code: (err as TwilioError).code,
        }),
      );
    },
    [closeModal, enqueueSnackbar],
  );

  return (
    <Box
      sx={{
        zIndex: 9999,
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        pointerEvents: 'none',
      }}
    >
      <RndModal
        open={open}
        position={position}
        size={size}
        onResizeStop={onResizeStop}
        onDragStop={onDragStop}
      >
        <VideoProvider onError={handleOnError}>
          <ParticipantProvider>
            <Stack sx={{ flex: 1, height: 1 }}>
              <ConversationHeader />
              <ConversationVideo />
              <ConversationModalFooter />
            </Stack>
          </ParticipantProvider>
        </VideoProvider>
      </RndModal>
    </Box>
  );
}
