// @mui
import { Stack, styled } from '@mui/material';

// ----------------------------------------------------------------------

export const StyledRoot = styled('main')(({ theme }) => ({
  backgroundColor: theme.palette.background.neutral,
  padding: theme.spacing(10, 2, 0, 2),
  [theme.breakpoints.up('lg')]: {
    padding: 0,
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    height: '100%',
  },
}));

export const StyledBox = styled(Stack)(({ theme }) => ({
  height: '100%',
}));

export const StyledSection = styled('div')(({ theme }) => ({
  display: 'none',
  position: 'relative',

  [theme.breakpoints.up('lg')]: {
    flexGrow: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
}));
export const StyledContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    display: 'flex',
    gap: 64,
    '@media (max-height: 720px)': {
      marginTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  },
}));

export const StyledContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexShrink: 0,
  justifyContent: 'center',
  [theme.breakpoints.up('lg')]: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export const StyledContentInner = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.spacing(2),
  padding: theme.spacing(2),
  width: '100%',
  maxWidth: '450px',
  boxShadow: theme.customShadows.z24,
  [theme.breakpoints.up('md')]: {},
  [theme.breakpoints.up('lg')]: {
    width: '450px',
  },
}));
