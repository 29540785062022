import axios, { AxiosRequestConfig } from 'axios';
import { getAuth } from 'firebase/auth';

export const axiosBaseUrl = process.env.REACT_APP_API_URL;

export const axiosConfig: AxiosRequestConfig = {
  baseURL: axiosBaseUrl,
};

const axiosBackendClient = axios.create(axiosConfig);

axiosBackendClient.interceptors.request.use(async function (config) {
  config.headers['Content-Type'] = 'application/json';
  config.headers['Accept'] = 'application/json';
  const token = await getAuth().currentUser?.getIdToken(true);
  if (token) {
    config.headers.Authorization = token;
  }

  return config;
});

export default axiosBackendClient;
