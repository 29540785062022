import { Outlet } from 'react-router-dom';
// @mui
import {
  Stack,
  Container,
  Toolbar,
  useTheme,
  useMediaQuery,
} from '@mui/material';

// hooks

// config
//
import Header from './Header';

// ----------------------------------------------------------------------

export default function CompactLayout() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <>
      <Header />
      <Stack sx={{ height: isDesktop ? '100%' : 'auto' }}>
        {isDesktop && <Toolbar sx={{ minHeight: '88px !important' }} />}
        <Container
          sx={{
            display: 'flex',
            flex: 1,
            maxWidth: '100% !important',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          component="main"
        >
          <Outlet />
        </Container>
      </Stack>
    </>
  );
}
