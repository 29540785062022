import { Box, IconButton, Stack, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';
import Iconify from 'src/components/iconify/Iconify';
import { getFileIcon } from '../../profile/components/ProfileFilesTable';

interface Document {
  filename: string;
  url: string;
}

interface CarouselProps {
  documents: Document[];
}

const DocumentCarousel: React.FC<CarouselProps> = ({ documents }) => {
  const elementRef = useRef(null);
  const [arrowDisable, setArrowDisable] = useState(true);

  const handleHorizantalScroll = (
    element: any,
    speed: any,
    distance: any,
    step: any,
  ) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
      if (element.scrollLeft === 0) {
        setArrowDisable(true);
      } else {
        setArrowDisable(false);
      }
    }, speed);
  };

  if (!documents.length) {
    return null;
  }

  return (
    <Box
      sx={{
        px: 5,
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <IconButton
        sx={{ position: 'absolute', left: 0 }}
        disabled={arrowDisable}
        onClick={() => {
          handleHorizantalScroll(elementRef.current, 5, 250, -20);
        }}
      >
        <Iconify icon="ic:round-chevron-left" width={20} />
      </IconButton>
      <IconButton
        sx={{ position: 'absolute', right: 0 }}
        onClick={() => {
          handleHorizantalScroll(elementRef.current, 5, 250, 20);
        }}
      >
        <Iconify icon="ic:round-chevron-right" width={20} />
      </IconButton>
      <Box
        sx={{
          display: 'flex',
          overflow: 'hidden',
          width: '100%',
          gap: 3,
        }}
        ref={elementRef}
      >
        {documents.map((file, index) => (
          <Stack
            key={index}
            sx={{
              gap: 2,
              flexDirection: 'row',
              alignItems: 'center',
              width: 'fit-content',
            }}
          >
            <Box
              sx={{
                padding: 1,
                borderRadius: 1,
                backgroundColor: 'background.neutral', // Adjust as per theme
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {getFileIcon(file.filename)}
            </Box>
            <Typography
              sx={{
                whiteSpace: 'nowrap',
                '&:hover': {
                  cursor: 'pointer',
                  textDecoration: 'underline',
                },
              }}
              onClick={() => window.open(file.url, '_blank')}
            >
              {file.filename.split('_')[1]}
            </Typography>
          </Stack>
        ))}
      </Box>
    </Box>
  );
};

export default DocumentCarousel;
