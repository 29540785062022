import { Box, IconButton, Stack, useTheme } from '@mui/material';
import { useNavigate } from 'react-router';
import Iconify from 'src/components/iconify';
import { PATH_DASHBOARD } from 'src/routes/paths';
import useRoomState from '../hooks/useRoomState';
import useVideoContext from '../hooks/useVideoContext';
import LiveVisitDetailsModalButton from './LiveVisitDetailsModalButton';
import ToggleAudioButton from './ToggleAudioButton';
import ToggleVideoButton from './ToggleVideoButton';

export default function LiveVisitFooter() {
  const { room } = useVideoContext();
  const roomState = useRoomState();
  const isReconnecting = roomState === 'reconnecting';
  const theme = useTheme();
  const navigate = useNavigate();

  const endConversation = () => {
    navigate(PATH_DASHBOARD.general.profiles.list);
  };

  return (
    <Stack
      sx={{
        p: 2,
        flexDirection: 'row',
        justifyContent: 'space-between',
        '@media (min-width:600px) and (max-height:420px)': { p: 1 },
      }}
    >
      <Box
        sx={{ display: 'flex', flex: 1, justifyContent: 'flex-start' }}
      ></Box>
      <Stack
        direction="row"
        gap={2}
        sx={{ display: 'flex', flex: 1, justifyContent: 'center' }}
      >
        <ToggleAudioButton disabled={isReconnecting || !room} />
        <IconButton
          size="small"
          sx={{
            backgroundColor: theme.palette.error.main,
            ':hover': {
              backgroundColor: theme.palette.error.light,
            },
            '&:disabled': {
              backgroundColor: theme.palette.action.disabledBackground,
            },
          }}
          disabled={!room}
          onClick={endConversation}
        >
          <Iconify
            icon="fluent:call-end-24-filled"
            color={theme.palette.text.primary}
          />
        </IconButton>
        <ToggleVideoButton disabled={isReconnecting || !room} />
      </Stack>
      <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
        <LiveVisitDetailsModalButton />
      </Box>
    </Stack>
  );
}
