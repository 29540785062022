import { Stack } from '@mui/material';
import { AccountChangePassword } from 'src/sections/@dashboard/user/account';

export default function AccountTabsPasswordPage() {
  return (
    <Stack sx={{ height: 1 }}>
      <AccountChangePassword />
    </Stack>
  );
}
