import { Helmet } from 'react-helmet-async';
import AuthNewPassword from 'src/sections/auth/AuthNewPassword';
// ----------------------------------------------------------------------

export default function NewPasswordPage() {
  return (
    <>
      <Helmet>
        <title> Zmiana hasła | Vetsi</title>
      </Helmet>
      <AuthNewPassword />
    </>
  );
}
