import NewPasswordPage from 'src/pages/auth/NewPasswordPage';
import VerificationPage from 'src/pages/auth/VerificationPage';
import LoginPage from 'src/pages/auth/LoginPage';

const VerificationRouter = () => {
  const params = new URLSearchParams(window.location.search);
  const mode = params.get('mode');

  switch (mode) {
    case 'resetPassword':
      return <NewPasswordPage />;
    case 'verifyEmail':
      return <VerificationPage />;
    default:
      return <LoginPage />;
  }
};
export default VerificationRouter;
