// @mui
import { GlobalStyles, useTheme } from '@mui/material';

// ----------------------------------------------------------------------

export default function StyledNotistack() {
  const theme = useTheme();

  const isLight = theme.palette.mode === 'light';

  const inputGlobalStyles = (
    <GlobalStyles
      styles={{
        '#root': {
          '.SnackbarContent-root': {
            width: '100%',
            padding: theme.spacing(1),
            margin: theme.spacing(0.25, 0),
            boxShadow: theme.customShadows.z8,
            borderRadius: theme.shape.borderRadius,
            color: isLight
              ? theme.palette.common.white
              : theme.palette.grey[800],
            backgroundColor: isLight
              ? theme.palette.grey[900]
              : theme.palette.common.white,
            '&.SnackbarItem-variantSuccess, &.SnackbarItem-variantError, &.SnackbarItem-variantWarning, &.SnackbarItem-variantInfo':
              {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.paper,
              },
            '&.SnackbarItem-variantSuccess': {
              backgroundColor: theme.palette.success.lighter,
            },
            '&.SnackbarItem-variantError': {
              backgroundColor: theme.palette.error.lighter,
            },
            '&.SnackbarItem-variantWarning': {
              backgroundColor: theme.palette.warning.lighter,
            },
            '&.SnackbarItem-variantInfo': {
              backgroundColor: theme.palette.info.lighter,
            },
          },
          '.SnackbarItem-message': {
            padding: '0 !important',
            fontWeight: theme.typography.fontWeightMedium,
            maxWidth: '100%',
            [theme.breakpoints.down('md')]: {
              maxWidth: '83%',
            },
          },
          '.SnackbarItem-action': {
            color: theme.palette.action.active,

            '& svg': {
              width: 20,
              height: 20,
            },
          },
        },
      }}
    />
  );

  return inputGlobalStyles;
}
