import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// @mui
import {
  Box,
  Divider,
  MenuItem,
  Stack,
  Typography,
  alpha,
} from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// auth
import { useAuthContext } from '../../../auth/useAuthContext';
// components
import { useUserContext } from 'src/auth/useUserContext';
import { IconButtonAnimate } from '../../../components/animate';
import { CustomAvatar } from '../../../components/custom-avatar';
import MenuPopover from '../../../components/menu-popover';
import { useSnackbar } from '../../../components/snackbar';

// ----------------------------------------------------------------------

const OPTIONS = [
  {
    label: 'Ustawienia',
    linkTo: PATH_DASHBOARD.general.account.general,
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { logout, user } = useAuthContext();
  const { data } = useUserContext();

  const { enqueueSnackbar } = useSnackbar();

  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleLogout = async () => {
    try {
      logout();
      handleClosePopover();
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Nastąpił błąd podczas wylogowania!', {
        variant: 'error',
      });
    }
  };

  const handleClickItem = (path: string) => {
    handleClosePopover();
    navigate(path);
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpenPopover}
        sx={{
          p: 0,
          ...(openPopover && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <CustomAvatar
          src={data?.avatar || user?.photoURL}
          alt={
            data
              ? `${data?.firstName} ${data?.lastName}`
              : `${user?.displayName}`
          }
          name={
            data
              ? `${data?.firstName} ${data?.lastName}`
              : `${user?.displayName}`
          }
        />
      </IconButtonAnimate>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        sx={{ width: 200, p: 0 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {data
              ? `${data?.firstName} ${data?.lastName}`
              : `${user?.displayName}`}
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        {pathname !== PATH_DASHBOARD.general.updateAccount && (
          <Stack sx={{ p: 1 }}>
            {OPTIONS.map((option) => (
              <MenuItem
                key={option.label}
                onClick={() => handleClickItem(option.linkTo)}
              >
                {option.label}
              </MenuItem>
            ))}
          </Stack>
        )}

        <Divider />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Wyloguj
        </MenuItem>
      </MenuPopover>
    </>
  );
}
