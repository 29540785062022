import { Skeleton, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { visitApi } from 'src/api/visit';
import { useUserContext } from 'src/auth/useUserContext';
import { useCurrentVisitContext } from '../../visit/hooks/useCurrentVisitContext';
import ProfileVisitsHistoryTable from './ProfileVisitsHistoryTable';
import ProfileVisitsHistoryTableSkeleton from './ProfileVisitsHistoryTableSkeleton';

export const ProfileVisitsHistoryContent = () => {
  const { isUserRole } = useUserContext();
  const { enqueueSnackbar } = useSnackbar();
  const { data: currentVisitData } = useCurrentVisitContext();
  const { profileId } = useParams<{ profileId: string }>();
  const { data, isLoading, isError, remove } = useQuery(
    ['profileVisitsHistory'],
    () =>
      visitApi.visitControllerGetVisitHistory({
        profileId: profileId as string,
      }),
    {
      retry: 1,
      enabled: Boolean(isUserRole),
    },
  );

  const {
    data: previewData,
    isLoading: isPreviewLoading,
    isError: isPreviewError,
    remove: removePreview,
  } = useQuery(
    ['profileVisitsHistoryPreview'],
    () =>
      visitApi.visitControllerGetProfileVisitHistoryPreview({
        profileId: profileId as string,
      }),
    {
      retry: 1,
      enabled: !Boolean(isUserRole) && Boolean(currentVisitData),
    },
  );

  useEffect(() => {
    if (
      (!data && !isLoading && isError) ||
      (!previewData && !isPreviewLoading && isPreviewError)
    ) {
      enqueueSnackbar('Nie mogliśmy pobrać najnowszej historii e-spotkań', {
        variant: 'error',
      });
    }
  }, [
    data,
    isLoading,
    isError,
    enqueueSnackbar,
    previewData,
    isPreviewLoading,
    isPreviewError,
  ]);

  useEffect(() => {
    return () => {
      isUserRole ? remove() : removePreview();
    };
  }, [remove, isUserRole, removePreview]);

  return !isLoading || !isPreviewLoading ? (
    <>
      <Stack p={3}>
        <Typography variant="h6">Historia e-spotkań</Typography>
      </Stack>
      <ProfileVisitsHistoryTable data={data?.data || previewData?.data || []} />
    </>
  ) : (
    <>
      <Stack p={3}>
        <Skeleton variant="rounded" height="28px" width="260px" />
      </Stack>
      <ProfileVisitsHistoryTableSkeleton />
    </>
  );
};
