import React, {
  PropsWithChildren,
  createContext,
  useMemo,
  useState,
} from 'react';
import MicrophoneAndCameraPermissionsModal from '../components/MicrophoneAndCameraPermissionsModal';

interface IMicrophoneAndCameraPermissionsModalContext {
  isModalOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
}

export const MicrophoneAndCameraPermissionsModalContext =
  createContext<IMicrophoneAndCameraPermissionsModalContext | null>(null);

export const MicrophoneAndCameraPermissionsModalProvider: React.FC<
  PropsWithChildren<{}>
> = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);

  const closeModal = () => setIsModalOpen(false);

  const memoizedValue = useMemo(
    () => ({
      isModalOpen,
      openModal,
      closeModal,
    }),
    [isModalOpen, openModal, closeModal],
  );

  return (
    <MicrophoneAndCameraPermissionsModalContext.Provider value={memoizedValue}>
      <MicrophoneAndCameraPermissionsModal
        isOpen={isModalOpen}
        onClose={closeModal}
      />
      {children}
    </MicrophoneAndCameraPermissionsModalContext.Provider>
  );
};
