// @mui

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
} from '@mui/material';

import { useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { conversationApi } from 'src/api/conversation';
import VetsiIcon from 'src/assets/icons/VetsiIcon';
import { useUserContext } from 'src/auth/useUserContext';
import usePrevious from 'src/hooks/usePrevious';
import {
  GetVisitById,
  GetVisitByIdStatusEnum,
  PaymentStatusEnum,
} from 'woofwoof-api';
import { VISIT_TYPE_MATRIX } from './BookingForm';
import VisitModalDetails from './VisitModalDetails';
import VisitModalNotesDetails from './VisitModalNotesDetails';
import VisitModalNotesDetailsSkeleton from './VisitModalNotesDetailsSkeleton';

// ---------------------------------------------------------------------

type VisitModalProps = {
  isOpen: boolean;
  onClose: () => void;
  visit?: GetVisitById;
  visitActions?: (visit: GetVisitById, onClose: () => void) => React.ReactNode;
};

export default function VisitModal({
  isOpen,
  onClose,
  visit,
  visitActions,
}: VisitModalProps) {
  const theme = useTheme();
  const { isProfessionalRole } = useUserContext();
  const prevIsOpen = usePrevious(isOpen);
  const [visitCopy, setVisitCopy] = useState<GetVisitById | undefined>(visit);

  useEffect(() => {
    if (visit && isOpen) {
      setVisitCopy(visit);
    }
  }, [visit, isOpen]);

  const isVisitCompleted =
    visitCopy?.status === GetVisitByIdStatusEnum.Completed;
  const paymentStatus = visitCopy?.payment?.status;

  // TODO: Export Transaction status Enum
  const eventsColorMatrix = useMemo(
    () => ({
      [PaymentStatusEnum.Created]: theme.palette.warning.light,
      [PaymentStatusEnum.Refunded]: theme.palette.warning.light,
      [PaymentStatusEnum.Failed]: theme.palette.error.light,
      [PaymentStatusEnum.Verified]: theme.palette.success.light,
    }),
    [theme],
  );

  const {
    data: conversationData,
    isLoading: isConversationLoading,
    remove: removeConversation,
  } = useQuery(
    ['conversationDetailsModal'],
    () =>
      conversationApi.conversationControllerGetConversationById({
        conversationId: (visitCopy?.conversation as any)?.id,
      }),
    {
      retry: 1,
      enabled: isVisitCompleted,
    },
  );

  useEffect(() => {
    if (!isOpen && prevIsOpen) {
      removeConversation();

      setTimeout(() => {
        setVisitCopy(undefined);
      }, 150);
    }
  }, [removeConversation, isOpen, prevIsOpen]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: isVisitCompleted ? '1080px' : '600px', width: 0.9 },
      }}
    >
      <>
        <DialogTitle
          sx={{
            display: 'flex',
            flexDirection: {
              xs: 'column',
              sm: 'column',
              md: 'row',
              lg: 'row',
              xl: 'row',
            },
            alignItems: {
              xs: 'flex-start',
              sm: 'flex-start',
              md: 'center',
              lg: 'center',
              xl: 'center',
            },
            justifyContent: 'space-between',
            gap: { xs: 1, sm: 1, md: 1 },
          }}
        >
          <Stack sx={{ flexDirection: 'row', gap: 2, alignItems: 'center' }}>
            <VetsiIcon
              width={28}
              height={28}
              color={
                paymentStatus && !isVisitCompleted && isProfessionalRole
                  ? eventsColorMatrix[paymentStatus]
                  : theme.palette.text.primary
              }
            />
            {visitCopy?.startTime &&
              format(new Date(visitCopy.startTime), 'dd/MM/yyyy H:mm')}
          </Stack>
          {visitCopy && (
            <Box component="span" sx={{ color: theme.palette.text.secondary }}>
              Rodzaj:{` `}
              <Box component="span" sx={{ color: theme.palette.text.primary }}>
                {VISIT_TYPE_MATRIX[visitCopy.category] || ''}
              </Box>
            </Box>
          )}
        </DialogTitle>
        <DialogContent>
          <Grid item container spacing={3} sx={{ flex: 1 }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={isVisitCompleted ? 7 : 12}
              xl={isVisitCompleted ? 7 : 12}
            >
              <VisitModalDetails visit={visitCopy} />
            </Grid>
            {isVisitCompleted && (
              <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
                {!isConversationLoading ? (
                  <VisitModalNotesDetails visit={visitCopy} />
                ) : (
                  <VisitModalNotesDetailsSkeleton />
                )}
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions sx={{ mt: 2 }}>
          {visitActions && visit && (
            <Box sx={{ flex: 1 }}>{visitActions(visit, onClose)}</Box>
          )}
          <Button variant="contained" color="primary" onClick={onClose}>
            Zamknij
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );
}
