// @mui
import { Button, Popover, Typography } from '@mui/material';
import { MouseEvent, useCallback, useState } from 'react';
import Iconify from 'src/components/iconify';
import { GetVisitById, PaymentStatusEnum } from 'woofwoof-api';

// ---------------------------------------------------------------------

type UserUpcomingVisitCardCancelButtonProps = {
  visit: GetVisitById;
  onCancelVisit: ({ visitId }: { visitId: string }) => void;
  isRescheduleOrCancelAvailable: boolean;
};

export default function UserUpcomingVisitCardCancelButton({
  visit,
  onCancelVisit,
  isRescheduleOrCancelAvailable,
}: UserUpcomingVisitCardCancelButtonProps) {
  const isPaymentFailed = visit?.payment?.status === PaymentStatusEnum.Failed;
  const isPaymentPending = visit?.payment?.status === PaymentStatusEnum.Created;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handlePopoverOpen = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      !isRescheduleOrCancelAvailable && setAnchorEl(event.currentTarget);
    },
    [isRescheduleOrCancelAvailable],
  );

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleOnCancelVisitClick = () => onCancelVisit({ visitId: visit.id });

  return (
    <>
      <span
        aria-owns={open ? 'cancel-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        style={{ display: 'inline-flex' }}
      >
        <Button
          fullWidth
          variant="soft"
          color="inherit"
          size="large"
          startIcon={<Iconify icon="fe:close" />}
          onClick={handleOnCancelVisitClick}
          disabled={
            isPaymentFailed
              ? false
              : isPaymentPending || !isRescheduleOrCancelAvailable
          }
        >
          Anuluj e-spotkanie
        </Button>
      </span>
      <Popover
        id="cancel-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography variant="body2" sx={{ p: 1, maxWidth: '350px' }}>
          Anulowanie spotkania jest możliwe z minimum 6 godzinnym wyprzedzeniem.
        </Typography>
      </Popover>
    </>
  );
}
