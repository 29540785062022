import { createContext, useCallback, useMemo, useState } from 'react';
import { DEFAULT_PROPS, FULL_SIZE_MARGIN } from 'src/components/rnd-modal';

// ----------------------------------------------------------------------

export type ModalStateType = {
  token?: string;
  roomName?: string;
  open: boolean;
  maximize: boolean;
  chat: boolean;
  size: {
    width: number;
    height: number;
  };
  position: {
    x: number;
    y: number;
  };
};

export type ConversationModalContextType = {
  state: ModalStateType;
  onMaximize: () => void;
  onMinimize: () => void;
  onResizeStop: ({ width, height }: { width: number; height: number }) => void;
  onDragStop: ({ x, y }: { x: number; y: number }) => void;
  openModal: ({
    token,
    roomName,
  }: {
    token?: string;
    roomName?: string;
  }) => void;
  closeModal: () => void;
};

export const ConversationModalContext =
  createContext<ConversationModalContextType | null>(null);

type ConversationModalProviderProps = {
  children: React.ReactNode;
};

export function ConversationModalProvider({
  children,
}: ConversationModalProviderProps) {
  const [state, setState] = useState<ModalStateType>({
    open: false,
    maximize: false,
    chat: false,
    size: {
      width: DEFAULT_PROPS.width,
      height: DEFAULT_PROPS.height,
    },
    position: {
      x: DEFAULT_PROPS.x,
      y: DEFAULT_PROPS.y,
    },
  });

  const onMaximize = () => {
    setState((state) => ({
      ...state,
      maximize: true,
      size: {
        width: document.body.clientWidth - 2 * FULL_SIZE_MARGIN,
        height: document.body.clientHeight - 2 * FULL_SIZE_MARGIN,
      },
      position: { x: FULL_SIZE_MARGIN, y: FULL_SIZE_MARGIN },
    }));
  };

  const onMinimize = () => {
    setState((state) => ({
      ...state,
      maximize: false,
      size: {
        width: DEFAULT_PROPS.width,
        height: DEFAULT_PROPS.height,
      },
      position: { x: DEFAULT_PROPS.x, y: DEFAULT_PROPS.y },
    }));
  };

  const onResizeStop = ({
    width,
    height,
  }: {
    width: number;
    height: number;
  }) => {
    setState((state) => ({
      ...state,
      maximize: false,
      size: {
        width,
        height,
      },
    }));
  };

  const onDragStop = ({ x, y }: { x: number; y: number }) => {
    setState((state) => ({
      ...state,
      maximize: false,
      position: {
        x,
        y,
      },
    }));
  };

  const openModal = useCallback(
    ({ token, roomName }: { token?: string; roomName?: string }) => {
      setState((state) => ({ ...state, open: true, roomName, token }));
    },
    [],
  );

  const closeModal = () =>
    setState((state) => ({
      maximize: false,
      chat: false,
      size: {
        width: DEFAULT_PROPS.width,
        height: DEFAULT_PROPS.height,
      },
      position: {
        x: DEFAULT_PROPS.x,
        y: DEFAULT_PROPS.y,
      },
      open: false,
      roomName: undefined,
      token: undefined,
    }));

  const memoizedValue = useMemo(
    () => ({
      state,
      onMaximize,
      onMinimize,
      onResizeStop,
      onDragStop,
      openModal,
      closeModal,
    }),
    [state, openModal],
  );

  return (
    <ConversationModalContext.Provider value={memoizedValue}>
      {children}
    </ConversationModalContext.Provider>
  );
}
